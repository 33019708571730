const initialState = {
    employeeList: [],
    funnelList: [],
    dealStatusList: [],
    leadStatusList: [],
};

export const dictionaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DICTIONARY_ENPLOYEE':
            return {
                ...state,
                employeeList: action.payload.result,
            };
        case 'GET_DICTIONARY_FUNNEL':
            return {
                ...state,
                funnelList: action.payload,
            };
        case 'GET_DICTIONARY_DEAL_STATUS':
            return {
                ...state,
                dealStatusList: action.payload,
            };
        case 'GET_DICTIONARY_LEAD_STATUS':
            return {
                ...state,
                leadStatusList: action.payload,
            };
        default:
            return state;
    }
};
export default dictionaryReducer;
