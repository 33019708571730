import {
    FETCH_IMPORT_COMPANY_FIELD,
    FETCH_IMPORT_CONTACT_FIELD,
    FETCH_IMPORT_LEAD_FIELD,
    FETCH_IMPORT_DEAL_FIELD,
    FETCH_IMPORT_DEAL_FUNNEL,
    FETCH_IMPORT_DEAL_STATUS,
    FETCH_IMPORT_PUT_ELEMENT,
    FETCH_IMPORT_GET_ELEMENT,
} from '../../types/types';
import {
    fetchImportCompanyFieldApi,
    fetchImportContactFieldApi,
    fetchImportLeadFieldApi,
    fetchImportDealFieldApi,
    fetchImportDealFunnelApi,
    fetchImportDealStatusApi,
    fetchImportCreateElementApi,
    fetchImportPutElementApi,
    fetchImportGetElementApi,
} from '../../servise/import/importEdit';
import {
    fetchImportCompanyFieldAction,
    fetchImportContactFieldAction,
    fetchImportLeadFieldAction,
    fetchImportDealFieldAction,
    fetchImportDealFunnelAction,
    fetchImportDealStatusAction,
} from '../../actions/import/importEdit';

const types = new Array();
types[1] = { original: 'CRM_COMPANY', title: 'Компания', title_sub: 'company' };
types[2] = { original: 'CRM_CONTACT', title: 'Контакты', title_sub: 'contact' };
types[3] = { original: 'CRM_LEAD', title: 'Лид', title_sub: 'lead' };
types[4] = { original: 'CRM_DEAL', title: 'Сделка', title_sub: 'deal' };

const initialState = {
    entityTypes: types,
    element: null,
};
const importEdit = (state = initialState, action, type) => {
    switch (action.type) {
        case FETCH_IMPORT_COMPANY_FIELD: {
            return {
                ...state,
                companyField: action.payload,
            };
        }

        case FETCH_IMPORT_CONTACT_FIELD: {
            return {
                ...state,
                contactField: action.payload,
            };
        }

        case FETCH_IMPORT_LEAD_FIELD: {
            return {
                ...state,
                leadField: action.payload,
            };
        }

        case FETCH_IMPORT_DEAL_FIELD: {
            return {
                ...state,
                dealField: action.payload,
            };
        }

        case FETCH_IMPORT_DEAL_FUNNEL: {
            return {
                ...state,
                dealFunnel: action.payload,
            };
        }

        case FETCH_IMPORT_DEAL_STATUS: {
            return {
                ...state,
                dealStatus: action.payload,
            };
        }

        default:
            return state;
    }
};

export default importEdit;

export const fetchImportCompanyField = () => {
    return async (dispatch) => {
        await fetchImportCompanyFieldApi()
            .then(r => {
                if(r.data.ID) delete r.data.ID
                dispatch(fetchImportCompanyFieldAction(r.data))
            })
    }
}
export const fetchImportContactField = () => {
    return async (dispatch) => {
        await fetchImportContactFieldApi()
            .then(r => {
                if(r.data.ID) delete r.data.ID
                dispatch(fetchImportContactFieldAction(r.data))
            })
    }
}
export const fetchImportLeadField = () => {
    return async (dispatch) => {
        await fetchImportLeadFieldApi()
            .then(r => {
                if(r.data.ID) delete r.data.ID
                dispatch(fetchImportLeadFieldAction(r.data))
            })
    }
}
export const fetchImportDealField = () => {
    return async (dispatch) => {
        await fetchImportDealFieldApi()
            .then(r => {
                if(r.data.ID) delete r.data.ID
                dispatch(fetchImportDealFieldAction(r.data))
            })
    }
}
export const fetchImportDealFunnel = () => {
    return async (dispatch) => {
        await fetchImportDealFunnelApi().then((r) => {
            dispatch(fetchImportDealFunnelAction(r.data));
        });
    };
};
export const fetchImportDealStatus = () => {
    return async (dispatch) => {
        await fetchImportDealStatusApi().then((r) => {
            dispatch(fetchImportDealStatusAction(r.data));
        });
    };
};
export const fetchImportCreateElement = (data) => {
    return async (dispatch) => {
        try {
            const response = await fetchImportCreateElementApi(data);
            window.location.href = `/import-setting/${response.data.id}`;
        } catch (e) {
            console.log(`Произошла ошибка ${e}`);
        }
    };
};

export const fetchImportPutElement = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await fetchImportPutElementApi(id, data);
        } catch (e) {
            console.log(`Произошла ошибка ${e}`);
        }
    };
};

export const fetchImportGetElement = (data, setElement) => {
    return async (dispatch) => {
        await fetchImportGetElementApi(data).then((r) => {
            setElement(r.data);
        });
    };
};
