import React, {useState, useEffect} from "react"
import axios from "axios";
import { api } from "..";

/**
 * Получаем список всех воронок
 */
export const funnelListApi = () => {
    return axios.get(api.url + `funnel/list/`, api.config)
}

/**
 * Получаем список всех пользовательских свойств
 */
export const statusReportDealApi = (id: any) => {
	return axios.get(api.url + `setting/STATUS_REPORT_DEAL_${id}`, api.config).then(r => r.data)
}

/**
 * Получаем данные по пользовательскому свойству
 */
 export const userFieldApi = (property: any) => {
	return axios.get(api.url + `user-field/${property}`, api.config).then(r => {
		return r.data
	})
}