import axios from 'axios';
import { SalaryDetailFilterProps } from './../../../pageComponents/SalaryDetail/SalaryDetail.props';

const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials:true
}

/**
 * Получаем данные для главной страницы
 * @param filter 
 * @returns 
 */
export const salaryCalculationApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`sallary/calculation/?month=${filter.month.monthNum}&year=${filter.month.year}&id=${filter.url.id}`, config)
}

/**
 * Получаем отчет за месяц [Отработано дней]
 * @param filter 
 * @returns 
 */
export const reportMonthTimeApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`report/monthtime/?month=${filter.month.monthNum}&year=${filter.month.year}&id=${filter.url.id}`, config)
}

/**
 * Получаем сделки [План/Факт], [Эффективное время]
 * @param filter 
 * @returns 
 */
export const taskListApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`task/list/?select[mark]=grade&finish_date_from=${filter.month.monthStart}&finish_date_to=${filter.month.monthEnd}&responsible_id=${filter.url.id}&status[]=5`, config)
}

/**
 * Получаем оценки за месяц [Оценка задач]
 * @param filter 
 * @returns 
 */
export const taskGroupApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`task/group/?finish_date_from=${filter.month.monthStart}&finish_date_to=${filter.month.monthEnd}&responsible_id=${filter.url.id}&status[]=5&group_by[mark]=grade`, config)
}

/** 
 * Получаем список дел [Эффективное время]
 * @param {*} userId 
 * @param {*} date 
 * @returns 
 */
export const activityListApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`activity/list?start_date_from=${filter.month.monthStart}&start_date_to=${filter.month.monthEnd}&responsible_id=${filter.url.id}&completed=Y`, config).catch(e => ([]))
}

/**
 * Получаем список событий календаря [Эффективное время]
 * @param {*} userId 
 * @param {*} date 
 * @returns
 */
export const eventListApi = (filter: SalaryDetailFilterProps) => {
    return axios.get(url+`event/list/?date_from=${filter.month.monthStart}&date_to=${filter.month.monthEnd}&responsible_id=${filter.url.id}&group_by[]=name&group_by[]=owner_id&group_by[]=date`, config).catch(e => ([]))
}