import { fetchSettingsApi } from '../../servise/commonData/commonData';

export const FetchSideMenuAction = (payload) => ({
    type: 'FETCH_SIDE_MENU',
    payload,
});
export const FetchSideMenuReportAction = (payload) => ({
    type: 'FETCH_SIDE_MENU_REPORT',
    payload,
});
export const FetchSettingsParamsAction = (payload) => ({
    type: 'FETCH_SETTINGS_PARAMS',
    payload,
});
export const FetchSettingsResultAction = (payload) => ({
    type: 'FETCH_SETTINGS_RESULT',
    payload,
});

export const FetchSideMenu = (params, settings, result,report) => {
    return async (dispatch) => {
        await fetchSettingsApi(params).then((r) => {
            settings
                ? dispatch(FetchSettingsParamsAction(r.data))
                : result
                ? dispatch(FetchSettingsResultAction(r.data)):
                report? dispatch(FetchSideMenuReportAction(r.data))
                : dispatch(FetchSideMenuAction(r.data));
        });
    };
};

