import { TaskListProps, TaskListResultProps } from '../../../props';
import { SalaryDetailFilterProps } from '../../../pageComponents/SalaryDetail/SalaryDetail.props';
import moment from 'moment';

import {
    SALARY_KICKIDLER_REPORT,
    SALARY_MARK_REPORT,
    SALARY_PERIOD,
    SALARY_TASK_ELAPSED,
    SALARY_WEEKEND,
    SALARY_WORKTIME_REPORT,
} from '../../actions/salaryDetailNew/salaryDetailNew.types';

import {
    salaryCalculationApi,
    taskListApi,
    reportMonthTimeApi,
    taskGroupApi,
    activityListApi,
    eventListApi,
} from '../../servise/salaryDetail/salaryDetail';

import {
    salaryCalculationAction,
    taskListAction,
    reportMonthTimeAction,
    taskGroupAction,
    activityListAction,
    eventListAction,
} from '../../actions/salaryDetail/salaryDetail';

import {
    salaryKickidlerReportAction,
    salaryMarkReportAction,
    salaryPeriodAction,
    salaryTaskElapsedAction,
    salaryWeekendAction,
    salaryWorktimeReportAction,
} from '../../actions/salaryDetailNew/salaryDetailNew';
import { kickidlerReportApi } from '../../servise/kickidler/kickidler';
import { ReportQuery } from '../../types/kickidler';
import { worktimeGroupApi } from '../../servise/worktime/worktime';
import { WorktimeGroupQuery } from '../../types/worktime';
import { taskElapsedGroupApi } from '../../servise/taskElapsed/taskElapsed';
import { TaskElapsedGroupQuery } from '../../types/taskElapsed';
import { TaskListQuery } from '../../types/task';
import { fetchApiTaskList } from '../../servise/task/task';
import { salaryPeriodApi, salaryWeekendApi } from '../../servise/salary/salary';
import { SalaryPeriodQuery } from '../../types/salary';

const initialState = {
    kickidlerReport: [],
    worktimeReport: [],
    taskElapsed: [],
    markReport: [],
    weekend: [],
    period: [],
};

export const salaryDetailNewReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SALARY_KICKIDLER_REPORT:
            return {
                ...state,
                kickidlerReport: action.payload,
            };
        case SALARY_WORKTIME_REPORT:
            return {
                ...state,
                worktimeReport: action.payload,
            };
        case SALARY_TASK_ELAPSED:
            return {
                ...state,
                taskElapsed: action.payload,
            };
        case SALARY_MARK_REPORT:
            return {
                ...state,
                markReport: action.payload.result,
            };
        case SALARY_WEEKEND:
            return {
                ...state,
                weekend: action.payload,
            };
        case SALARY_PERIOD:
            return {
                ...state,
                period: action.payload,
            };
        default:
            return state;
    }
};
export default salaryDetailNewReducer;

export const fetchKickidlerReport = (filter: ReportQuery) => {
    return async (dispatch: any) => {
        await kickidlerReportApi(filter).then((r) => {
            if (r.data.code && r.data.code == 400) {
                dispatch(salaryKickidlerReportAction([]));
            } else {
                dispatch(salaryKickidlerReportAction(r.data));
            }
        });
    };
};

export const fetchWorktimeReport = (filter: WorktimeGroupQuery) => {
    return async (dispatch: any) => {
        await worktimeGroupApi(filter).then((r) => {
            dispatch(salaryWorktimeReportAction(r.data.result));
        });
    };
};

export const fetchTaskElapsed = (filter: TaskElapsedGroupQuery) => {
    return async (dispatch: any) => {
        await taskElapsedGroupApi(filter).then((r) => {
            dispatch(salaryTaskElapsedAction(r.data.result));
        });
    };
};

export const fetchMarkReport = (filter: TaskListQuery) => {
    return async (dispatch: any) => {
        await fetchApiTaskList(filter).then((r) => {
            dispatch(salaryMarkReportAction(r));
        });
    };
};

export const fetchWeekend = (year: Number) => {
    return async (dispatch: any) => {
        await salaryWeekendApi(year).then((r) => {
            dispatch(salaryWeekendAction(r.data));
        });
    };
};

export const fetchSalaryPeriod = (filter: SalaryPeriodQuery) => {
    return async (dispatch: any) => {
        await salaryPeriodApi(filter).then((r) => {
            dispatch(salaryPeriodAction(r.data));
        });
    };
};

/**
 * Получаем данные для главной страницы
 * @param filter
 * @returns
 */
export const fetchSalaryCalculation = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await salaryCalculationApi(filter).then((r) => {
            //Считаем сумму kpi и добавляем в объект
            try {
                r.data.result[0].result.kpi.total =
                    r.data.result[0].result.kpi.effectivetime +
                    r.data.result[0].result.kpi['plan-fact'] +
                    r.data.result[0].result.kpi['task-mark'];
                dispatch(salaryCalculationAction(r.data.result[0]));
            } catch (e) {
                console.log('Пустое значение в ответе сервера');
            }
        });
    };
};

/**
 * Получаем данные [Отработано дней]
 * @param filter
 * @returns
 */
export const fetchReportMonthTime = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await reportMonthTimeApi(filter).then((r: any) => {
            //Вытаскиваем нужного нам пользователя
            r.data = r.data.filter((el: any) => el.user_id == filter.url.id);
            dispatch(reportMonthTimeAction(r.data[0]));
        });
    };
};

/**
 * Получаем данные для детальной страницы [План/Факт] [Эффективное время]
 * @param filter
 * @returns
 */
export const fetchTaskList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        /*await taskListApi(filter).then((r: any) => {
            //Возвращаем нужного пользователя
            const result = r.data.result.map((el: any) => ({
                ...el,
                duration_plan: el.duration_plan ? Number(el.duration_plan) : 0,
                duration_fact: el.duration_fact ? Number(el.duration_fact) : 0,
            }));
            dispatch(taskListAction(result));
        });*/
    };
};

/**
 * Получаем данные [Оценка задач]
 * @param filter
 * @returns
 */
export const fetchTaskGroup = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await taskGroupApi(filter).then((r) => {
            dispatch(taskGroupAction(r.data));
        });
    };
};

/**
 * Получаем данные [Эффективное время]
 * @param filter
 * @returns
 */
export const fetchEffectiveTime = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await salaryCalculationApi(filter).then((r) => {
            dispatch(salaryCalculationAction(r.data[0]));
        });
    };
};

/**
 * Получаем список дел [Эффективное время]
 * @param {*} userId
 * @param {*} date
 * @returns
 */
export const fetchActivityList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await activityListApi(filter).then((r: any) => {
            const deals = r.data.result.map((el: any) => {
                const startTime = moment(el.start_time, 'YYYY-MM-DD HH:mm:ss');
                const endTime = moment(el.end_time, 'YYYY-MM-DD HH:mm:ss');
                el.time = endTime.diff(startTime, 'second');
                return el;
            });

            dispatch(activityListAction(r.data));
        });
    };
};

/**
 * Получаем список событий календаря [Эффективное время]
 * @param {*} userId
 * @param {*} date
 * @returns
 */
export const fetchEventList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await eventListApi(filter).then((r: any) => {
            dispatch(eventListAction(r.data));
        });
    };
};
