const initialState = {
    result: [],
    dictionary: {},
};

export const statisticDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CALL_STATISTIC':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
export default statisticDetail;
