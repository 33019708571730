import moment from "moment"
import { FilterMonthArrProps } from "./FilterMonth.props"
import { parse } from 'query-string';

/**
 * Преобразуем дату в объект
 * @param date 
 * @returns 
 */

export const dateToObj = (date: string) => {
    const format: string = "DD.MM.YYYY"
    const dateObj: FilterMonthArrProps = {
        date: moment(date, format).format("DD.MM.YYYY"),
        monthNum: Number(moment(date, format).format('M')),
        monthStart: moment(date, format).startOf('month').format('DD.MM.YYYY'),
        monthEnd: moment(date, format).endOf('month').format('DD.MM.YYYY'),
        year: Number(moment(date, format).format('YYYY')),
        monthDays: moment(date, format).daysInMonth(),
        monthName: moment(date, format).format('MMMM'),
        monthName2: moment(date, format).format('DD MMMM').substr(3),
    }
    return dateObj
}

/**
 * Данные из url в переменную
 */
 export const fncUrl2Variable = () => {
    return parse(window.location.search)
}