import {
    FETCH_REPORT_POSITION_MAIN_PAGE,
    FETCH_REPORT_POSITION_DETAIL_PAGE,
} from '../../types/types';

import {
    userFieldCodeApi,
    hrFunnelIdApi,
    positionApi,
    dealInWorkApi,
    dealClosedSuccessApi,
    dealClosedFailApi,
    callMadeApi,
    callMoreApi,
    meetingAssignedApi,
    meetingConductedApi,
    dealInWorkListApi,
    dealClosedSuccessListApi,
    dealClosedFailListApi,
    callMadeListApi,
    callMoreListApi,
    meetingAssignedListApi,
    meetingConductedListApi
} from '../../servise/reportPosition/reportPositionApi';

import {
    mainPageAction,
    detailPageAction
} from '../../actions/reportPosition/reportPositionAction';

/**
 * Устанавливаем пользовательское св-во, с которым будем делать запросы
 */
export const fetchUserFieldCode = (setFilter) => {
    return async (dispatch) =>
        await userFieldCodeApi().then((r) => {
            setFilter((data) => {
                return {
                    ...data,
                    userFieldCode: r.data,
                };
            });
        });
};

/**
 * Устанавливаем воронку, с которой будем делать запросы
 */
export const fetchHrFunnelId = (setFilter) => {
    return async (dispatch) =>
        await hrFunnelIdApi().then((r) => {
            setFilter((data) => {
                return {
                    ...data,
                    funnelId: r.data,
                };
            });
        });
};


const initialState = {
    mainPage: {},
    detailPage: {}
}

const reportPositionReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_REPORT_POSITION_MAIN_PAGE: {
            return {
                ...state,
                mainPage: action.payload
            }
        }
        case FETCH_REPORT_POSITION_DETAIL_PAGE: {
            return {
                ...state,
                detailPage: action.payload
            }
        }
        default:
            return state
    }
}

export default reportPositionReducer;

/**
 * Создаем массив главной страницы (для формирования таблицы)
 */
export const fetchMainPage = (filter, setData) => {
    return async(dispatch) => {
        const total = {}
        const positionRes = await positionApi(filter);
        const dealInWorkRes = await dealInWorkApi(filter);
        const dealClosedSuccessRes = await dealClosedSuccessApi(filter);
        const dealClosedFailRes = await dealClosedFailApi(filter);
        const callMadeRes = await callMadeApi(filter);
        const callMoreRes = await callMoreApi(filter);
        const meetingAssignedRes = await meetingAssignedApi(filter);
        const meetingConductedRes = await meetingConductedApi(filter);
        
        const userFieldCodeSmall = filter.userFieldCode.toLowerCase()
        
        let positionArr = positionRes.data.enum

        const dealInWork = dealInWorkRes.data.result
        const dealClosedSuccess = dealClosedSuccessRes.data.result
        const dealClosedFail = dealClosedFailRes.data.result
        const callMade = callMadeRes.data.result
        const callMore = callMoreRes.data.result
        const meetingAssigned = meetingAssignedRes.data.result
        const meetingConducted = meetingConductedRes.data.result
        const arr = {
            dealInWork,
            dealClosedSuccess,
            dealClosedFail,
            callMade,
            callMore,
            meetingAssigned,
            meetingConducted
        }

        //Дополняем массив position(должности) нужными данными для построения таблицы
        positionArr.forEach((pos, key) => {
            Object.keys(arr).forEach(code => {
                if(arr[code] && arr[code].length) {
                    positionArr[key][code] = 0
                    arr[code].forEach(el => {
                        if(el[userFieldCodeSmall] == pos.id) {
                            positionArr[key][code] = parseInt(el.count)
                        }
                    })
                } else {
                    positionArr[key][code] = 0
                }

                //Считаем общее количество по каждому столбцу
                if(isNaN(total[code])) total[code] = 0
                total[code] += +positionArr[key][code]
            })
        })
        positionArr = positionArr.filter(el => {
            //Убираем из массива вакансии, в которых все значения нулевые
            if(
                el.callMade !== 0 ||
                el.callMore !== 0 ||
                el.dealClosedFail !== 0 ||
                el.dealClosedSuccess !== 0 ||
                el.dealInWork !== 0 ||
                el.meetingAssigned !== 0 ||
                el.meetingConducted !== 0
            ) return true
        }).map(el => {
            //Меняем "0" на "-"
            if(!el.callMade) el.callMade = "-"
            if(!el.callMore) el.callMore = "-"
            if(!el.dealClosedFail) el.dealClosedFail = "-"
            if(!el.dealClosedSuccess) el.dealClosedSuccess = "-"
            if(!el.dealInWork) el.dealInWork = "-"
            if(!el.meetingAssigned) el.meetingAssigned = "-"
            if(!el.meetingConducted) el.meetingConducted = "-"
            return el
        })
        /*
        setData(data => ({
            ...data,
            result: positionArr,
            total
        }));
        */
        dispatch(mainPageAction({
            result: positionArr,
            total
        }))
    }
};

/**
 * Формируем массив для детальной страницы
 */
export const fetchDetailPage = (filter, setFilter) => {
    return async(dispatch) => {
        let r = {}
        switch(filter.detailPageType) {
            case "dealInWork":
                r = await dealInWorkListApi(filter)
            break;
            case "dealClosedSuccess":
                r = await dealClosedSuccessListApi(filter)
            break;
            case "dealClosedFail":
                r = await dealClosedFailListApi(filter)
            break;
            case "callMade":
                r = await callMadeListApi(filter)
            break;
            case "callMore":
                r = await callMoreListApi(filter)
            break;
            case "meetingAssigned":
                r = await meetingAssignedListApi(filter)
            break;
            case "meetingConducted":
                r = await meetingConductedListApi(filter)
            break;
        }
        setFilter(prev => ({...prev, paginationItems: r.data && r.data.totalCount}))
        dispatch(detailPageAction(r.data))
    }
}