const initialState = {
    S_OPERATIONAL_ENTITY: [],
    S_OPERATIONAL_SUCCESS_FORMAT: null,
    S_OPERATIONAL_SUCCESS_PLAN: null,
    S_HR_FUNNEL_ID: null,
    S_LOGO_NAME: null,
    S_LEFT_MENU: null,
};

export const settingReducer = (state = initialState, action) => {
    if (action.type in state) {
        let result = { ...state };
        result[action.type] = action.payload;
        return result;
    } else {
        return state;
    }
};

export default settingReducer;
