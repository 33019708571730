import React, { PureComponent } from 'react';
import { Card, CardBody, Media } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class MinimalStatisticsBG extends PureComponent {
    render() {
        let iconLeft;
        let iconRight;
        let textDirection;
        let classes;

        if (this.props.iconSide === 'right') {
            iconRight = this.props.children;
        } else {
            iconLeft = this.props.children;
            textDirection = 'text-right';
        }

        if (!this.props.padding) {
            classes = 'px-3 py-3';
        } else {
            classes = `px-${this.props.padding} py-${this.props.padding}`;
        }
        return (
            <Card className={classnames(this.props.cardBgColor)}>
                <CardBody className={classes}>
                    <Media>
                        <span className={this.props.statisticsColor ? this.props.statisticsColor : ''}>{iconLeft}</span>
                        <Media
                            body
                            className={classnames('white', textDirection)}
                        >
                            <h3
                                className={classnames(
                                    'mb-1',
                                    this.props.statisticsColor
                                )}
                            >
                                {this.props.statistics}
                            </h3>
                            <span className="text-label">{this.props.text}</span>
                        </Media>
                        <span className={this.props.statisticsColor ? this.props.statisticsColor : ''}>{iconRight}</span>
                    </Media>
                </CardBody>
            </Card>
        );
    }
}

MinimalStatisticsBG.propTypes = {
    iconSide: PropTypes.string,
    cardBgColor: PropTypes.string,
    statisticsColor: PropTypes.string,
    statistics: PropTypes.string,
    text: PropTypes.string,
};

export default MinimalStatisticsBG;
