import { 
  SALARY_DETAIL_CALCULATION,
  SALARY_DETAIL_TASK_LIST,
  SALARY_DETAIL_REPORT_MONTH_TIME,
  SALARY_DETAIL_TASK_GROUP,
  SALARY_DETAIL_ACTIVITY_LIST,
  SALARY_DETAIL_EVENT_LIST
} from "../../types/types"

export const salaryCalculationAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_CALCULATION,
    payload
  }
)

export const taskListAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_TASK_LIST,
    payload
  }
)

export const reportMonthTimeAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_REPORT_MONTH_TIME,
    payload
  }
)

export const taskGroupAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_TASK_GROUP,
    payload
  }
)

export const activityListAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_ACTIVITY_LIST,
    payload
  }
)

export const eventListAction = (payload: any)=>(              
  {
    type: SALARY_DETAIL_EVENT_LIST,
    payload
  }
)