import axios from 'axios';
const url = process.env.REACT_APP_BASE_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};
export const fetchWithoutMarkApi = (limit, page) => {
    const params = `page=${page}&pageSize=${limit}`;
    return axios.get(url + `api/task/withoutmark/?${params}`, config);
};
