import axios from 'axios';
import { api } from '../../../reusableComponents';
import getQueryString from '../helper';
import { ReportQuery } from '../../types/kickidler';

/**
 * Получаем данные отчета по сотруднику
 * @param filter
 * @returns
 */
export const kickidlerReportApi = (query: ReportQuery) => {
    return axios.get(api.url_module + `kickidler/api/report?${getQueryString(query)}`, api.config);
};
