import { fetchActivityListApi } from '../../servise/commonData/commonData';

export const fetchActivityInterviewAction = (payload) => ({
    type: 'FETCH_ACTIVITY_INTERVIEW',
    payload,
});
export const fetchActivityPlannedAction = (payload) => ({
    type: 'FETCH_ACTIVITY_PLANNED',
    payload,
});
export const fetchActivityShedInterviewAction = (payload) => ({
    type: 'FETCH_ACTIVITY_SHEDINTERVIEW',
    payload,
});
export const fetchActivityDealsAction = (payload) => ({
    type: 'FETCH_ACTIVITY_DEALS',
    payload,
});

export const fetchActivityFirstAction = (payload) => ({
    type: 'FETCH_ACTIVITY_FIRST',
    payload,
});
export const fetchActivitySecondAction = (payload) => ({
    type: 'FETCH_ACTIVITY_SECOND',
    payload,
});
export const fetchActivityFinalAction = (payload) => ({
    type: 'FETCH_ACTIVITY_FINAL',
    payload,
});

export const fetchActivityFirstBAction = (payload) => ({
    type: 'FETCH_ACTIVITY_FIRST_B',
    payload,
});
export const fetchActivitySecondBAction = (payload) => ({
    type: 'FETCH_ACTIVITY_SECOND_B',
    payload,
});
export const fetchActivityFinalBAction = (payload) => ({
    type: 'FETCH_ACTIVITY_FINAL_B',
    payload,
});

export const fetchActivityModalPlan = (payload) => ({
    type: 'FETCH_ACTIVITY_MODAL_PLAN',
    payload,
});
export const fetchDealPlanFinishAction = (payload) => ({
    type: 'FETCH_MODAL_PLAN_FINISH',
    payload,
});

export const fetchActivityList = (
    params,
    paramsTitle,
    type,
    managerParam,
    pagination
) => {
    return async (dispatch) => {
        await fetchActivityListApi(
            params,
            paramsTitle,
            managerParam,
            pagination
        ).then((r) => {
            switch (type) {
                case 'planned':
                    dispatch(fetchActivityPlannedAction(r.data));

                    break;
                case 'shedInterview':
                    dispatch(fetchActivityShedInterviewAction(r.data));

                    break;
                case 'delas':
                    dispatch(fetchActivityDealsAction(r.data));

                    break;
                case 'first':
                    dispatch(fetchActivityFirstAction(r.data));

                    break;
                case 'second':
                    dispatch(fetchActivitySecondAction(r.data));

                    break;
                case 'final':
                    dispatch(fetchActivityFinalAction(r.data));

                    break;
                case 'firstB':
                    dispatch(fetchActivityFirstBAction(r.data));

                    break;
                case 'secondB':
                    dispatch(fetchActivitySecondBAction(r.data));

                    break;
                case 'finalB':
                    dispatch(fetchActivityFinalBAction(r.data));

                    break;
                case 'modalPlan':
                    dispatch(fetchActivityModalPlan(r.data));

                    break;
                case 'modalPlanFinish':
                    dispatch(fetchDealPlanFinishAction(r.data));

                    break;

                default:
                    dispatch(fetchActivityInterviewAction(r.data));
            }
        });
    };
};
