import React, {useState, useEffect} from "react"
import { FilterDateProps, FilterDateArrProps, DateObjProps } from './FilterDate.props';
import './FilterDate.scss';
//import Select from "react-select";
//import axios from "axios";
import { dateToArr } from "./functions";

import DatePicker from 'react-datepicker';
//import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';

import moment from "moment";
import 'moment/locale/ru';

import { changeFormatDate } from "./functions";

import ru from "date-fns/locale/ru/index.js";
//registerLocale('ru',ru);
//setDefaultLocale('ru');


//CSS стили для input
const customStyles = {
	option: (provided: any) => ({
		...provided,
		borderBottom: "1px solid grey",
		color: "black",
	}),
};

/**
 * Компонент вывода департаментов. Прокидывает в фильр: FilterDateVal, FilterDateEmployee
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @param today () пробрасывает фильтр от текущей даты
 * @param date2 фильтр второй даты
 * @returns array
 */
export const FilterDate: React.FC<FilterDateProps> = ({ 
	setFilter,
	isClearable = false, 
	isMulti= false,
	placeholder = "Период",
	todayToFilter = false,
	showDate2 = false,
	date1 = "",
	date2 = "",
	filterDateSwitcher = true
}) => {

	let [startDate, setStartDate] = useState<any>("");
	let [startDate2, setStartDate2] = useState(new Date);

	const changeDate = (date1:any, secondDate = false) => {
		//текущая дата (сегодня)
		const today = moment().format("DD.MM.YYYY")

		if(!secondDate)
			setStartDate(date1)
		else 
			setStartDate2(date1)
		
		if(filterDateSwitcher) {
			//console.log()
		}

		//Прокидываем в масив объект date1 или date2
		const filterObj = !secondDate ? "date1" : "date2"
		let dateObj:DateObjProps = {
			//Формируем массив относительно выбранной даты
			[filterObj]: dateToArr(date1),
		}
		//Формируем массив относительно сегодняшнего дня
		if(todayToFilter) {
			dateObj.today = dateToArr(today)
		}
		setFilter((prev: any) => ({...prev, ...dateObj}))
	}

	useEffect(() => {
		
		if(date1) {
			date1 = changeFormatDate(date1)
			setStartDate(new Date(date1))
			changeDate(new Date(date1))
		} else {
			setStartDate(new Date)
			changeDate(new Date)
		}

		if(date2) {
			date2 = changeFormatDate(date2)
			setStartDate2(new Date(date2))
			changeDate(new Date(date2), true)
		} else {
			setStartDate2(new Date)
			changeDate(new Date(date2), true)
		}
	},[])

	return (
		<>
		<DatePicker
			className={`filter-date filter-date-1`}
			dateFormat="dd.MM.yyyy"
			selected={startDate}//new Date
			//startDate={startDate}
			onChange={(date: any) => {
				changeDate(date)
			}}
			styles={customStyles}
			locale={ru}
		/>
			{date2 && 
			<DatePicker
				className={`filter-date filter-date-2`}
				dateFormat="dd.MM.yyyy"
				selected={startDate2}//new Date
				//startDate2={startDate2}
				onChange={(date: any) => {
					changeDate(new Date(date), true)
				}}
				styles={customStyles}
				locale={ru}
			/>}
		</>
	)
};