import { combineReducers } from 'redux';
import hrTableReducer from './hrTableReducer';
import hrSourceReducer from './hrSourceReducer';
import hrStaticAddReducer from './hrStaticAddReducer';
import hrStaticReducer from './hrStaticReducer';
import hrTargetReducer from './hrTargetReducer';

const hrReducer = combineReducers({
    hrTableReducer,
    hrSourceReducer,
    hrStaticAddReducer,
    hrStaticReducer,
    hrTargetReducer,
});

export default hrReducer;
