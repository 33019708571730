import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartTaskMarkProps } from './ChartTaskMark.props';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { options, createData } from './functions';

export const ChartTaskMark:React.FC<ChartTaskMarkProps> = ({taskGroup, setFilter}) => {

    const data = createData(taskGroup)

    return <Bar options={options(setFilter)} data={data} />

}