import React from "react"
import { ModalProps, ModalRemoveProps } from './Modal.props';
import './Modal.scss';
import {Modal as ModalReact} from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { Button } from "reactstrap";

/**
 * Для вызова окна
 */
export const ModalContent = ({ 
	children, 
	open, 
	onClose 
}: ModalProps): JSX.Element => {
	return <ModalReact open={open} onClose={onClose} focusTrapped={false}>
		<div className="modal-for-content">
			<div className={"modal__header modal-for-content__header"}></div>
			<div className={"modal__body modal-for-content__body"}>
				{children}
			</div>
			{/*<div className={"modal__footer modal-for-content__footer"}></div>*/}
		</div>
	</ModalReact>;
};


/**
 * Для вызова окна
 * 1. const [filter, setFilter] = useState({modalRemoveOpen: false})
 * 2. <ModalRemove open={filter.modalRemoveOpen} onClose={() => setFilter(prev => ({...prev, modalRemoveOpen: false}))} onRemove={() => dispatch(fetchImportDelete(filter, setFilter))}/>
 * @param param
 * @returns 
 */
export const ModalRemove = ({
	children = "Вы действительно хотите удалить элемент?", 
	open, 
	onClose,
	onRemove
}: ModalRemoveProps): JSX.Element => {
	return <ModalReact open={open} onClose={onClose} focusTrapped={false}>
		<div className="modal-remove">
			<div className={"modal__header modal-remove__header"}></div>
			<div className={"modal__body modal-remove__body"}>
				<div className="modal-remove__question">
					{children}
				</div>
				<div className="modal-remove__buttons">
					<Button onClick={onRemove}>Да</Button>
					<Button onClick={onClose}>Нет</Button>     
				</div>
			</div>
			<div className={"modal__footer modal-remove__footer"}></div>
		</div>
	</ModalReact>;
};