import React, {useEffect, useState} from 'react';
import {Pagination as PaginationBlock, PaginationItem, PaginationLink} from 'reactstrap';
import cn from 'classnames';
import {PaginationProps} from './Pagination.props';
import './Pagination.scss';
import {parse, stringify} from 'query-string';
import {Link} from "react-router-dom";

/**
 * Получаем текущую страницу из url ?page=5
 * @param params по умолчанию location.search
 * @returns number
 */
export const getCurrentPage = (params: any = location.search) => {
	const url = parse(params)
	return url.page ? Number(url.page) : 1
}

/**
 * Возвращает объект с параметрами
 * @param url window.location.search
 * @returns - {id: 45, page: 2, type: "dealInWork"}
 */
export const paramsFromUrl = (url: string = window.location.search) => {
    return parse(url)
}

/**
 * При размещении компонента обязательно :
 * 1. const [filer, setFilter] = {paginationPageSize: 10, paginationPage: getCurrentPage(), paginationParams: paramsFromUrl(window.location.search)}
 * 2. <Pagination items={totalCount} page={filter.paginationPage} pageSize={filter.paginationPageSize} setFilter={setFilter} />
 * @param items Кол-во элементов всего (обязательный параметр)
 * @param page Страница старта (обязательный параметр)
 * @param setFilter Фильтр компонента (обязательный параметр)
 * @param pageSize Кол-во элементов на странице
 * @param pageSide Количество страниц в постраничной навигации по бокам относительно выбранной
 * @param params Приходят параметры ?id=45&type=dealInWork
 * @returns Component
 */
export const Pagination = ({ 
	items = 10,
	page = 1,
	pageSize = 10,
	pageSide = 5,
	params = {},
	setFilter
}: PaginationProps): JSX.Element => {

	const createLinkWithParams = (page:number) => {
		params.page = page
		return "?" + stringify(params)
	}

	//Кол-во страниц
	let pages: number = Math.ceil(items/pageSize)

	//Переводим количество страниц в массив
	const pagesArr: number[] = [];
	for( let i: number = 0; i < pages; i++) {
		pagesArr[i] = i + 1
	}

	//Переход на следующую или предыдущую страницу в зависимости от параметра 1 или -1
	const changePageHandler = (page: 1 | -1) => {
		setFilter((prev: any) => ({
			...prev,
			paginationPage: prev.paginationPage + page
		}))
	}

	//Высчитываем количество элементов слева от выбранной страницы
	const leftSideNum = () => {
		return (page - 1) < pageSide ? page - 1 : pageSide
	}

	//Высчитываем количество элементов справа от выбранной страницы
	const rightSideNum = () => {
		return (pages - page) > pageSide ? pageSide : pages - page
	}

	const leftSide: number = leftSideNum()
	const rightSide: number = rightSideNum()

	const rightSide2 = rightSide + (pageSide - leftSide)
	const leftSide2 = leftSide + (pageSide - rightSide)

	return (
		pages > 1 ? <PaginationBlock className="pagination">
			{/*Предыдущая страница*/}
			<PaginationItem 
				className={cn({
					["disabled"] : page === 1 
				})} 
				onClick={() => changePageHandler(-1)}
			>
				<Link className="page-link" to={createLinkWithParams(page - 1)}>«</Link>
			</PaginationItem>

			{/*Список страниц*/}
			{pagesArr.map((p: number) => {
				params.page = p
				
				if(p >= page - leftSide2 && p <= page + rightSide2 ) return <PaginationItem 
					key = {p}
					className={cn({
						["active"]: p === page,
					})} 
					onClick={(e: any) => {
						e.preventDefault()
						const {page} = parse(location.search)
						setFilter((prev: any) => ({...prev, paginationPage: Number(page)}))
					}}
				>
					<Link className="page-link" to={createLinkWithParams(p)}>{p}</Link>
				</PaginationItem>
			}
			)}

			{/*Следующая страница*/}
			<PaginationItem
				className={cn({
					["disabled"] : page === pages 
				})} 
				onClick={() => changePageHandler(1)}
			>
				<Link className="page-link" to={createLinkWithParams(page + 1)}>»</Link>
			</PaginationItem>
		</PaginationBlock>
		: <></>
	);
};