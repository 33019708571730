import {
    FETCH_REPORT_POSITION_MAIN_PAGE,
    FETCH_REPORT_POSITION_DETAIL_PAGE
} from "../../types/types";

/**
 * Массив для формирования главной страницы mainPage
 */
export const mainPageAction = (payload) => ({
    type: FETCH_REPORT_POSITION_MAIN_PAGE,
    payload,
});

/**
 * Массив для формирования детальной страницы detailPage
 */
export const detailPageAction = (payload) => ({
    type: FETCH_REPORT_POSITION_DETAIL_PAGE,
    payload,
});
