import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LogOut } from 'react-feather';

class UserBlock extends Component {
    render() {
        return (
            <div className="user-block user-block-item">
                <Link to="/dynamic-menu">
                    <div className="user-block-wrapper">
                        {this.props.user.profile.personal_photo ? (
                            <img
                                src={this.props.user.profile.personal_photo}
                                alt="logged-in-user"
                                width="35"
                                height="35"
                            />
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="16"
                                fill="#ffffff"
                                className="bi bi-person-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                        )}

                        <span className="user-name">
                            {this.props.user.profile.name
                                ? `${this.props.user.profile.name} ${this.props.user.profile.last_name}`
                                : 'Пользователь'}
                        </span>
                    </div>
                </Link>

                <Link
                    onClick={this.logout}
                    to="/login"
                    className="p-0 user-logout"
                >
                    <LogOut size={16} className="mr-1" />
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(UserBlock);
