const initialState = {
    newDeal: {
        result: [],
        totalcount: 0,
    },
    DealInWork: {
        result: [],
        totalcount: 0,
    },
    DealWithoutTask: {
        result: [],
        totalcount: 0,
    },
    DealSuccess: {
        count: 0,
        opportunity: 0,
    },
    newLead: {
        result: [],
        totalcount: 0,
    },
    LeadInWork: {
        result: [],
        totalcount: 0,
    },
    LeadWithoutTask: {
        result: [],
        totalcount: 0,
    },
    TaskDelay: {
        result: [],
        totalcount: 0,
    },
    ActivityDelay: {
        result: [],
        totalcount: 0,
    },
    employeeList: {
        result: [],
        totalcount: 0,
    },
    departmentList: [],
    funnelList: [],
    test: [],
    requestList: {
        result: [],
        totalcount: 0,
    },
    requestGroup: {
        result: [],
        dictionary: [],
    },
    newStatus: [],
};

export const operationalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_OPERATIONAL_NEW_LEAD':
            return {
                ...state,
                newLead: action.payload,
            };
        case 'FETCH_OPERATIONAL_LEAD_IN_WORK':
            return {
                ...state,
                LeadInWork: action.payload,
            };
        case 'FETCH_OPERATIONAL_LEAD_WITHOUT_TASK':
            return {
                ...state,
                LeadWithoutTask: action.payload,
            };
        case 'FETCH_OPERATIONAL_NEW_DEAL':
            return {
                ...state,
                newDeal: action.payload,
            };
        case 'FETCH_OPERATIONAL_DEAL_IN_WORK':
            return {
                ...state,
                DealInWork: action.payload,
            };
        case 'FETCH_OPERATIONAL_DEAL_WITHOUT_TASK':
            return {
                ...state,
                DealWithoutTask: action.payload,
            };
        case 'FETCH_OPERATIONAL_DEAL_SUCCESS':
            return {
                ...state,
                DealSuccess: action.payload.result[0],
            };
        case 'FETCH_OPERATIONAL_TASK_DELAY':
            return {
                ...state,
                TaskDelay: action.payload,
            };
        case 'FETCH_OPERATIONAL_ACTIVITY_DELAY':
            return {
                ...state,
                ActivityDelay: action.payload,
            };
        case 'FETCH_OPERATIONAL_DEPARTMENT_LIST':
            return {
                ...state,
                departmentList: action.payload,
            };
        case 'FETCH_OPERATIONAL_EMPLOYEE_LIST':
            return {
                ...state,
                employeeList: action.payload,
            };

        case 'FETCH_OPERATIONAL_FUNNEL_LIST':
            return {
                ...state,
                funnelList: action.payload,
            };
        case 'FETCH_OPERATIONAL_REQUEST_LIST':
            return {
                ...state,
                requestList: action.payload,
            };
        case 'FETCH_OPERATIONAL_REQUEST_GROUP':
            return {
                ...state,
                requestGroup: action.payload,
            };
        case 'FETCH_OPERATIONAL_STATUS_NEW_LIST':
            return {
                ...state,
                newStatus: action.payload,
            };
        default:
            return state;
    }
};
export default operationalReducer;
