import axios from 'axios';
import { api } from '../../../reusableComponents';
import getQueryString from '../helper';
import { WorktimeGroupQuery } from '../../types/worktime';

/**
 * Получаем данные об отработанном времени
 * @param query
 * @returns
 */
export const worktimeGroupApi = (query: WorktimeGroupQuery) => {
    return axios.get(api.url + `worktime/group?${getQueryString(query)}`, api.config);
};
