import { fetchTaskGroupWorkApi } from '../../servise/allTaskReport/allTaskReport';

export const fetchTaskGroupWorkAction = (payload) => ({
    type: 'FETCH_TASK_GROUP_WORK',
    payload,
});
export const fetchTaskGroupTodayAction = (payload) => ({
    type: 'FETCH_TASK_GROUP_WORK_TODAY',
    payload,
});
export const fetchTaskGroupNextDayAction = (payload) => ({
    type: 'FETCH_TASK_GROUP_WORK_NEXT_DAY',
    payload,
});
export const fetchTaskGroupDelayAction = (payload) => ({
    type: 'FETCH_TASK_GROUP_WORK_DELAY',
    payload,
});
export const fetchTaskGroupAllFinishAction = (payload) => ({
    type: 'FETCH_TASK_GROUP_ALL_FINISH',
    payload,
});

export const fetchTaskGroupWork = (params, type) => {
    return async (dispatch) => {
        await fetchTaskGroupWorkApi(params).then((r) => {
            switch (type) {
                case 'detailTask':
                    dispatch(fetchTaskGroupWorkAction(r));
                    break;
                case 'todayParam':
                    dispatch(fetchTaskGroupTodayAction(r));
                    break;
                case 'nextDayParam':
                    dispatch(fetchTaskGroupNextDayAction(r));
                    break;
                case 'delaysParam':
                    dispatch(fetchTaskGroupDelayAction(r));
                    break;
                case 'finishParam':
                    dispatch(fetchTaskGroupAllFinishAction(r));
                    break;
                default:
            }
        });
    };
};
