import { fetchDataAction, fetchDataListAction } from '../../actions/zakupki/zakupki';
import { GetPivotApi, GetPivotListApi } from '../../servise/zakupki/zakupki';

const initialState = {
    tenderplanStat: {
        S: 0,
        F: 0,
        N: 0,
        C: 0,
    },
    dealQueue: 0,
    dealNew: 0,
    dealPrevOverdue: 0,
    dealOverdue: 0,
    dealInWork: {
        dectionary: {},
        result: [],
    },
    dealFail: {
        dectionary: {},
        result: [],
    },
    sendResponseTask: 0,
    getResponseTask: 0,
    taskOverdue: 0,
    taskInWork: 0,
    preAnalis: [],
    techAnalis: [],
    countByStatus: [],
    data: [],
    dataList: [],
    totalCount: 0,
};

const zakupkiDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ZKP_DASHBOARD_TENDERPLAN':
            return {
                ...state,
                tenderplanStat: action.payload,
            };
        case 'FETCH_ZKP_DASHBOARD_QUEUE':
            return {
                ...state,
                dealQueue: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_NEW_DEAL':
            return {
                ...state,
                dealNew: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_PREV_OVERDUE':
            return {
                ...state,
                dealPrevOverdue: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_OVERDUE':
            return {
                ...state,
                dealOverdue: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_IN_WORK':
            return {
                ...state,
                dealInWork: action.payload,
            };
        case 'FETCH_ZKP_DASHBOARD_FAIL_DEAL':
            return {
                ...state,
                dealFail: action.payload,
            };
        case 'FETCH_ZKP_DASHBOARD_IN_WORK_TASK':
            return {
                ...state,
                taskInWork: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_OVERDUE_TASK':
            return {
                ...state,
                taskOverdue: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_PRE_ANALIS_TASK':
            return {
                ...state,
                preAnalis: action.payload.result,
            };
        case 'FETCH_ZKP_DASHBOARD_TECH_ANALIS_TASK':
            return {
                ...state,
                techAnalis: action.payload.result,
            };
        case 'FETCH_ZKP_DASHBOARD_GET_RESPONSE_TASK':
            return {
                ...state,
                getResponseTask: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_SEND_RESPONSE_TASK':
            return {
                ...state,
                sendResponseTask: action.payload.totalcount,
            };
        case 'FETCH_ZKP_DASHBOARD_COUNT_STATUS':
            return {
                ...state,
                countByStatus: action.payload.result,
            };

        case 'FETCH_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case 'FETCH_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case 'FETCH_DATA_LIST':
            return {
                ...state,
                dataList: action.payload.result,
                totalCount: action.payload.totalcount,
            };
        default:
            return state;
    }
};

export default zakupkiDashboardReducer;

export const getZakupkiData = (month) => {
    return async (dispatch) => {
        await GetPivotApi(month).then((r) => {
            dispatch(fetchDataAction(r.data.result));
        });
    };
};

export const getZakupkiDataList = (date1, date2, page, limit) => {
    return async (dispatch) => {
        await GetPivotListApi(date1, date2, page, limit).then((r) => {
            dispatch(fetchDataListAction(r.data));
        });
    };
};
