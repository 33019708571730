import { 
  FETCH_REPORT_CRM_DEAL,
  FETCH_REPORT_CRM_LEAD,
  FETCH_REPORT_CRM_STATUS_DEAL,
  FETCH_REPORT_CRM_STATUS_LEAD,
  FETCH_REPORT_CRM_STATUS_REPORT_LEAD,
  FETCH_REPORT_CRM_STATUS_REPORT_RESULT
} from "../../types/types"

export const dealAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_DEAL,
    payload
  }
)

export const leadAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_LEAD,
    payload
  }
)

export const statusDealAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_STATUS_DEAL,
    payload
  }
)

export const statusLeadAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_STATUS_LEAD,
    payload
  }
)

export const statusReportLeadAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_STATUS_REPORT_LEAD,
    payload
  }
)

export const statusReportResultAction = (payload)=>(              
  {
    type: FETCH_REPORT_CRM_STATUS_REPORT_RESULT,
    payload
  }
)



