import { 
	DYNAMIC_MENU_SETTING_LEFT_MENU,
	DYNAMIC_MENU_ADD_ITEM,
	DYNAMIC_MENU_REMOVE_ITEM,
	DYNAMIC_MENU_REMOVE_SUB_ITEM,
	DYNAMIC_MENU_EDIT_ITEM,
	DYNAMIC_MENU_RENEW_BOARD,
	DYNAMIC_MENU_CREATE_BOARDS,
	DYNAMIC_MENU_CREATE_MENU
} from "../../types/types"

export const settingLeftMenuAction = (payload) => (              
	{
		type: DYNAMIC_MENU_SETTING_LEFT_MENU,
		payload
	}
)

export const createBoardsAction = (payload) => (              
	{
		type: DYNAMIC_MENU_CREATE_BOARDS,
		payload
	}
)

export const createMenuAction = (payload) => (              
	{
		type: DYNAMIC_MENU_CREATE_MENU,
		payload
	}
)

export const addItemAction = (payload) => (              
	{
		type: DYNAMIC_MENU_ADD_ITEM,
		payload
	}
)

export const removeItemAction = (payload) => (              
	{
		type: DYNAMIC_MENU_REMOVE_ITEM,
		payload
	}
)

export const removeSubItemAction = (payload) => (              
	{
		type: DYNAMIC_MENU_REMOVE_SUB_ITEM,
		payload
	}
)

export const editItemAction = (urlParams, payload) => (              
	{
		type: DYNAMIC_MENU_EDIT_ITEM,
		payload,
		urlParams
	}
)

export const renewBoardsAction = (payload) => (              
	{
		type: DYNAMIC_MENU_RENEW_BOARD,
		payload
	}
)