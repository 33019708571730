import axios from "axios"
import { api } from "../FilterDepartment/functions"

/**
 * Получаем список сотрудников отдела
 * @returns 
 */
export const employeeListApi = (filter: any) => {
	let params = filter.department && !!filter.department.length && filter.department.map((item: any) => `&department[]=${item}`).join("") || ""
    return axios.get(api.url+`employee/list/?active=1${params}`, api.config).then((r:any) => r.data)
}

/**
 * Получаем список сотрудников департаментов
 * @param setEmployee 
 */
export const fetchListEmployee = async (setEmployee:any, filter: any) => {
	let employees = await employeeListApi(filter).then((r:any) => r.result)
	setEmployee(employees)
}
