import { TaskGroupResultProps } from "../../props";
import { ArDataProps } from "./ChartTaskMark.props";
import { arColor } from "../colors";

/**
 * Настройки для графика
 */
export const options = (setFilter: any = false) => {

  return {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Задачи с оценками',
      },
    },
    onClick: function(evt: any, element: any) {
      if(element.length > 0) {
          const index = element[0].element.$context.dataIndex === 0 
          ? null
          : element[0].element.$context.dataIndex

          if(setFilter) {
            setFilter((prev: any) => ({...prev, chartTaskMark: index}))
          }
            
      }
    }
  }
};

/**
 * Наполняем массив данныеми, полученными в запросе
 * @param arData 
 */
export const createData = (taskGroup: TaskGroupResultProps[]) => {

  /**
   * Массив по умолчанию с пустыми значениями
   */
  const arData: ArDataProps[] = [
      {label: 'нет', count: 0},
      {label: '1', count: 0},
      {label: '2', count: 0},
      {label: '3', count: 0},
      {label: '4', count: 0},
      {label: '5', count: 0}
  ]

  //Если пришел ответ с сервера
  if(!!taskGroup.length) {
      //Наполняем массив данными с taskGroup
      arData.forEach((item, i) => {
          taskGroup.forEach(el => {
              if(
              item.label === el.grade || 
              (item.label === "нет" && el.grade === null)
              ) {
              arData[i].count = Number(el.count)
              }
          })
      })
  }

  return {
      labels: arData.map(el => el.label),
      datasets: [
          {
              label: 'Задач',
              data: arData.map(el => el.count),
              backgroundColor: [arColor[0], arColor[1], arColor[2], arColor[3], arColor[4], arColor[5]],
          },
      ],
  };
  
}