import { 
  FETCH_REPORT_TIME_MONTH,
  FETCH_REPORT_TIME_LINE,
  FETCH_REPORT_TIME_TASK,
  FETCH_REPORT_TIME_DEAL,
  FETCH_REPORT_TIME_EVENT
} from "../../types/types"

/**
 * Reducer->reportTimeMonth
 * */ 
export const reportTimeMonthAction = (payload)=>(              
  {
    type:FETCH_REPORT_TIME_MONTH,
    payload
  }
)

/**
 * Reducer->reportTimeLine
 * */ 
export const reportTimeLineAction = (payload)=>(
  {
    type: FETCH_REPORT_TIME_LINE,
    payload
  }
)

/**
 * Reducer->reportTimeTask
 * */ 
export const reportTimeTaskAction = (payload)=>(
  {
    type: FETCH_REPORT_TIME_TASK,
    payload
  }
)

export const reportTimeDealAction = (payload)=>(
  {
    type: FETCH_REPORT_TIME_DEAL,
    payload
  }
)

export const reportTimeEventAction = (payload)=>(
  {
    type: FETCH_REPORT_TIME_EVENT,
    payload
  }
)



