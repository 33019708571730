import { combineReducers } from 'redux';
import worktime from './worktime';
import puls from './task/puls';
import failureStatus from './failureStatus';

export default combineReducers({
    worktime,
    puls,
    failureStatus,
});
