import axios from 'axios';
import { api } from '../../../reusableComponents';
import { FilterProps } from '../../../pageComponents/ReportSalary/ReportSalary.props';

/**
 * Получаем пункты меню
 * @returns 
 */
export const settingLeftMenuApi = () => {
    /*return axios.get(api.url + `setting/LEFT_MENU`, api.config).then(r => (
        r.data = r.data.map((el: any, key: number) => ({...el, order: key + 1}))
    ))*/
    return axios.get(api.url + `setting/LEFT_MENU`, api.config).then(r => r.data)
}

/**
 * Отправляем сформированное меню на сервер
 * @returns 
 */
export const menuChangeApi = (data: any) => {
    return axios.put(api.url + `menu/change`, data, api.config).then(r => (r.data))
}