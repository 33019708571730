import axios from 'axios';
import { api } from '../../../reusableComponents';

export const fetchApiTenderplanCount = () => {
    return axios.get(api.url + `zakupki/tenderplan-count`, api.config).then((n) => n.data);
};

// const url = process.env.REACT_APP_API_URL;
const url = `http://localhost:3003/`;

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};

export const format = 'DD.MM.YYYY';

export const GetPivotApi = () => {
    //const dateMonthStart = moment().month(month).startOf('month').format(format);

    //    const dateMonthEnd = moment().month(month).endOf('month').format(format);

    return axios.get(`${url}./data_history.json`, {
        ...config,
        params: {
            //create_date_from: dateMonthStart,
            //create_date_to: dateMonthEnd,
        },
    });
};

export const GetPivotListApi = () => {
    //date1, date2, page, limit
    return axios.get(`${url}./list_history.json`, {
        ...config,
        params: {
            //create_date_from: date1,
            //create_date_to: date2,
            //page: page + 1,
            //pageSize: limit,
        },
    });
};
