import axios from 'axios';
const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};
export const GetUserProfileApi = (id = 0) => {
    if (id == 0) {
        return axios.get(url + `user/profile`, config);
    } else {
        return axios.get(url + `user/` + id + `/profile`, config);
    }
};
