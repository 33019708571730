export const fetchStaticSourceAction = (payload) => ({
    type: 'FETCH_STATIC_SOURCE',
    payload,
});
export const fetchSourceAction = (payload) => ({
    type: 'FETCH_SOURCE',
    payload,
});
export const fetchSourceIdAction = (payload) => ({
    type: 'FETCH_SOURCE_ID',
    payload,
});
export const setReplaceSuccessAction = () => ({ type: 'SET_REPLACE_SUCCESS' });
export const resetReplaceSuccessAction = () => ({
    type: 'RESET_REPLACE_SUCCESS',
});
export const fetchStaticVacancyAction = (payload) => ({
    type: 'FETCH_STATIC_VACANCY',
    payload,
});
export const setSourceAddErrorAction = (payload) => ({
    type: 'SET_SOURCE_EDD_ERROR',
    payload,
});
