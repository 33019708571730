// import external modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
// Styling
import '../../../assets/scss/components/sidebar/sidebar.scss';
// import internal(own) modules
import SideMenuContent from './sidemenu/sidemenu';
import SidebarHeader from './sidebarHeader/sidebarHeader';
import { FoldedContentConsumer } from '../../../utility/context/toggleContentContext';
import templateConfig from '../../../templateConfig';
import {
    sidebarImage,
    sidebarImageUrl,
    sidebarBgColor,
    sidebarCollapsed,
    sidebarSize,
} from '../../../redux/actions/customizer/customizerActions';
import UserBlock from './userblock/userblock';
import { Form, FormGroup, Label, Input } from 'reactstrap';

class Sidebar extends Component {
    state = {
        collapsedSidebar: templateConfig.sidebar.collapsed,
        width: window.innerWidth,
        layout: !!Number(localStorage.getItem("layout"))
    };
    updateWidth = () => {
        this.setState((prevState) => ({
            width: window.innerWidth,
        }));
    };

    handleCollapsedSidebar = (collapsedSidebar) => {
        this.setState({ collapsedSidebar });
    };

    componentDidMount() {
        this.setState((prevState) => ({
            collapsedSidebar:
                localStorage.getItem('menues') === 'open' ? true : false,
        }));
        if (window !== 'undefined') {
            window.addEventListener('resize', this.updateWidth, false);
        }
    }

    componentWillUnmount() {
        if (window !== 'undefined') {
            window.removeEventListener('resize', this.updateWidth, false);
        }
    }
    handleMouseEnter = (e) => {
        this.setState((prevState) => ({
            collapsedSidebar: false,
        }));
    };

    handleMouseLeave = (e) => {
        this.setState((prevState) => ({
            collapsedSidebar: true,
        }));
    };
    

    render() {
        return (
            <Fragment>
                <FoldedContentConsumer>
                    {(context) => (
                        <div
                            data-active-color="white"
                            data-background-color={
                                this.props.color === ''
                                    ? templateConfig.sidebar.backgroundColor
                                    : this.props.color
                            }
                            className={classnames(
                                'app-sidebar',
                                {
                                    '': !this.state.collapsedSidebar,
                                    collapsed: this.state.collapsedSidebar,
                                },
                                {
                                    'hide-sidebar':
                                        this.state.width < 991 &&
                                        this.props.sidebarState === 'close',
                                    '': this.props.sidebarState === 'open',
                                }
                            )}
                            onMouseEnter={
                                context.foldedContent
                                    ? this.handleMouseEnter
                                    : null
                            }
                            onMouseLeave={
                                context.foldedContent
                                    ? this.handleMouseLeave
                                    : null
                            }
                        >
                            <SidebarHeader
                                toggleSidebarMenu={this.props.toggleSidebarMenu}
                                sidebarBgColor={this.props.color}
                            />
                            <PerfectScrollbar className="sidebar-content">
                                <SideMenuContent
                                    collapsedSidebar={
                                        this.state.collapsedSidebar
                                    }
                                    toggleSidebarMenu={
                                        this.props.toggleSidebarMenu
                                    }
                                />
                            {process.env.REACT_APP_DEV_MODE
                            ?<>
                            <hr className="my-3" />
                            <Form className="px-3 py-1">
                                <div className="togglebutton">
                                    <FormGroup check>
                                        <Label check>
                                        <Input
                                            type="checkbox"
                                            id="cz-bg-image-display"
                                            checked={this.state.layout}
                                            onChange={e => {
                                                if (e.target.checked === true) {
                                                    this.setState({
                                                        ...this.state,
                                                        layout: true
                                                    })
                                                    localStorage.setItem("layout", 1)
                                                    this.props.handleLayout('layout-light');
                                                } else {
                                                    this.setState({
                                                        ...this.state,
                                                        layout: false
                                                    })
                                                    localStorage.setItem("layout", 0)
                                                    this.props.handleLayout('layout-dark');
                                                }
                                            }}
                                        />{" "}
                                        Светлый шаблон
                                        </Label>
                                    </FormGroup>
                                    <Link to={"/colorpalettes"}>Цвета и градиенты</Link><br />
                                    <Link to={"/uikit/feather"}>Иконки</Link>
                                    

                                </div>
                            </Form>
                            </>
                            : null}
                            </PerfectScrollbar>
                        
                            <UserBlock />

                            {/* {this.props.img === '' ? ( */}
                            {templateConfig.sidebar.backgroundImage ? (
                                this.props.imgurl === '' ? (
                                    <div
                                        className="sidebar-background"
                                        style={{
                                            backgroundImage:
                                                "url('" +
                                                templateConfig.sidebar
                                                    .backgroundImageURL +
                                                "')",
                                        }}
                                    ></div>
                                ) : (
                                    <div
                                        className="sidebar-background"
                                        style={{
                                            backgroundImage:
                                                "url('" +
                                                this.props.imgurl +
                                                "')",
                                        }}
                                    ></div>
                                )
                            ) : this.props.imgurl === '' ? (
                                <div className="sidebar-background"></div>
                            ) : (
                                <div
                                    className="sidebar-background"
                                    style={{
                                        backgroundImage:
                                            "url('" + this.props.imgurl + "')",
                                    }}
                                ></div>
                            )}
                        </div>
                    )}
                </FoldedContentConsumer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    color: state.customizer.sidebarBgColor,
    img: state.customizer.sidebarImage,
    imgurl: state.customizer.sidebarImageUrl,
    size: state.customizer.sidebarSize,
    collapsed: state.customizer.sidebarCollapsed,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    sidebarBgColor: (color) => dispatch(sidebarBgColor(color)),
    sidebarImage: (img) => dispatch(sidebarImage(img)),
    sidebarImageUrl: (imgurl) => dispatch(sidebarImageUrl(imgurl)),
    sidebarSize: (size) => dispatch(sidebarSize(size)),
    sidebarCollapsed: (collapsed) => dispatch(sidebarCollapsed(collapsed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
