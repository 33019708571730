const initialState = {
    lead: { totalcount: 0, result: [] },
    deal: { totalcount: 0, result: [] },
    task: { totalcount: 0, result: [] },
    activity: { totalcount: 0, result: [] },
};

export const entityListModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ENTITY_MODAL_DEAL':
            return {
                ...state,
                deal: action.payload,
            };
        case 'FETCH_ENTITY_MODAL_LEAD':
            return {
                ...state,
                lead: action.payload,
            };
        case 'FETCH_ENTITY_MODAL_TASK':
            return {
                ...state,
                task: action.payload,
            };
        case 'FETCH_ENTITY_MODAL_ACTIVITY':
            return {
                ...state,
                activity: action.payload,
            };
        default:
            return state;
    }
};
export default entityListModalReducer;
