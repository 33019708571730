import { fetchDealListCommonApi } from '../../servise/commonData/commonData';

export const fetchDealListCommonAction = (payload) => ({
    type: 'FETCH_DEAL_LIST_COMMON',
    payload,
});

export const fetchDealListCommon = (params) => {
    return async (dispatch) => {
        await fetchDealListCommonApi(params).then((r) => {
            dispatch(fetchDealListCommonAction(r));
        });
    };
};
