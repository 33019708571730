import axios from 'axios';
const url = process.env.REACT_APP_BASE_URL;
const urlApi = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};
export const fetchTableDataApi = (fetchDate, selectUser) => {
    const params = `date_report=${fetchDate}`;
    const paramsUser = selectUser ? `&manager_id=${selectUser}` : '';
    return axios.get(url + `hr/report/?${params}${paramsUser}`, config);
};
export const fetchUserApi = (params) => {
    return axios.get(urlApi + `employee/list/?${params}`, config);
};
export const fetchUserSettingApi = (flag) =>
    axios.get(urlApi + `setting/${flag}`, config);
