import { fetchStaticApi } from '../../servise/hr/hrStatic';
import { fetchStaticAction } from '../../actions/hr/static/static';

const initialState = {
    statics: [],
    totalCount: null,
};
const hrStaticReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_STATIC': {
            return {
                ...state,
                statics: action.payload.elements,
                totalCount: action.payload.totalCount,
            };
        }
        default:
            return state;
    }
};

export default hrStaticReducer;

export const fetchStatic = (limit, page) => {
    return async (dispatch) => {
        await fetchStaticApi(limit, page).then((r) => {
            dispatch(fetchStaticAction(r.data));
        });
    };
};
