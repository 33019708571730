import { fetchCallApi } from '../../servise/commonData/commonData';

export const fetchCallAction = (payload) => ({ type: 'FETCH_CALL', payload });
export const fetchCallDurationAction = (payload) => ({
    type: 'FETCH_CALL_DURATION',
    payload,
});
export const fetchCallCountAction = (payload) => ({
    type: 'FETCH_CALL_COUNT',
    payload,
});

export const fetchCall = (params, type) => {
    return async (dispatch) => {
        fetchCallApi(params).then((r) => {
            switch (type) {
                case 'duration':
                    dispatch(fetchCallDurationAction(r));
                    break;
                case 'callCount':
                    dispatch(fetchCallCountAction(r));
                    break;
                default: {
                }
            }
        });
    };
};
// export const fetchCallDetail = (duration="") => {
//     return async (dispatch) => {
//         let result = await fetchCallDetailApi(duration)
//         duration === "" ? dispatch(fetchCallDetailAction(result)) : dispatch(fetchCallDetailAllAction(result))
//
//     }
// }
export const fetchCallState = (duration) => {
    return async (dispatch) => {
        let result = await fetchCallApi('', duration);
        dispatch(fetchCallAction(result));
    };
};
