import { 
    FETCH_REPORT_CRM_DEAL,
    FETCH_REPORT_CRM_LEAD,
    FETCH_REPORT_CRM_STATUS_DEAL,
    FETCH_REPORT_CRM_STATUS_LEAD,
    FETCH_REPORT_CRM_STATUS_REPORT_LEAD,
    FETCH_REPORT_CRM_STATUS_REPORT_RESULT
} from "../../types/types"


import {
    dealApi,
    leadApi,
    statusDealApi,
    statusLeadApi,
    statusReportLeadApi,
    statusReportResultApi,
    userFieldApi
} from "../../servise/reportCrm/reportCrm"


import { 
    dealAction,
    leadAction,
    statusDealAction,
    statusLeadAction,
    statusReportLeadAction,
    statusReportResultAction
} from "../../actions/reportCrm/reportCrm"

const initialState = {
    arDeal: {
        dictionary: {
            manager:[],
            status:[]
        }
    },
    arLead: {
        dictionary: {
            manager:[],
            status:[]
        }
    },
    arStatusDeal: [],
    arStatusLead: [],
    statusReportResult: []
};

export const reportCrmReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_REPORT_CRM_DEAL:
            return {
                ...state,
                arDeal: action.payload
            }
        case FETCH_REPORT_CRM_LEAD:
            return {
                ...state,
                arLead: action.payload
            }
        case FETCH_REPORT_CRM_STATUS_DEAL:
            return {
                ...state,
                arStatusDeal: action.payload
            }
        case FETCH_REPORT_CRM_STATUS_LEAD:
            return {
                ...state,
                arStatusLead: action.payload
            }
        case FETCH_REPORT_CRM_STATUS_REPORT_LEAD:
            return {
                ...state,
                statusReportLead: action.payload
            }
        case FETCH_REPORT_CRM_STATUS_REPORT_RESULT:
            return {
                ...state,
                statusReportResult: action.payload
            }
        default:
            return state;
    }
};
export default reportCrmReducer

/**
 * Получаем сделки
 * @param {*} filter 
 * @returns 
 */
export const fetchDeal = (filter:any, arStatus:any) => {
    return async (dispatch:any) => {
        await dealApi(filter).then((r: any)=>{
            //Расширяем массив статусов данными по пользователю с количеством действий manager и с общим количеством действий total
            r.data.dictionary.status = arStatus
            r.data.dictionary.status.forEach((el:any, key:number) => {
                r.data.dictionary.status[key].manager = {}
                r.data.dictionary.status[key].total = 0
                r.data.result.forEach((item:any) => {
                    if(el.status_id === item.stage_id) {
                        r.data.dictionary.status[key].manager[item.assigned_by_id] = {
                            count: item.count,
                            sum: item.opportunity
                        }
                        r.data.dictionary.status[key].total += Number(item.count)
                    }
                })
                
            })
            dispatch(dealAction(r.data))
        })
    }
}

/**
 * Получаем лиды
 * @param {*} filter 
 * @returns 
 */
export const fetchLead = (filter:any, arStatus:any) => {
    return async (dispatch:any) => {
        await leadApi(filter).then((r: any)=>{
            //Расширяем массив статусов данными по пользователю с количеством действий manager и с общим количеством действий total
            r.data.dictionary.status = arStatus
            r.data.dictionary.status.forEach((el:any, key:number) => {
                r.data.dictionary.status[key].manager = {}
                r.data.dictionary.status[key].total = 0
                r.data.result.forEach((item:any) => {
                    if(el.status_id === item.status_id) {
                        r.data.dictionary.status[key].manager[item.assigned_by_id] = {
                            count: item.count,
                            sum: item.opportunity
                        }
                        r.data.dictionary.status[key].total += Number(item.count)
                    }
                })
                
            })
            dispatch(leadAction(r.data))
        })
    }
}

/**
 * Получаем массив статусов для сделок
 * @returns 
 */
export const fetchStatusDeal = (filter: any) => {
    return async (dispatch:any) => {
        await statusDealApi(filter).then((r: any)=>{
            console.log(r.data)
            if(filter.status && filter.status.length) {
                const status: any = {
                    processing: "",
                    failed: "F",
                    success: "S"
                }
                const stat = status[filter.status[0]]
                if(filter.status[0] === "processing") {
                    r.data = r.data.filter((item: any) => item.semantics === "")
                } else {
                    r.data = r.data.filter((item: any) => item.semantics === stat)
                }
            }
            dispatch(statusDealAction(r.data))
        })
    }
}

/**
 * Получаем массив статусов для лидов
 * @returns 
 */
export const fetchStatusLead = () => {
    return async (dispatch:any) => {
        await statusLeadApi().then((r: any)=>{
            dispatch(statusLeadAction(r.data))
        })
    }
}

/**
 * Получаем массив пользовательских свойств для лидов
 * @returns 
 */
export const fetchStatusReportLead = () => {
    return async (dispatch: any) => {
        const statusReportLead = await statusReportLeadApi().then((r: any) => {
            return r.data
        })
        const arUserProp: any[] = []
        for(let i = 0; i < statusReportLead.length; i++) {
            let r = await userFieldApi(statusReportLead[i])
            arUserProp.push(r.data)
        }
        return dispatch(statusReportLeadAction(arUserProp))
    }
}

/**
 * Получаем масив полей, которые нужно выводить
 * @returns 
 */
export const fetchStatusReportResult = () => {
    return async (dispatch: any) => await statusReportResultApi().then((r: any) => {
        dispatch(statusReportResultAction(r.data))})
}