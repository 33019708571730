import { fetchWithoutMarkApi } from '../../servise/withoutMark/withoutMark';
import { fetchWithoutMarkAction } from '../../actions/withoutMark/withoutMark';

const initialState = {
    list: [],
    totalCount: null,
};
const withoutMarkReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_WITHOUT_MARK': {
            return {
                ...state,
                list: action.payload.elements,
                totalCount: action.payload.totalCount,
            };
        }

        default:
            return state;
    }
};

export default withoutMarkReducer;

export const fetchWithoutMark = (limit, page) => {
    return async (dispatch) => {
        await fetchWithoutMarkApi(limit, page).then((r) => {
            dispatch(fetchWithoutMarkAction(r.data));
        });
    };
};
