const initialState = {
    time: 0,
    users: [],
};

export const worktime = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REPORT_WORKTIME':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
export default worktime;
