export const arColor: string[] = [
    "#009DA0",
    "#0CC27E",
    "#FF586B",
    "#1CBCD8",
    "#FF8D60",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#9e9e9e",
    "#607d8b", 
    "#000000",
    "#ffffff",
]

export const arBackground: string[] = [
    "gradient-pomegranate",
    "gradient-ibiza-sunset",
    "gradient-strawberry",
    "gradient-plum",
    "gradient-orange-amber",
    "gradient-purple-deep-purple",

    "gradient-purple-amber",
    "gradient-amber-amber",
    "gradient-king-yna",
    "gradient-blueberry",
    "gradient-mint",
    "gradient-orange",
    "gradient-green-tea",
    "gradient-blackberry",
    "gradient-passion-fruit",
    "gradient-sublime-vivid",
    "gradient-summer",
    "gradient-crystal-clear",
    "gradient-celestial",
    "gradient-mini",
    "gradient-dawn",
    "gradient-brady-brady-fun-fun",
    "gradient-cosmic-fusion",
    "gradient-nepal",
    "gradient-love-couple",
    "gradient-sunset",
    "gradient-grapefruit-sunset",
    "gradient-politics",
    "gradient-red-ocean",
    "gradient-back-to-earth",
    "gradient-flickr",
    "gradient-virgin-america",
    "gradient-purple-bliss",
    "gradient-man-of-steel",
    "gradient-shdow-night",
    "gradient-dracula",
    "gradient-bloody-mary",
    "gradient-harmonic-energy",
    "gradient-purple-love",
    "gradient-indigo-light-blue",
    "gradient-light-blue-indigo",
    "gradient-yellow-green",
    "gradient-orange-deep-orange",
    "gradient-deep-purple-purple",
    "gradient-red-pink",
    "gradient-light-green-amber",
    "gradient-purple-pink",
    "gradient-indigo-dark-blue",
    "gradient-teal-cyan",
    "gradient-blue-grey-blue-grey",
    "gradient-cyan-dark-green",
    "gradient-indigo-blue",
    "gradient-brown-brown",
    "gradient-blue-grey-blue",
    "gradient-purple-deep-orange",
    "gradient-green-teal",
    "gradient-purple-light-blue",
    "gradient-cyan-cyan",
    "gradient-yellow-teal",
    "gradient-cyan-light-green",
    "gradient-indigo-purple",
    "gradient-deep-purple-blue",
    "gradient-deep-orange-orange",
    "gradient-light-blue-cyan",
    "gradient-blue-indigo"
]

