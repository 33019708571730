// import external modules
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ToggleLeft, ToggleRight, X } from 'react-feather';
// import internal(own) modules
import { FoldedContentConsumer } from '../../../../utility/context/toggleContentContext';
import Logo from '../../../../assets/img/logo.png';
import LogoDark from '../../../../assets/img/logo-dark.png';
import templateConfig from '../../../../templateConfig';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../../../redux/actions/settings/settings';

const SidebarHeader = (props) => {
    const handleClick = (e) => {
        props.toggleSidebarMenu('close');
    };
    const dispatch = useDispatch();
    useEffect(() => {
        !settings && dispatch(fetchSettings('LOGO_NAME'));
    }, []);
    const { settings } = useSelector((r) => r.commonData);

    return (
        <FoldedContentConsumer>
            {(context) => (
                <div className="sidebar-header">
                    <div className="logo clearfix">
                        <NavLink to="/" className="logo-text float-left">
                            <div className="logo-img">
                                {templateConfig.sidebar.backgroundColor ===
                                'white' ? (
                                    props.sidebarBgColor === '' ||
                                    props.sidebarBgColor === 'white' ? (
                                        <img src={LogoDark} alt="logo" />
                                    ) : (
                                        <img src={Logo} alt="logo" />
                                    )
                                ) : props.sidebarBgColor === 'white' ? (
                                    <img src={LogoDark} alt="logo" />
                                ) : (
                                    <img src={Logo} alt="logo" />
                                )}
                            </div>
                            <span className="text align-middle">
                                {settings && settings}
                            </span>
                        </NavLink>
                        <span className="nav-toggle d-none d-sm-none d-md-none d-lg-block">
                            {context.foldedContent ? (
                                <ToggleLeft
                                    onClick={context.makeNormalContent}
                                    className="toggle-icon"
                                    size={16}
                                />
                            ) : (
                                <ToggleRight
                                    onClick={context.makeFullContent}
                                    className="toggle-icon"
                                    size={16}
                                />
                            )}
                        </span>
                        <span
                            href=""
                            className="nav-close d-block d-md-block d-lg-none d-xl-none"
                            id="sidebarClose"
                        >
                            <X onClick={handleClick} size={20} />
                        </span>
                    </div>
                </div>
            )}
        </FoldedContentConsumer>
    );
};

export default SidebarHeader;
