import React from 'react';

const Footer = (props) => (
    <footer>
        <div className="container-fluid">
            <p className="text-center m-0 mt-1">
                © 2021 Разработано <i className="ft-heart font-small-3" />
                <a href="https://atlassys.tech/" target="_blank" rel="noopener noreferrer">
                    {' '}
                    Atlassys Technology
                </a>
            </p>
        </div>
    </footer>
);

export default Footer;
