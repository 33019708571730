import React from "react"
import { IconProps } from './Icon.props';
import * as ReactIcon from 'react-feather';


/**
 * @param code символьный код иконки
 * @param className класс
 * @returns array
 */
export const Icon = ({ 
	code,
	className,
	onClick
}:IconProps) => {
	const TheItem: any = ReactIcon[code];
	return <div className={className} onClick={onClick}>
		<TheItem size={51} color="#000" />
	</div>
};