import {
    fetchTableDataApi,
    fetchUserApi,
    fetchUserSettingApi,
} from '../../servise/hr/hrReport';
import {
    fetchTableData,
    fetchUserAction,
    fetchUserHRAction,
} from '../../actions/hr/table/table';

const initialState = {
    table: [],
    user: [],
};
const hrTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TABLE':
            return {
                ...state,
                table: action.payload,
            };
        case 'FETCH_USER_ITEMS':
            return {
                ...state,
                user: action.payload,
            };
        case 'FETCH_USER_HR_ITEMS':
            return {
                ...state,
                userHR: action.payload,
            };
        default:
            return state;
    }
};

export default hrTableReducer;

export const fetchTable = (fetchDate, selectUser) => {
    return async (dispatch) => {
        await fetchTableDataApi(fetchDate, selectUser).then((r) => {
            dispatch(fetchTableData(r.data));
        });
    };
};
export const fetchUser = (flag) => {
    return async (dispatch) => {
        await fetchUserApi(flag).then((r) => {
            dispatch(fetchUserAction(r.data.result));
        });
    };
};
export const fetchUserHR = (flag) => {
    return async (dispatch) => {
        await fetchUserSettingApi(flag).then((r) => {
            dispatch(fetchUserHRAction(r.data));
        });
    };
};
