const initialState = {
    profile: {
        user_id: null,
        name: null,
        last_name: null,
        personal_photo: null,
    },
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USER_PROFILE':
            return {
                ...state,
                profile: action.payload,
            };
        default:
            return state;
    }
};
export default userReducer;
