import {
    FETCH_IMPORT_LIST
} from "../../types/types"

import {
    fetchImportListApi, 
    fetchImportDeleteApi
} from "../../servise/import/importList"

import {
    fetchImportListAction, 
} from "../../actions/import/importList"

const initialState = {
    list: {
        result: [],
        totalCount: 0
    }
}

const importList = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_IMPORT_LIST : {
            return {
                ...state,
                list: action.payload
            }
        }
        default:
            return state
    }
}

export default importList;

export const fetchImportList = (filter) => {
    return async (dispatch) => {
        const r = await fetchImportListApi(filter)
        if(r) {
            dispatch(fetchImportListAction(r))
        } else {
            window.location.href = "/import-setting/"
        }
    }
}

export const fetchImportDelete = (filter, setFilter) => {
    return async (dispatch) => {
        const response = await fetchImportDeleteApi(filter)
        setFilter(prev => ({...prev, modalRemoveOpen: false}))
    }
}