// Event Action Types
export const ADD_EVENT = 'ADD_EVENT';

// Chat Action Types
export const HANDLE_FILTER_USER = 'HANDLE_FILTER_USER';
export const HANDLE_SELECT_USER = 'HANDLE_SELECT_USER';
export const HANDLE_SEND_MESSAGE = 'HANDLE_SEND_MESSAGE';

// Import Action Types importList
export const FETCH_IMPORT_LIST = 'FETCH_IMPORT_LIST';

// Import Action Types importEdit
export const FETCH_IMPORT_COMPANY_FIELD = 'FETCH_IMPORT_COMPANY_FIELD';
export const FETCH_IMPORT_CONTACT_FIELD = 'FETCH_IMPORT_CONTACT_FIELD';
export const FETCH_IMPORT_LEAD_FIELD = 'FETCH_IMPORT_LEAD_FIELD';
export const FETCH_IMPORT_LEAD_USER_FIELD = 'FETCH_IMPORT_LEAD_USER_FIELD';
export const FETCH_IMPORT_DEAL_FIELD = 'FETCH_IMPORT_DEAL_FIELD';
export const FETCH_IMPORT_DEAL_USER_FIELD = 'FETCH_IMPORT_DEAL_USER_FIELD';
export const FETCH_IMPORT_DEAL_FUNNEL = 'FETCH_IMPORT_DEAL_FUNNEL';
export const FETCH_IMPORT_DEAL_STATUS = 'FETCH_IMPORT_DEAL_STATUS';

export const FETCH_IMPORT_GET_ELEMENT = 'FETCH_IMPORT_GET_ELEMENT';
export const FETCH_IMPORT_PUT_ELEMENT = 'FETCH_IMPORT_PUT_ELEMENT';

export const FETCH_REPORT_POSITION_MAIN_PAGE = 'FETCH_REPORT_POSITION_MAIN_PAGE';
export const FETCH_REPORT_POSITION_DETAIL_PAGE = 'FETCH_REPORT_POSITION_DETAIL_PAGE';

export const FETCH_REPORT_TIME_MONTH = "FETCH_REPORT_TIME_MONTH"
export const FETCH_REPORT_TIME_LINE = "FETCH_REPORT_TIME_LINE"
export const FETCH_REPORT_TIME_DEAL = "FETCH_REPORT_TIME_DEAL"
export const FETCH_REPORT_TIME_TASK = "FETCH_REPORT_TIME_TASK"
export const FETCH_REPORT_TIME_EVENT = "FETCH_REPORT_TIME_EVENT"

export const EVERYDAY_REPORT_SCREEN_FUNNEL_LIST = "EVERYDAY_REPORT_SCREEN_FUNNEL_LIST"
export const EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE = "EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE"
export const EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH = "EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH"
export const EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY = "EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY"
export const EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE = "EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE"
export const EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE = "EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE"
export const EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID = "EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID"
export const EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE = "EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE"
export const EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT = "EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT"
export const EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC = "EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC"
export const EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK = "EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK"

export const FETCH_REPORT_CRM_DEAL = "FETCH_REPORT_CRM_DEAL"
export const FETCH_REPORT_CRM_LEAD = "FETCH_REPORT_CRM_LEAD"
export const FETCH_REPORT_CRM_STATUS_DEAL = "FETCH_REPORT_CRM_STATUS_DEAL"
export const FETCH_REPORT_CRM_STATUS_LEAD = "FETCH_REPORT_CRM_STATUS_LEAD"
export const FETCH_REPORT_CRM_STATUS_REPORT_LEAD = "FETCH_REPORT_CRM_STATUS_REPORT_LEAD"
export const FETCH_REPORT_CRM_STATUS_REPORT_RESULT = "FETCH_REPORT_CRM_STATUS_REPORT_RESULT"

export const REPORT_SALARY_CALCULATION = "REPORT_SALARY_CALCULATION"

export const SALARY_DETAIL_CALCULATION = "SALARY_DETAIL_CALCULATION"
export const SALARY_DETAIL_TASK_LIST = "SALARY_DETAIL_TASK_LIST"
export const SALARY_DETAIL_REPORT_MONTH_TIME = "SALARY_DETAIL_REPORT_MONTH_TIME"
export const SALARY_DETAIL_TASK_GROUP = "SALARY_DETAIL_TASK_GROUP"
export const SALARY_DETAIL_ACTIVITY_LIST = "SALARY_DETAIL_ACTIVITY_LIST"
export const SALARY_DETAIL_EVENT_LIST = "SALARY_DETAIL_EVENT_LIST"

export const DYNAMIC_MENU_SETTING_LEFT_MENU = "DYNAMIC_MENU_SETTING_LEFT_MENU"
export const DYNAMIC_MENU_CREATE_MENU = "DYNAMIC_MENU_CREATE_MENU"
export const DYNAMIC_MENU_CREATE_BOARDS = "DYNAMIC_MENU_CREATE_BOARDS"
export const DYNAMIC_MENU_ADD_ITEM = "DYNAMIC_MENU_ADD_ITEM"
export const DYNAMIC_MENU_REMOVE_ITEM = "DYNAMIC_MENU_REMOVE_ITEM"
export const DYNAMIC_MENU_REMOVE_SUB_ITEM = "DYNAMIC_MENU_REMOVE_SUB_ITEM"
export const DYNAMIC_MENU_EDIT_ITEM = "DYNAMIC_MENU_EDIT_ITEM"
export const DYNAMIC_MENU_RENEW_BOARD = "DYNAMIC_MENU_RENEW_BOARD"
export const DYNAMIC_MENU_SEND_TO_SERVER = "DYNAMIC_MENU_SEND_TO_SERVER"

export const USER_TASK_DATA_TABLE = "USER_TASK_DATA_TABLE"
export const USER_TASK_LIST_TODAY = "USER_TASK_LIST_TODAY"
export const USER_TASK_LIST_TOMMOROW = "USER_TASK_LIST_TOMMOROW"
export const USER_TASK_LIST_WEEK = "USER_TASK_LIST_WEEK"
export const USER_TASK_LIST_MONTH = "USER_TASK_LIST_MONTH"
export const USER_TASK_LIST_TODAY_MORE = "USER_TASK_LIST_TODAY_MORE"
export const USER_TASK_LIST_TOMMOROW_MORE = "USER_TASK_LIST_TOMMOROW_MORE"
export const USER_TASK_LIST_WEEK_MORE = "USER_TASK_LIST_WEEK_MORE"
export const USER_TASK_LIST_MONTH_MORE = "USER_TASK_LIST_MONTH_MORE"