import { fetchGoogleDocApi } from '../../servise/commonData/commonData';

export const fetchGoogleDocAction = (payload) => ({
    type: 'FETCH_GOOGLE_DOC',
    payload,
});

export const fetchGoogleDoc = (params) => {
    return async (dispatch) => {
        fetchGoogleDocApi(params).then((r) => {
            dispatch(fetchGoogleDocAction(r.data));
        }).catch(e => ({data: []}));
    };
};
