import axios from "axios";
const url = process.env.REACT_APP_BASE_URL
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials:true
}
export const fetchImportListApi = (filter) => {
    return axios.get(url+`api/import-setting/list/?page=${filter.paginationPage}&pageSize=${filter.paginationPageSize}`, config).then(r => r.data).catch(error => false)
}

export const fetchImportDeleteApi = (filter) => {
    return axios.delete(url+`api/import-setting/${filter.removeItemId}`, config)
}