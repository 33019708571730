import { 
    USER_TASK_LIST_TODAY,
    USER_TASK_LIST_TOMMOROW,
    USER_TASK_LIST_WEEK,
    USER_TASK_LIST_MONTH,
    USER_TASK_LIST_TODAY_MORE,
    USER_TASK_LIST_TOMMOROW_MORE,
    USER_TASK_LIST_WEEK_MORE,
    USER_TASK_LIST_MONTH_MORE,
    USER_TASK_DATA_TABLE,
} from "../../types/types"

import { 
    taskListTodayApi,
    taskListTommorowApi,
    taskListWeekApi,
    taskListMonthApi,
    taskGroupTodayApi,
    taskGroupTommorowApi,
    taskGroupWeekApi,
    taskGroupMonthApi,
} from '../../servise/userTask/userTask';

import {
    taskListTodayAction,
    taskListTommorowAction,
    taskListWeekAction,
    taskListMonthAction,
    taskListTodayMoreAction,
    taskListTommorowMoreAction,
    taskListWeekMoreAction,
    taskListMonthMoreAction,
    dataTableAction
} from '../../actions/userTask/userTask';

const initialState = {
    arEmployee: [],
    taskListToday: null,
    taskListTommorow: null,
    taskListWeek: null,
    taskListMonth: null,
    dataColumn: [],
    dataTable: []
};

export const userTaskReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case USER_TASK_LIST_TODAY:
            return {
                ...state,
                taskListToday: action.payload
            }
        case USER_TASK_LIST_TOMMOROW:
            return {
                ...state,
                taskListTommorow: action.payload
            }
        case USER_TASK_LIST_WEEK:
            return {
                ...state,
                taskListWeek: action.payload
            }
        case USER_TASK_LIST_MONTH:
            return {
                ...state,
                taskListMonth: action.payload
            }
        case USER_TASK_LIST_TODAY_MORE:
            action.payload.result = [...state.taskListToday.result, ...action.payload.result]
            return {
                ...state,
                taskListToday: action.payload
            }
        case USER_TASK_LIST_TOMMOROW_MORE:
            action.payload.result = [...state.taskListTommorow.result, ...action.payload.result]
            return {
                ...state,
                taskListTommorow: action.payload
            }
        case USER_TASK_LIST_WEEK_MORE:
            action.payload.result = [...state.taskListWeek.result, ...action.payload.result]
            return {
                ...state,
                taskListWeek: action.payload
            }
        case USER_TASK_LIST_MONTH_MORE:
            action.payload.result = [...state.taskListMonth.result, ...action.payload.result]
            return {
                ...state,
                taskListMonth: action.payload
            }
        case USER_TASK_DATA_TABLE:
            return {
                ...state,
                dataTable: action.payload
            }
        default:
            return state;
    }
};
export default userTaskReducer

/**
 * Получаем задачи за сегодня
 * @param filter 
 * @returns 
 */
export const fetchTaskListToday = (filter: any, more: any = false) => { 
    return async (dispatch: any) => {
        await taskListTodayApi(filter).then((r: any)=>{
            r.data.maxPage = Math.ceil(r.data.totalcount / 100)
            more 
            ? dispatch(taskListTodayMoreAction(r.data))
            : dispatch(taskListTodayAction(r.data))
        })
    }
}

/**
 * Получаем задачи за завтра
 * @param filter 
 * @returns 
 */
export const fetchTaskListTommorow = (filter: any, more: any = false) => { 
    return async (dispatch: any) => {
        await taskListTommorowApi(filter).then((r: any)=>{
            r.data.maxPage = Math.ceil(r.data.totalcount / 100)
            more 
            ? dispatch(taskListTommorowMoreAction(r.data))
            : dispatch(taskListTommorowAction(r.data))
            
        })
    }
}

/**
 * Получаем задачи на неделю
 * @param filter 
 * @returns 
 */
export const fetchTaskListWeek = (filter: any,  more: any = false) => { 
    return async (dispatch: any) => {
        await taskListWeekApi(filter).then((r: any)=>{
            r.data.maxPage = Math.ceil(r.data.totalcount / 100)
            more 
            ? dispatch(taskListWeekMoreAction(r.data))
            : dispatch(taskListWeekAction(r.data))
        })
    }
}

/**
 * Получаем задачи на месяц
 * @param filter 
 * @returns 
 */
export const fetchTaskListMonth = (filter: any,  more: any = false) => { 
    return async (dispatch: any) => {
        await taskListMonthApi(filter).then((r: any)=>{
            r.data.maxPage = Math.ceil(r.data.totalcount / 100)
            more 
            ? dispatch(taskListMonthMoreAction(r.data))
            : dispatch(taskListMonthAction(r.data))
            
        })
    }
}

/**
 * Получаем данные для построения таблицы
 */
export const fetchTableData = (filter: any) => {
    return async (dispatch: any) => {
        taskGroupMonthApi(filter)
        const result = await Promise.all([
            taskGroupTodayApi(filter), 
            taskGroupTommorowApi(filter),
            taskGroupWeekApi(filter),
            taskGroupMonthApi(filter)
        ]).then((period: any) => {
            let users = period.reduce((prev: any, item: any) => {
                const itemDictionaryManager = item.dictionary && item.dictionary.manager 
                ? item.dictionary.manager 
                : []

                return [...prev , ...itemDictionaryManager]
            }, [])
            //Оставляем уникальных сотрудников
            users = users.filter((user: any, index: any) => {
                const _user = JSON.stringify(user);
                return index === users.findIndex((obj: any) => {
                    return JSON.stringify(obj) === _user;
                });
            });

            const table: any = []
            for(const user of users) {
                const time = []
                //Считаем запланированное и фактическое время в каждом интервале
                for(const interval of period) {
                    const intervalResult =  interval.result ? interval.result : []
                    const total = intervalResult.reduce((prev: any, task: any) => {
                        if(user.id === task.responsible_id) {
                            prev.plan += Number(task.time_estimate) 
                            prev.fact += Number(task.time_spent_inlogs) 
                            return prev
                        } else {
                            return prev
                        }
                    }, {plan: 0, fact: 0})
                    time.push(total)
                }
                table.push({name: user.last_name + " " + user.name, result: time})
                dispatch(dataTableAction(table))
            }
        })
    }
}