import {parse} from 'query-string';

/**
 * Возвращает объект с параметрами
 * @param url window.location.search
 * @returns - {id: 45, page: 2, type: "dealInWork"}
 */
export const fncParamsFromUrl = (
    setFilter: any,
    url: string = window.location.search
) => {
    const paramsArr = parse(url)
    setFilter((prev: any) => ({...prev, paramsFromUrl: paramsArr}))
}

//CSS стили для input
export const customStyles = {
	option: (provided: any) => ({
		...provided,
		borderBottom: "1px solid grey",
		color: "black",
	}),
};

/**
 * Используется для запросов
 * export const queryApi = () => {return axios.get(api.url+ "user-field/UF_CRM_1617025486034", api.config)}
 */
/*
export const api:any = {
	url : process.env.REACT_APP_API_URL,
	config:	{
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
    	withCredentials:true
	}
}
*/