import React, {useState, useEffect} from "react"
import { FilterFunnelProps, ReactSelectProps } from './FilterFunnel.props';
import styles from './FilterFunnel.module.scss';
import Select from "react-select";
import axios from "axios";
import { funnelListApi, statusReportDealApi, userFieldApi } from "./FilterFunnelApi";
//CSS стили для input
const customStyles = {
	option: (provided: any) => ({
		...provided,
		borderBottom: "1px solid grey",
		color: "black",
	}),
};

/*
const url = process.env.REACT_APP_API_URL
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials:true
}
*/



const fetchFunnelList = async (setFilter:any) => {
	await funnelListApi().then(r => setFilter(r.data))
}

/**
 * Прокидываем пользовательское свойство userFields в фильтр 
 * @param id 
 * @param name 
 * @param setFilter 
 */
const fetchStatusReportDeal = async (value: any, setFilter: any) => {
	const code = await statusReportDealApi(value)
	const arProperty: any = []

	if(code) {
		for(let i = 0; i < code.length; i++) {

			userFieldApi(code[i]).then(r => {
				delete r.enum
				delete r.entity_id
				arProperty.push(r)
			})
			
	
		}
		setFilter((prev: any) => ({
			...prev,
			userFields: arProperty
		}))
	} else {
		setFilter((prev: any) => {
			prev = resetFilter(prev)
			return {
			...prev,
			userFields: null
			}
		})
	}
	
}
/**
 * Сбрасываем фильтр
 * @param prev 
 * @returns 
 */
const resetFilter = (prev: any) => {
	if(prev.userFields) {
		prev.userFields.forEach((input: any) => {
			const propertyCode = input.xml_id
			if(prev.hasOwnProperty(propertyCode))
				delete prev[propertyCode]
		})
	}
	return prev
}


/**
 * Компонент выпадающего списка воронки. Прокидывает в фильр: funnel, userFields
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * //@param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @returns array
 */
export const FilterFunnel: React.FC<FilterFunnelProps> = ({ 
	setFilter,
	isClearable = false,
	//isMulti = false,
	placeholder = "Выберите воронку",
}) => {


	//Список воронок
	const [list, setList] = useState([]);

	//Получаем список воронок при первой загрузке
	useEffect(() => {
		fetchFunnelList(setList)
	}, [])

	return (
		<Select
			className={styles.input}
			onChange={(data: ReactSelectProps) => {

				const value = data === null ? data : data.value

				fetchStatusReportDeal(value, setFilter)

				setFilter((prev: any) => {
					console.log("prev", prev)
					//Если не выбрана воронка, то удаляем все выбранные пользовательские свойства 
					if(value === null) {
						prev = resetFilter(prev)
						/*Object.keys(prev).forEach((code: any) => {
							if(code.includes("UF_CRM_")) {
								delete prev[code]
							}
						})*/
						return {
							...prev, 
							funnel: value
						}
					} else {
						return {
							...prev, 
							funnel: value
						}
					}
				})
					
			}}
			options={
				list.map((el:any):any => ({label: el.name, value: el.id}))
			}
			isMulti={false}
			isClearable={isClearable}
			placeholder={placeholder}
			styles={customStyles}
		/>
	)
};