import axios from 'axios';
import { api } from '../../../reusableComponents';
import getQueryString from '../helper';
import { TaskListQuery } from '../../types/task';

export const fetchApiTaskList = (params: TaskListQuery) => {
    return axios
        .get(api.url + `task/list/?${getQueryString(params)}`, api.config)
        .then((n) => n.data);
};

export const fetchApiTaskGroup = (params: object) => {
    return axios
        .get(api.url + `task/group/?${getQueryString(params)}`, api.config)
        .then((n) => n.data);
};
