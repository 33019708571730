import { fetchSettingsApi } from '../../servise/commonData/commonData';
import { settingByCode } from '../../servise/setting/setting';

export const fetchSettingsAction = (payload) => ({
    type: 'FETCH_SETTINGS',
    payload,
});
export const fetchSettingsIdAction = (payload) => ({
    type: 'FETCH_SETTINGS_ID',
    payload,
});

export const fetchSettingByCode = (code) => {
    return async (dispatch) => {
        const result = await settingByCode(code);
        dispatch({
            type: 'S_' + code,
            payload: result.data,
        });
    };
};

export const fetchSettings = (params) => {
    return async (dispatch) => {
        const result = await fetchSettingsApi(params);
        params === 'HR_FUNNEL_ID'
            ? dispatch(fetchSettingsIdAction(result.data))
            : dispatch(fetchSettingsAction(result.data));
    };
};
