import { FilterProps } from "../../../pageComponents/ReportSalary/ReportSalary.props";

import { 
    REPORT_SALARY_CALCULATION,
} from "../../types/types"

import { 
    reportSalaryCalculationApi 
} from '../../servise/reportSalary/reportSalary';

import { 
    reportSalaryCalculationAction 
} from '../../actions/reportSalary/reportSalary';

const initialState = {
    salaryCalculation:[]
};

export const reportSalaryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REPORT_SALARY_CALCULATION:
            return {
                ...state,
                salaryCalculation: action.payload
            }
        default:
            return state;
    }
};
export default reportSalaryReducer

export const fetchReportSalary = (filter: FilterProps) => {
    return async (dispatch: any) => {
        await reportSalaryCalculationApi(filter).then(r=>{
            dispatch(reportSalaryCalculationAction(r.data))
        })
    }
}
