import React, {useState, useEffect} from "react"
import { FilterStatusProps, ReactSelectProps } from './FilterUserField.props';
import styles from './FilterUserField.module.scss';
import Select from "react-select";
import { customStyles } from "../functions";
import { userFieldApi } from "./functions";
import { 
	UserFieldProps, 
	UserFieldEnumProps 
} from "../FilterUserField/FilterUserField.props";

/**
 * Компонент вывода пользовательского свойства. Прокидывает в фильр объект название свойста и значение
 * @param setFilter прокидываем фильтр
 * @param filterName название фильра которое будет проброшено в фильтр
 * @param userFieldCode название пользовательского св-ва 
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @param dependOn зависимость от свойства
 * @returns array
 */
export const FilterUserField: React.FC<FilterStatusProps> = ({ 
	setFilter,
	filterName,
	userFieldCode,
	isClearable = false,
	isMulti = false,
	placeholder = "Выберите вариант",
	dependOn = true
}) => {
	
	/*if(!dependOn) {
		setFilter((prev: any) => {
			return {
				...prev, 
				[filterName]: [],
				[filterName + "Code"]: ""
			}
		})
	}*/

	const [data, setData] = useState<UserFieldProps>()

	useEffect(()=> {
		userFieldApi(userFieldCode, setData)
	}, [])

	return (
		<Select
			className={styles.input}
			onChange={(e: any) => {
				//Приводим input к массиву, чтобы был как и множественное поле
				let value: ReactSelectProps[] = isMulti ? e : [e]
				//При сбросе в инпуте делаем пустое состояние такое же как и в множественном выборе
				if(!value[0]) value = []

				setFilter((prev: any) => {
					return {
						...prev, 
						[filterName]: value.map(el => el.value),
						//[filterName + "Code"]: userFieldCode,
					}
				})
			}}
			options={
				data && 
				data.enum.map(el => ({
					label: el.value, value: el.id
				}))
			}
			isMulti={isMulti}
			isClearable={isClearable}
			placeholder={placeholder}
			styles={customStyles}
		/>
	)
};