import axios from 'axios';
import { api } from '../../../reusableComponents';
import getQueryString from '../helper';
import { TaskElapsedGroupQuery } from '../../types/taskElapsed';

/**
 * Получаем данные о счетчиках задач
 * @param query
 * @returns
 */
export const taskElapsedGroupApi = (query: TaskElapsedGroupQuery) => {
    return axios.get(api.url + `task-elapsed/group?${getQueryString(query)}`, api.config);
};
