import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};

export const fetchEmployeeApi = (params = false) => {
    const param = params ? `&${params}` : '';
    return axios.get(url + `employee/list/?active=1${param}`, config);
};

export const fetchTaskTimeCountApi = (date, employee) => {
    return axios.get(
        url +
            `task/group/?responsible_id=${employee}&create_date_from=${date.dateStartMonth}&create_date_to=${date.dateEndMonth}&status[]=5&group_by[]=manager`,
        config,
    );
};

export const fetchTaskMarkApi = (date, employee) => {
    /*
    return axios.get(
        url +
            `task/group/?responsible_id=${employee}&create_date_from=${date.dateStartMonth}&create_date_to=${date.dateEndMonth}&status[]=5&group_by[]=mark`,
        config,
    );
    */
    return axios.get(
        url +
            `task/group/?responsible_id=${employee}&create_date_from=${date.dateStartMonth}&create_date_to=${date.dateEndMonth}&status[]=5&group_by[mark][]=grade`,
        config,
    );
    
};

export const fetchWorkTimeGroupApi = (date, employee) => {
    return axios.get(
        url +
            `worktime/group/?date_from=${date.dateStartMonth}&date_to=${date.dateEndMonth}&responsible_id=${employee}`,
        config,
    );
};
