import React, {useState, useEffect} from "react"
import { FilterEmployeeProps } from './FilterEmployee.props';
import './FilterEmployee.scss';
import Select from "react-select";
import { customStyles } from "../functions";
import { 
	fetchListEmployee
} from "./functions";

/**
 * Компонент вывода сотрудников. Прокидывает в фильр: employee
 * @param filter прокидываем фильтр
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @param className класс
 * @returns array
 */
export const FilterEmployee: React.FC<FilterEmployeeProps> = ({ 
	filter,
	setFilter,
	isClearable = false, 
	isMulti = false,
	placeholder = "Выберите сотрудника",
	className = ""
}) => {

	const [employee, setEmployee] = useState([]);

	useEffect(() => {
		fetchListEmployee(setEmployee, filter)
	}, [filter.employee, filter.department])

	return (
		<Select
			className={`${className} filter-employee`}
			onChange={(e: any) => {

				//Приводим input к массиву, чтобы был как и множественное поле
				let value = isMulti ? e : [e]
				//При сбросе в инпуте делаем пустое состояние такое же как и в иножественном выборе
				if(!value[0]) value = []

				setFilter((prev: any) => {
					//Если ничего не выбрано в фильтре, значит сотрудники равны null
					return {
						...prev, 
						employee: value.map((el: any) => el.value)
					}
				})
					
			}}
			options={
				!!employee.length && employee.map((el:any):any => ({
					label: ` ${el.last_name} ${el.name}`, value: el.id
				})) || []
			}
			isMulti={isMulti}
			isClearable={isClearable}
			placeholder={placeholder}
			styles={customStyles}
		/>
	)
};