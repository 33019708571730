import {
    fetchAddStaticDataApi,
    fetchStaticSourceApi,
    fetchStaticVacancyApi,
} from '../../servise/hr/hrSourceAdd';
import {
    fetchStaticSourceAction,
    fetchStaticVacancyAction,
} from '../../actions/hr/source/source';
import {
    setAddStaticSuccessAction,
    setResetStaticSuccessAction,
} from '../../actions/hr/static/static';
const initialState = {
    staticData: [],
    staticVacancy: [],
    addSuccess: false,
};
const hrStaticAddReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_STATIC_SOURCE': {
            return {
                ...state,
                staticData: action.payload,
            };
        }
        case 'SET_ADD_STATIC_SUCCESS': {
            return {
                ...state,
                addSuccess: true,
            };
        }
        case 'SET_RESET_STATIC_SUCCESS': {
            return {
                ...state,
                addSuccess: false,
            };
        }
        case 'FETCH_STATIC_VACANCY': {
            return {
                ...state,
                staticVacancy: action.payload,
            };
        }
        default:
            return state;
    }
};

export default hrStaticAddReducer;

export const addStaticData = (value) => {
    return async (dispatch) => {
        await fetchAddStaticDataApi(value).then(() =>
            dispatch(setAddStaticSuccessAction())
        );
    };
};
export const resetStaticSuccess = () => {
    return async (dispatch) => {
        dispatch(setResetStaticSuccessAction());
    };
};
export const fetchStaticSource = () => {
    return async (dispatch) => {
        await fetchStaticSourceApi().then((r) => {
            dispatch(fetchStaticSourceAction(r.data));
        });
    };
};
export const fetchStaticVacancy = () => {
    return async (dispatch) => {
        await fetchStaticVacancyApi().then((r) => {
            dispatch(fetchStaticVacancyAction(r.data));
        });
    };
};
