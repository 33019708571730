import React from "react"
import { HtagProps } from './Htag.props';
import './Htag.scss';

export const Htag = ({ 
	type, 
	children,
	className=""
 }: HtagProps): JSX.Element => {
	switch (type) {
		case 'h1':
			return <h1 className={`h1 ${className}`}>{children}</h1>;
		case 'is-like-h1':
			return <div className={`is-like-h1 ${className}`}>{children}</div>;
		case 'h2':
			return <h2 className={`h2 ${className}`}>{children}</h2>;
		case 'h3':
			return <h3 className={`h3 ${className}`}>{children}</h3>;
		default:
			return <>{children}</>;
	}
};