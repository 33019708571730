import {
    FETCH_IMPORT_COMPANY_FIELD,
    FETCH_IMPORT_CONTACT_FIELD,
    FETCH_IMPORT_LEAD_FIELD,
    FETCH_IMPORT_DEAL_FIELD,
    FETCH_IMPORT_DEAL_FUNNEL,
    FETCH_IMPORT_DEAL_STATUS,
    FETCH_IMPORT_GET_ELEMENT,
} from '../../types/types';

export const fetchImportCompanyFieldAction = (payload) => ({
    type: FETCH_IMPORT_COMPANY_FIELD,
    payload,
});

export const fetchImportContactFieldAction = (payload) => ({
    type: FETCH_IMPORT_CONTACT_FIELD,
    payload,
});

export const fetchImportLeadFieldAction = (payload) => ({
    type: FETCH_IMPORT_LEAD_FIELD,
    payload,
});

export const fetchImportDealFieldAction = (payload) => ({
    type: FETCH_IMPORT_DEAL_FIELD,
    payload,
});

export const fetchImportDealFunnelAction = (payload) => ({
    type: FETCH_IMPORT_DEAL_FUNNEL,
    payload,
});

export const fetchImportDealStatusAction = (payload) => ({
    type: FETCH_IMPORT_DEAL_STATUS,
    payload,
});
