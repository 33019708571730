import moment from "moment"
import { FilterDateArrProps } from "../Functions/Functions.props"

export const dateToArr = (date: string) => {
    const format: string = "DD.MM.YYYY"
    const dateObj: FilterDateArrProps = {
        date: moment(date, format).format("DD.MM.YYYY"),
        dayNum: Number(moment(date, format).format('D')),
        weekStart: moment(date, format).startOf('week').format('DD.MM.YYYY'),
        plus1Day: moment(date, format).add(1, 'days').format('DD.MM.YYYY'),
        monthStart: moment(date, format).startOf('month').format('DD.MM.YYYY'),
        monthEnd: moment(date, format).endOf('month').format('DD.MM.YYYY'),
        monthDays: moment(date, format).daysInMonth(),
        monthNum: Number(moment(date, format).format('M')),
        monthName: moment(date, format).format('MMMM'),
        monthName2: moment(date, format).format('DD MMMM').substr(3),
        year: Number(moment(date, format).format('YY')),
        year2: Number(moment(date, format).format('YYYY')),
        minus1Day: moment(date, format).subtract(1, 'days').format('DD.MM.YYYY'),
        minus2Day: moment(date, format).subtract(2, 'days').format('DD.MM.YYYY'),
        minus6Day: moment(date, format).subtract(6, 'days').format('DD.MM.YYYY'),
        minusMonth: moment(date, format).subtract(1, 'month').format('DD.MM.YYYY')
    }
    return dateObj
}