import moment from "moment";

import { 
    FETCH_REPORT_TIME_MONTH,
    FETCH_REPORT_TIME_LINE,
    FETCH_REPORT_TIME_TASK,
    FETCH_REPORT_TIME_DEAL,
    FETCH_REPORT_TIME_EVENT
} from "../../types/types"

import {
    reportTimeMonthApi,
    reportTimeLineApi,
    reportTimeTaskApi,
    reportTimeTaskNameApi,
    reportTimeDealApi,
    reportTimeEventApi
} from "../../servise/reportTime/reportTime"

import { 
    reportTimeMonthAction,
    reportTimeLineAction,
    reportTimeTaskAction,
    reportTimeDealAction,
    reportTimeEventAction
} from "../../actions/reportTime/reportTime"

const initialState = {
    reportTimeMonth:[],
    reportTimeLine: 0,
    reportTimeDeal: [],
    reportTimeTask: [],
    reportTimeEvent: []
};

export const reportTimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORT_TIME_MONTH:
            return {
                ...state,
                reportTimeMonth: action.payload
            }
        case FETCH_REPORT_TIME_LINE:
            return {
                ...state,
                reportTimeLine: action.payload
            }
        case FETCH_REPORT_TIME_TASK:
            return {
                ...state,
                reportTimeTask: action.payload
            }
        case FETCH_REPORT_TIME_DEAL:
            return {
                ...state,
                reportTimeDeal: action.payload
            }
        case FETCH_REPORT_TIME_EVENT:
            return {
                ...state,
                reportTimeEvent: action.payload
            }  
        default:
            return state;
            
    }
};
export default reportTimeReducer

/**
 * Получаем массив для главной страницы
 * @param {*} filter 
 * @returns 
 */
export const FetchReportTimeMonth = (filter) => {
    return async (dispatch) => {
        await reportTimeMonthApi(filter).then(r=>{
            //Если есть сотрудники в отделе
            if(filter.departmentEmployee && !!filter.departmentEmployee.length) {
                //const arrId = filter.departmentEmployee.map(employee => employee.id)
                //console.log("arrId--->", arrId)
                const result = r.data.filter(el => filter.departmentEmployee.includes(String(el.user_id)))
                dispatch(reportTimeMonthAction(result))
            } 
            //Если нет сотрудников в отделе
            else if(filter.departmentEmployee === false) {
                dispatch(reportTimeMonthAction([]))
            }
            //Если не был выбран департамент в фильтре
            else {
                dispatch(reportTimeMonthAction(r.data))
            }
        })
    }
}

/**
 * Получаем время на линии в секундах для детальной страницы
 * @param {*} filter 
 * @returns number
 */
export const FetchReportTimeLine = (filter) => {
    return async (dispatch) => {
        await reportTimeLineApi(filter).then(r =>{
            dispatch(reportTimeLineAction(r))
        })
    }
}

/**
 * Получаем список задач для детальной страницы
 * @param {*} filter 
 * @returns 
 */
export const FetchReportTimeTask = (filter) => {
    return async (dispatch) => {

        //Получаем время по задачам задачи
        const responseTaskTime = await reportTimeTaskApi(filter)
        const params = responseTaskTime.map(task => "id[]=" + task.task_id).join("&")
        //Получаем названия задач
        const responseTaskName = await reportTimeTaskNameApi(params)

        //Получаем общий массив по задачам с названием и временем
        const arr = responseTaskTime.map((el,key) => ({
            ...responseTaskTime[key], 
            ...responseTaskName[key]
        }))
        dispatch(reportTimeTaskAction(arr))
    }
}

/**
 * Получаем список дел для детальной страницы
 * @param {*} filter 
 * @returns 
 */
export const FetchReportTimeDeal = (filter) => {
    return async (dispatch) => {
        const response = await reportTimeDealApi(filter).then(r => {
            //Считаем время сделки по каждому элементу
            const deals = r.data.result.map(el => {
                const startTime = moment(el.start_time, "YYYY-MM-DD HH:mm:ss")
                const endTime = moment(el.end_time, "YYYY-MM-DD HH:mm:ss")
                el.time = endTime.diff(startTime, "second")
                return el
            })
            dispatch(reportTimeDealAction(deals))
        })
    }
}

/**
 * Получаем список дел для детальной страницы
 * @param {*} filter 
 * @returns 
 */
export const FetchReportTimeEvent = (filter) => {
    return async (dispatch) => {
        await reportTimeEventApi(filter).then(r => {
            dispatch(reportTimeEventAction(r.data))
        })
    }
}
