// import external modules
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Spinner from '../components/spinner/spinner';

// import internal(own) modules
import MainLayoutRoutes from '../layouts/routes/mainRoutes';
import FullPageLayout from '../layouts/routes/fullpageRoutes';

import { FetchUserProfile } from '../redux/actions/user/userActions';
import { connect } from 'react-redux';

// Full Layou
const LazyAuth = lazy(() => import('../views/auth/login'));

// Main Layout
const LazyEcommerceDashboard = lazy(() => import('../views/dashboard/ecommerceDashboard'));
const LazyOperational = lazy(() => import(`../views/operational/operational`));
const LazyWorkTime = lazy(() => import('../views/report/worktime'));
const LazyReportTime = lazy(() => import('../views/reportTime/reportTime'));
const LazyWithoutMark = lazy(() => import('../views/withoutMark/withoutMark'));
const LazyHrSources = lazy(() => import('../views/hr/hrSource/hrSource'));
const LazyGeneralReport = lazy(() => import('../views/generalReport/generalReport'));
const LazyReportCrm = lazy(() => import('../views/reportCrm/reportCrm'));
const LazyCallStatistic = lazy(() => import('../views/call/statistic'));
const LazyReportPosition = lazy(() => import('../views/reportPosition/reportPosition'));
const LazyReportSalary = lazy(() => import('../views/reportSalary/reportSalary'));
const LazyMarketingPlan = lazy(() => import('../views/marketingPlan/marketingPlan'));
const LazyDailyReport = lazy(() => import('../views/dailyReportScreen/dailyReportScreen'));
const LazyImportList = lazy(() => import('../views/import/importList'));
const LazyImportEdit = lazy(() => import('../views/import/importEdit'));
const LazySalaryDetail = lazy(() => import('../views/salaryDetail/salaryDetail'));
const LazySalaryDetailNew = lazy(() => import('../views/salaryDetailNew/salaryDetailNew'));
const LazyAllTaskReport = lazy(() => import('../views/allTaskReport/allTaskReport'));
const LazyDynamicMenu = lazy(() => import('../views/dynamicMenu/dynamicMenu'));
const LazyColorPallete = lazy(() => import('../views/colorPalletes/colorPallete'));
const LazyFeather = lazy(() => import('../views/ui-kit/feather'));
const LazyHrSourceReplace = lazy(() => import('../views/hr/hrSource/hrSourceReplace'));
const LazyMarketingPlanReplace = lazy(() => import('../views/marketingPlan/marketingPlanReplace'));
const LazyFailureStatus = lazy(() => import('../views/report/failureStatus'));
const LazyTaskPuls = lazy(() => import('../views/report/taskpuls'));
const LazyUserTask = lazy(() => import('../views/userTask/userTask'));
const LazyZakupkiHistoryPivot = lazy(() => import('../views/zakupki/ZakupkiHistoryPivot'));
const LazyZakupkiHistoryPivotList = lazy(() => import('../views/zakupki/ZakupkiHistoryPivotList'));
const LazyZakupkiDashboard = lazy(() => import('../views/zakupki/ZakupkiDashboard'));

/*
const LazyReportMount = lazy(() => import('../views/reportMonth/reportMonth'))
const LazyHrStatic = lazy(() => import('../views/hr/hrStatic/hrStatic'))
const LazyHrAddStatic = lazy(() => import('../views/hr/hrStatic/hrAddStatic'))
const LazyHrTable = lazy(() => import('../views/hr/hrTable/hrTable'))
const LazyHrTarget = lazy(() => import('../views/hr/hrTarget/hrTarget'))
const LazyHrTargetReplace = lazy(() =>import('../views/hr/hrTarget/hrTargetReplace'))
*/

export let menu = [
    {
        cid: 1,
        name: 'Стартовая страница',
        url: '/',
        component: LazyEcommerceDashboard,
        show: false,
    },
    {
        cid: 2,
        name: 'Оперативный отчёт',
        url: '/operational',
        component: LazyOperational,
        show: true,
    },
    { cid: 3, name: 'Рабочее время', url: '/report/worktime', component: LazyWorkTime, show: true },
    {
        cid: 4,
        name: 'Рабочий график',
        url: '/staff/report/time',
        component: LazyReportTime,
        show: true,
    },
    {
        cid: 5,
        name: 'Задачи без оценки',
        url: '/staff/task/withoutmark',
        component: LazyWithoutMark,
        show: true,
    },
    { cid: 6, name: 'Источники резюме', url: '/hr/source', component: LazyHrSources, show: true },
    {
        cid: 7,
        name: 'Общий отчёт',
        url: '/hr/general/report',
        component: LazyGeneralReport,
        show: true,
    },
    { cid: 8, name: 'Сделки', url: '/report/crm/deals', component: LazyReportCrm, show: true },
    { cid: 9, name: 'Лиды', url: '/report/crm/leads', component: LazyReportCrm, show: true },
    {
        cid: 10,
        name: 'Статистика звонков',
        url: '/staff/call/statistic',
        component: LazyCallStatistic,
        show: true,
    },
    {
        cid: 11,
        name: 'Отчёт по вакансиям',
        url: '/hr/report/position',
        component: LazyReportPosition,
        show: true,
    },
    {
        cid: 12,
        name: 'Ведомость зарплат',
        url: '/report/salary',
        component: LazyReportSalary,
        show: true,
    },
    {
        cid: 13,
        name: 'План маркетинга',
        url: '/marketing/plan',
        component: LazyMarketingPlan,
        show: true,
    },
    {
        cid: 14,
        name: 'Ежедневный отчёт',
        url: '/marketing/report/daily',
        component: LazyDailyReport,
        show: true,
    },
    {
        cid: 15,
        name: 'Список правил импорта',
        url: '/import-setting/list',
        component: LazyImportList,
        show: true,
    },
    {
        cid: 16,
        name: 'Создать правило',
        url: '/import-setting/create',
        component: LazyImportEdit,
        show: true,
    },
    {
        cid: 17,
        name: 'Редактировать правило',
        url: '/import-setting/:id',
        component: LazyImportEdit,
        show: false,
    },
    /*{
        cid: 18,
        name: 'Ведомость зарплат детально',
        url: '/report/salary/:id',
        component: LazySalaryDetail,
        show: false,
    },*/
    {
        cid: 1000,
        name: 'Ведомость зарплат детально',
        url: '/report/salary/:id',
        component: LazySalaryDetailNew,
        show: false,
    },
    {
        cid: 19,
        name: 'Отчет по здачам',
        url: '/staff/task/report',
        component: LazyAllTaskReport,
        show: true,
    },
    {
        cid: 20,
        name: 'Динамическое меню',
        url: '/dynamic-menu',
        component: LazyDynamicMenu,
        show: false,
    },
    {
        cid: 21,
        name: 'Цвета и градиенты шаблона',
        url: '/colorpalettes',
        component: LazyColorPallete,
        show: false,
    },
    { cid: 22, name: 'Иконки шаблона', url: '/uikit/feather', component: LazyFeather, show: false },
    {
        cid: 23,
        name: 'Добавление/редактирование ресурса',
        url: '/hr/source/replace/:id?',
        component: LazyHrSourceReplace,
        show: false,
    },
    {
        cid: 24,
        name: 'Добавление/редактирование плана',
        url: '/marketing/plan/replace/:id?',
        component: LazyMarketingPlanReplace,
        show: false,
    },
    {
        cid: 25,
        name: 'Отказ по лидам',
        url: '/failure/status/lead',
        url2: '/failure/status/:entity_type?',
        component: LazyFailureStatus,
        show: true,
    },
    {
        cid: 26,
        name: 'Отказ по сделкам',
        url: '/failure/status/deal',
        url2: '/failure/status/:entity_type?',
        component: LazyFailureStatus,
        show: true,
    },
    {
        cid: 27,
        name: 'LazyTaskPuls',
        url: '/report/puls/:userId/:date',
        component: LazyTaskPuls,
        show: false,
    },
    {
        cid: 28,
        name: 'Задачи пользователей',
        url: '/user-task',
        component: LazyUserTask,
        show: false,
    },
    {
        cid: 29,
        name: 'Анализ закупок (сводная)',
        url: '/zakupki/history/pivot',
        component: LazyZakupkiHistoryPivot,
        show: true,
    },
    {
        cid: 30,
        name: 'Анализ закупок (список)',
        url: '/zakupki/history/list',
        component: LazyZakupkiHistoryPivotList,
        show: true,
    },
    {
        cid: 31,
        name: 'Закупки Dashboard',
        url: '/zakupki/dashboard',
        component: LazyZakupkiDashboard,
        show: true,
    },
    /*{name: "Добавления/редактирования цели", url:"/hr/target/replace/:id?/:type_id?/:person?/:resume?/", component: LazyHrTargetReplace, show: true},*/
    /*{name: "Итоговый отчёт", url:"/hr/table", component: LazyHrTable, show: true},*/
    /*{name: "Добавление цели", url:"/hr/target", component: LazyHrTarget, show: true},*/
    /*{name: "Ручная статистика", url:"/hr/static", component: LazyHrStatic, show: true},*/
    /*{name: "Добавление статистики", url:"/hr/static/add", component: LazyHrAddStatic, show: true},*/
    /*{name: "Отчёт за месяц", url: "/staff/report/month", component: LazyReportMount, show: true},*/
];

class Router extends Component {
    componentWillMount() {
        this.props.getUser();
    }

    componentDidUpdate() {
        let path_login = window.location.pathname === '/login';
        if (this.props.user.profile.error) {
            if (!path_login) {
                window.location.pathname = '/login';
            }
        }
    }

    render() {
        return (
            // Set the directory path if you are deplying in sub-folder
            <BrowserRouter basename="/">
                <Switch>
                    <FullPageLayout
                        exact
                        path="/login"
                        render={(matchprops) => (
                            <Suspense fallback={<Spinner />}>
                                <LazyAuth {...matchprops} />
                            </Suspense>
                        )}
                    />
                    {menu.map((item, key) => {
                        let Component;
                        if (item.component) {
                            const Component = item.component;
                            return (
                                <MainLayoutRoutes
                                    key={key}
                                    exact
                                    path={item.url2 ? item.url2 : item.url}
                                    render={(matchprops) => {
                                        return (
                                            <Suspense fallback={<Spinner />}>
                                                <Component {...matchprops} />
                                            </Suspense>
                                        );
                                    }}
                                />
                            );
                        }
                    })}
                </Switch>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

const mapDispatchToProps = (dispatch) => ({
    getUser: () => dispatch(FetchUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
