import {
    fetchEmployeeApi,
    fetchWorkTimeGroupApi,
    fetchTaskTimeCountApi,
    fetchTaskMarkApi,
} from '../../servise/reportMonth/reportMonth';
import {
    fetchEmployeeAction,
    fetchWorkTimeGroupAction,
    fetchTaskTimeCountAction,
    fetchTaskMarkAction,
} from '../../actions/reportMonth/reportMonth';

const initialState = {
    workTimeGroup: null,
    taskTimeCount: {},
    taskMark: {},
};
const reportMonthReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_EMPLOYEE_REPORT': {
            return {
                ...state,
                employee: action.payload,
            };
        }

        case 'FETCH_WORK_TIME_GROUP': {
            return {
                ...state,
                workTimeGroup: action.payload,
            };
        }

        case 'FETCH_TASK_TIME_COUNT': {
            return {
                ...state,
                taskTimeCount: action.payload,
            };
        }

        case 'FETCH_TASK_MARK': {
            return {
                ...state,
                taskMark: action.payload,
            };
        }

        default:
            return state;
    }
};

export default reportMonthReducer;

export const fetchEmployee = (params) => {
    return async (dispatch) => {
        await fetchEmployeeApi(params).then((r) => {
            dispatch(fetchEmployeeAction(r.data.result));
        });
    };
};

export const fetchTaskTimeCount = (date, employee) => {
    return async (dispatch) => {
        if (employee) {
            await fetchTaskTimeCountApi(date, employee).then((r) => {
                dispatch(fetchTaskTimeCountAction(r.data.result[0]));
            });
        }
    };
};

export const fetchTaskMark = (date, employee) => {
    return async (dispatch) => {
        await fetchTaskMarkApi(date, employee).then((r) => {
            dispatch(fetchTaskMarkAction(r.data.result));
        });
    };
};

export const fetchWorkTimeGroup = (date, employee) => {
    return async (dispatch) => {
        await fetchWorkTimeGroupApi(date, employee).then((r) => {
            if (r.data.result)
                dispatch(
                    fetchWorkTimeGroupAction(
                        Number(r.data.result[0].count),
                    ),
                );
        });
    };
};
