// import {
//     fetchEmployeeApi,
//     fetchReportMonthTimeApi,
//     fetchReportMonthTimeCompletionApi,
//     fetchReportMonthTimeTimeApi
// } from "../../servise/ReportMonthTime/ReportMonthTime";
// import {
//     fetchEmployeeAction,
//     fetchReportMonthTimeAction,
//     fetchReportMonthTimeCompletionAction,
//     fetchReportMonthTimeTimeAction
// } from "../../actions/ReportMonthTime/ReportMonthTime";

const initialState = {};
const generalReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REPORT_TASK_LIST': {
            return {
                ...state,
                reportTaskList: action.payload,
            };
        }
        case 'FETCH_REPORT_TASK_LIST_ADDED': {
            return {
                ...state,
                reportTaskListAdded: action.payload,
            };
        }
        case 'FETCH_REPORT_TASK_LIST_DELAY': {
            return {
                ...state,
                reportTaskListDelay: action.payload,
            };
        }
        case 'FETCH_CALL_DURATION':
            return {
                ...state,
                callDuration:
                    action.payload.result && action.payload.result[0].count,
            };
        case 'FETCH_CALL_COUNT':
            return {
                ...state,
                callCount: action.payload.result && action.payload.result[0],
            };

        case 'FETCH_TASK_LIST_DELAY_CRM':
            return {
                ...state,
                delaysCrm: action.payload,
            };

        default:
            return state;
    }
};

export default generalReportReducer;
