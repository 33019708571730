const initialState = {
    worktime: [],
    callAttempt: [],
    callFact: [],
    taskPlan: [],
    taskFact: [],
    activityPlan: [],
    activityFact: [],
};

export const employeeTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_EMPLOYEE_TABLE_WORKTIME':
            return {
                ...state,
                worktime: action.payload.result,
            };
        case 'FETCH_ET_CALL_ATTEMPT':
            return {
                ...state,
                callAttempt: action.payload.result,
            };
        case 'FETCH_ET_CALL_FACT':
            return {
                ...state,
                callFact: action.payload.result,
            };
        case 'FETCH_ET_TASK_PLAN':
            return {
                ...state,
                taskPlan: action.payload.result,
            };
        case 'FETCH_ET_TASK_FACT':
            return {
                ...state,
                taskFact: action.payload.result,
            };
        case 'FETCH_ET_ACTIVITY_PLAN':
            return {
                ...state,
                activityPlan: action.payload.result,
            };
        case 'FETCH_ET_ACTIVITY_FACT':
            return {
                ...state,
                activityFact: action.payload.result,
            };
        default:
            return state;
    }
};
export default employeeTableReducer;
