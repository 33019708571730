import { GetCallStatisticApi } from "../../../redux/servise/call/statistic";
import {fetchCallGroupApi} from "../../servise/commonData/commonData";

export const GetCallStatisticAction = (payload)=>({type:"FETCH_CALL_STATISTIC",payload})
export const fetchCallGroupAction = (payload)=>({type:"FETCH_CALL_GROUP",payload})
export const fetchCallGroupDelayAction = (payload)=>({type:"FETCH_CALL_GROUP_DELAY",payload})

export const FetchCallStatistic = (params) => {
  return async (dispatch) => {
      await GetCallStatisticApi(params).then(r=>{
          dispatch(GetCallStatisticAction(r.data))
      })
  }
}
export const FetchCallStatisticReset = () => {
  return async (dispatch) => {
          dispatch(GetCallStatisticAction({    result : [],
              dictionary : {}}))
  }
}
export const fetchCallGroup = (params,delay) => {
  return async (dispatch) => {
      await fetchCallGroupApi(params).then(r=>{
        delay? dispatch(fetchCallGroupDelayAction(r)) : dispatch(fetchCallGroupAction(r))
      })
  }
}
