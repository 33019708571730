import axios from "axios";
const url = process.env.REACT_APP_BASE_URL

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',

    },
    withCredentials:true
}

export const fetchImportCompanyFieldApi = (type) => {
    return axios.get(url+`api/field/?type=1`, config)
}
export const fetchImportContactFieldApi = (type) => {
    return axios.get(url+`api/field/?type=2`, config)
}
export const fetchImportLeadFieldApi = () => {
    return axios.get(url+`api/field/?type=3`, config)
}
export const fetchImportDealFieldApi = () => {
    return axios.get(url+`api/field/?type=4`, config)
}
export const fetchImportDealFunnelApi = () => {
    return axios.get(url+`api/funnel/list`, config)
}
export const fetchImportDealStatusApi = () => {
    return axios.get(url+`api/status/deal`, config)
}
export const fetchImportCreateElementApi = (data) => {
    return axios.post(url+`api/import-setting/`, data, config)
}
export const fetchImportPutElementApi = (id, data) => {
    return axios.put(url+`api/import-setting/${id}`, data, config)
}
export const fetchImportGetElementApi = (id) => {
    return axios.get(url+`api/import-setting/${id}`, config)
}