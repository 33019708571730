import { fetchWorkResponseApi } from '../../servise/commonData/commonData';

export const fetchWorkResponseAction = (payload) => ({
    type: 'FETCH_WORK_RESPONSE',
    payload,
});
export const fetchWorkResponseNewAction = (payload) => ({
    type: 'FETCH_WORK_RESPONSE_NEW',
    payload,
});

export const fetchWorkResponse = (params, newRes) => {
    return async (dispatch) => {
        await fetchWorkResponseApi(params).then((r) => {
            newRes
                ? dispatch(fetchWorkResponseNewAction(r))
                : dispatch(fetchWorkResponseAction(r));
        });
    };
};
