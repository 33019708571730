import React, {useState, useEffect} from "react"
import { FilterStatusProps, ReactSelectProps } from './FilterStatus.props';
import styles from './FilterStatus.module.scss';
import Select from "react-select";
import { customStyles } from "../functions";

/**
 * Компонент вывода департаментов. Прокидывает в фильр: filterDepartmentArr, filterDepartmentEmployee
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @returns array
 */
export const FilterStatus: React.FC<FilterStatusProps> = ({ 
	setFilter,
	isClearable = false,
	isMulti = false,
	placeholder = "Выберите статус",
}) => {

	useEffect(() => {
		setFilter((prev: any) => {
			return {
				...prev, 
				status: []
			}
		})
	}, [])

	return (
		<Select
			className={styles.input}
			onChange={(e: any) => {
				//Приводим input к массиву, чтобы был как и множественное поле
				let value: ReactSelectProps[] = isMulti ? e : [e]
				//При сбросе в инпуте делаем пустое состояние такое же как и в множественном выборе
				if(!value[0]) value = []

				setFilter((prev: any) => {
					return {
						...prev, 
						status: value.map((el:ReactSelectProps) => el.value)
					}
				})
					
			}}
			options={
				[
					{ label: 'В работе', value: 'processing' },
					{ label: 'Не успешный', value: 'failed' },
					{ label: 'Успешный', value: 'success' },
				]
			}
			isMulti={isMulti}
			isClearable={isClearable}
			placeholder={placeholder}
			styles={customStyles}
		/>
	)
};