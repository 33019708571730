export const fetchEmployeeAction = (payload) => ({
    type: 'FETCH_EMPLOYEE_REPORT',
    payload,
});

export const fetchWorkTimeGroupAction = (payload) => ({
    type: 'FETCH_WORK_TIME_GROUP',
    payload,
});

export const fetchTaskTimeCountAction = (payload) => ({
    type: 'FETCH_TASK_TIME_COUNT',
    payload,
});

export const fetchTaskMarkAction = (payload) => ({
    type: 'FETCH_TASK_MARK',
    payload,
});
