const initialState = {
    user: {
        NAME: null,
        LAST_NAME: null,
    },
    task: [],
};

export const puls = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REPORT_PULS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
export default puls;
