export const arInterval = [
    {name: "На сегодня", code: "today"}, 
    {name: "На завтра", code: "tommorow"}, 
    {name: "На неделю", code: "week"}, 
    {name: "На месяц", code: "month"}
]

/**
 * Создаем параметры для запроса
 * @param filter 
 * @returns 
 */
export const createParams = (filter: any) => {
    let params = ""
    if(!!filter.employee.length) {
        params = filter.employee.map((id: any) => `&responsible_id[]=${id}`).join("")
    } else if(!!filter.departmentEmployee.length) {
        params = filter.departmentEmployee.map((id: any) => `&responsible_id[]=${id}`).join("")
    }
    return params
}

/**
 * Считаем плановое время по кажому сотруднику
 * @param arResult 
 * @param key 
 * @returns 
 */
export const columnPlan = (arResult: any, key: any) => {
    return arResult.reduce((num: any, item: any) => {
        item = item.result[key].plan ? item.result[key].plan : 0
        return num += Number(item)
    }, 0)
}

/**
 * Считаем фактическое время по кажому сотруднику
 * @param arResult 
 * @param key 
 * @returns 
 */
export const columnFact = (arResult: any, key: any) => {
    return arResult.reduce((num: any, item: any) => {
        item = item.result[key].fact ? item.result[key].fact : 0
        return num += Number(item)
    }, 0)
}