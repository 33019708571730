import React from "react"
import moment from "moment";
import { arColor } from "../../reusableComponents/colors";

export function dateToObject(currentDate, format = 'DD.MM.YYYY') {
    return {
        day: Number(moment(currentDate, format).format('D')),
        day2: moment(currentDate, format).format('DD'),
        date: currentDate,
        dateStartMonth: `01.${moment(currentDate, format).format(
            'MM.YYYY',
        )}`,
        dateEndMonth: `${moment(
            currentDate,
            format,
        ).daysInMonth()}.${moment(currentDate, format).format('MM.YYYY')}`,
        daysInMonth: moment(currentDate, format).daysInMonth(),
        //monthNum: Number(moment(currentDate, format).format('M')),
        //monthNum2: moment(currentDate, format).format('MM'),
        //monthName: moment(currentDate, format).format('MMMM'),
        //monthName2: moment(currentDate, format).format('DD MMMM').substr(3),
        //year: Number(moment(currentDate, format).format('YY')),
        //year2: Number(moment(currentDate, format).format('YYYY')),
        threeDayAgo: moment(currentDate, format).subtract(2, 'days').format('DD.MM.YYYY'),
        weekAgo: moment(currentDate, format).subtract(6, 'days').format('DD.MM.YYYY')
    };
}

/**
 * Вывод параметров под графиком
 */
export const underGraph = (adUtmSource) => {
    return <div className="under-graph">
        {adUtmSource.map(el => <div key={el.class} className={"ct-series-" + el.class}>
        <svg version="1.1" width="10" height="15">
            <rect width="10" height="10" strokeWidth="5" className="ct-bar"></rect>
        </svg>
        <span className="ml-1">{el.name}</span>
    </div>
    )}
</div>
}

export const onMouseOverHandler = e => {
    let father = e.target.closest("tr")
    let date = father.querySelector(".hover").innerHTML
    document.querySelector(".everyday-report-screen .for-date").innerHTML = date
}

export const onMouseOutHandler = e => {
    document.querySelector(".everyday-report-screen .for-date").innerHTML = ""
}

export const getLocalFunnelId = () => {
    const funnel = localStorage.getItem("dailyReportScreenFunnelId") 
    return funnel ? JSON.parse(funnel) : ""
}


/**
 * [Источник]
 * Добавляем к элементам св-ва name и class для постоения графика
 */
export function correctArraySourceId(data) {
    let alphabet = 'abcdefghijklmnopqrstuvwxyz'
    data.result && data.result.forEach((ad, key) => {
        data.result[key].class = alphabet[0]
        alphabet = alphabet.substr(1)
        if(ad.source_id === null) {
            return data.result[key].name = "Без источника"
        }
        if(ad.source_id === "") {
            return data.result[key].name = "Без источника 2"
        }
        if(ad.source_id === "SELF") {
            return data.result[key].name = "SELF"
        }
            
        data.dictionary.source_id.forEach(ad2 => {
            if(ad2.status_id === ad.source_id) {
                data.result[key].name = ad2.name
            }
        })
    })
    return data.result ? data.result : []
}

/**
 * [Рекламные каналы]
 * Добавляем к элементам св-ва name и class для постоения графика
 */
export function correctArrayUtmSource(data) {
    let alphabet = 'abcdefghijklmnopqrstuvwxyz'
    data.result && data.result.forEach((ad, key) => {
        data.result[key].class = alphabet[0]
        alphabet = alphabet.substr(1)
        if(ad.utm_source === null) {
            return data.result[key].name = "Без источника"
        } 
        !!data.dictionary.length && data.dictionary.utm_source.forEach(ad2 => {
            if(ad2.status_id === ad.utm_source) {
                data.result[key].name = ad2.name
            }
        })
    })
    return data.result ? data.result : []
}



/**
 * [Объем трафика]
 */
export const marketingTraffic = (dateStart, dateEnd, arr) => {
    const arResult = {
        list: [],
        ads:[]
    }
    //Вытаскиваем уникальные названия рекламных компаний
    if(!!arr.length) {
        arResult.ads = arr.map(el => el.source)
        arResult.ads = [...new Set(arResult.ads)]
        arResult.ads = arResult.ads.map((el, key) => ({name: el, color: arColor[key]}))
    }
    //Формируем массив с диапазоном дат и 0 значениями источников [Объем трафика]
    if(dateStart && dateEnd) {
        let date = dateStart
        while(moment(date, "DD.MM.YYYY").isBetween(
            moment(dateStart, "DD.MM.YYYY"), 
            moment(dateEnd, "DD.MM.YYYY"),
            undefined,
            []
        )) {
            const sources = {}
            //Наполняем объект пустыми значениями
            for(let el of arResult.ads) {
                sources[el.name] = 0
            }
            arResult.list[date] = {
                date,
                sources
            }
            date = moment(date,"DD.MM.YYYY").add(1, 'days').format("DD.MM.YYYY");
        }
    }
    //Наполняем массив значениями [Объем трафика]
    if(!!arr.length) {
        arr.forEach((el) => {
            if(arResult.list.hasOwnProperty(el.date)) {
                arResult.list[el.date].sources[el.source] = el.count
            }   
        })
    }
    return arResult
}