import React, { useState, useEffect } from 'react';
import { FilterDateProps, FilterDateArrProps } from '../FilterDate/FilterDate.props';
import styles from './FilterDateSwitcher.module.scss';
import Select from 'react-select';
import { dateToArr } from './functions';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import { FilterDate } from '../FilterDate/FilterDate';
import { customStyles } from '../index';

import moment from 'moment';
import 'moment/locale/ru';

/**
 * Компонент вывода департаментов. Прокидывает в фильр: FilterDateVal, FilterDateEmployee
 * @param filter прокидываем фильтр
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @param today пробрасывает фильтр от текущей даты
 * @param showDate2 фильтр второй даты
 * @returns array
 */
export const FilterDateSwitcher: React.FC<FilterDateProps> = ({
    filter,
    setFilter,
    isClearable = false,
    isMulti,
    placeholder = 'Выберите период',
    todayToFilter = false,
    showDate2 = false,
    date1 = '',
    date2 = '',
}) => {
    //dateToArr()
    const [data, setData] = useState<FilterDateArrProps>({
        date: '',
        plus1Day: '',
        dayNum: 0,
        monthStart: '',
        monthEnd: '',
        monthDays: 0,
        monthNum: 0,
        monthName: '',
        monthName2: '',
        year: 0,
        year2: 0,
        minus1Day: '',
        minus2Day: '',
        minus6Day: '',
        minusMonth: '',
        weekStart: '',
    });
    const [select, setSelect] = useState('');
    //let [startDate, setStartDate] = useState(new Date);
    //let [startDate2, setStartDate2] = useState(new Date);

    useEffect(() => {
        let date = moment().format('DD.MM.YYYY');
        setData(dateToArr(date));
    }, []);

    //let defaultValue: any = { label: 'За последние 7 дней', value: "last7day" }

    let defaultValue: any = null;
    if (Object.keys(filter.date1).length !== 0) {
        //Устанавливаем завтра
        if (filter.date1.date === filter.date2.date && filter.date1.date === data.plus1Day)
            defaultValue = { label: 'Завтра', value: 'tommorow' };
        //Устанавливаем сегодня
        else if (filter.date1.date === filter.date2.date && filter.date1.date === data.date)
            defaultValue = { label: 'Сегодня', value: 'today' };
        //Устанавливаем вчера
        else if (filter.date1.date === filter.date2.date && filter.date1.date === data.minus1Day)
            defaultValue = { label: 'Вчера', value: 'minus1Day' };
        //Устанавливаем текущую неделю
        else if (filter.date1.date === data.weekStart && filter.date2.date === data.date)
            defaultValue = { label: 'За текущую неделю', value: 'currentWeek' };
        //Устанавливаем последнюю неделю
        else if (filter.date1.date === data.minus6Day && filter.date2.date === data.date)
            defaultValue = { label: 'За последние 7 дней', value: 'last7day' };
        //Устанавливаем за текущий месяц
        else if (filter.date1.date === data.monthStart && filter.date2.date === data.date)
            defaultValue = { label: 'За текущий месяц', value: 'currentMonth' };
        //Устанавливаем за последний месяц
        else if (filter.date1.date === data.minusMonth && filter.date2.date === data.date)
            defaultValue = { label: 'За последний месяц', value: 'lastMonth' };
        //Точная дата
        /*else if(
			filter.date1.date === filter.date2.date
		) defaultValue = { label: 'Точная дата', value: "exactDate" }*/ else {
            defaultValue = { label: 'Диапазон дат', value: 'dateRange' };
        }
    }

    return (
        <>
            <Select
                className={styles.input}
                onChange={(e: any) => {
                    if (e) {
                        setSelect(e.value);
                    } else {
                        //Если происходит сброс поля
                        setSelect('');
                        setFilter((prev: any) => ({
                            ...prev,
                            date1: {},
                            date2: {},
                        }));
                    }
                }}
                options={[
                    //{ label: 'Не установлено', value: "" },
                    { label: 'Завтра', value: 'tommorow' },
                    { label: 'Вчера', value: 'minus1Day' },
                    { label: 'Сегодня', value: 'today' },
                    { label: 'За текущую неделю', value: 'currentWeek' },
                    { label: 'За последние 7 дней', value: 'last7day' },
                    { label: 'За текущий месяц', value: 'currentMonth' },
                    { label: 'За последний месяц', value: 'lastMonth' },
                    //{ label: 'Точная дата', value: "exactDate" },
                    { label: 'Диапазон дат', value: 'dateRange' },
                ]}
                isMulti={isMulti}
                isClearable={isClearable}
                placeholder={placeholder}
                styles={customStyles}
                value={defaultValue}
            />

            {/*Вчера*/}
            {select === 'minus1Day' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.minus1Day}
                    date2={data.minus1Day}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*Сегодня*/}
            {select === 'today' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.date}
                    date2={data.date}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*Завтра*/}
            {select === 'tommorow' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.plus1Day}
                    date2={data.plus1Day}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*За текущую неделю*/}
            {select === 'currentWeek' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.weekStart}
                    date2={data.date}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*За последние 7 дней*/}
            {select === 'last7day' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.minus6Day}
                    date2={data.date}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*За текущий месяц*/}
            {select === 'currentMonth' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.monthStart}
                    date2={data.date}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*За последний месяц (- ~30 дней)*/}
            {select === 'lastMonth' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={data.minusMonth}
                    date2={data.date}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}

            {/*Точная дата*/}
            {/*select === "exactDate" && <FilterDate 
			setFilter={setFilter} 
			date1={null}
			date2={null}
			todayToFilter={todayToFilter}
			showDate2={false} 
		/>*/}

            {/*Диапазон дат*/}
            {select === 'dateRange' && (
                <FilterDate
                    setFilter={setFilter}
                    date1={null}
                    date2={null}
                    //date1={""}
                    //date2={""}
                    showDate2={true}
                    todayToFilter={todayToFilter}
                />
            )}
        </>
    );
};
