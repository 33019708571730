import axios from 'axios';

import { api } from '../../../reusableComponents';
import { createParams } from '../../../pageComponents/UserTask/functions';

/**
 * Получаем данные пользователя за сегодня
 * @param filter 
 * @returns 
 */
export const taskListTodayApi = (filter) => {
    return axios.get(api.url+`task/list/?create_date_from=${filter.today}&create_date_to=${filter.today}${createParams(filter)}&page=${filter.pages.today}&pageSize=10`, api.config)
    //return axios.get(api.url+`task/list/?create_date_from=04.10.2021&create_date_to=04.10.2021${createParams(filter)}&page=${filter.pages.today}&pageSize=10`, api.config)
}
/**
 * Получаем данные пользователя за завтра*/
export const taskListTommorowApi = (filter) => {
    return axios.get(api.url+`task/list/?create_date_from=${filter.tommorow}&create_date_to=${filter.tommorow}${createParams(filter)}&page=${filter.pages.tommorow}&pageSize=10`, api.config)
    //return axios.get(api.url+`task/list/?create_date_from=05.10.2021&create_date_to=05.10.2021${createParams(filter)}&page=${filter.pages.tommorow}&pageSize=10`, api.config)
}
/**
 * Получаем данные пользователя за неделю*/
export const taskListWeekApi = (filter) => {
    return axios.get(api.url+`task/list/?create_date_from=${filter.weekStart}&create_date_to=${filter.weekEnd}${createParams(filter)}&page=${filter.pages.tommorow}&pageSize=10`, api.config)
    //return axios.get(api.url+`task/list/?create_date_from=06.10.2021&create_date_to=10.10.2021${createParams(filter)}&page=${filter.pages.week}&pageSize=10`, api.config)
}
/**
 * Получаем данные пользователя за месяц*/
export const taskListMonthApi = (filter) => {
    return axios.get(api.url+`task/list/?create_date_from=${filter.monthStart}&create_date_to=${filter.monthEnd}${createParams(filter)}&page=${filter.pages.tommorow}&pageSize=10`, api.config)
    //return axios.get(api.url+`task/list/?create_date_from=11.10.2019&create_date_to=31.10.2021${createParams(filter)}&page=${filter.pages.month}&pageSize=10`, api.config)

}



/**
 * Получаем данные пользователя за сегодня
 * @param filter 
 * @returns 
 */
export const taskGroupTodayApi = (filter) => {
    return axios.get(api.url+`task/group/?create_date_from=${filter.today}&create_date_to=${filter.today}${createParams(filter)}&group_by[]=manager`, api.config)
    //return axios.get(api.url+`task/group/?create_date_from=04.10.2021&create_date_to=04.10.2021${createParams(filter)}&group_by[]=manager`, api.config).then(r => r.data)
}

/**
 * Получаем данные пользователя за завтра
 **/
export const taskGroupTommorowApi = (filter) => {
    return axios.get(api.url+`task/group/?create_date_from=${filter.tommorow}&create_date_to=${filter.tommorow}${createParams(filter)}&group_by[]=manager`, api.config)
    //return axios.get(api.url+`task/group/?create_date_from=05.10.2021&create_date_to=05.10.2021${createParams(filter)}&group_by[]=manager`, api.config).then(r => r.data)
}

/**
 * Получаем данные пользователя за неделю
 **/
export const taskGroupWeekApi = (filter) => {
    return axios.get(api.url+`task/group/?create_date_from=${filter.weekStart}&create_date_to=${filter.weekEnd}${createParams(filter)}&group_by[]=manager`, api.config)
    //return axios.get(api.url+`task/group/?create_date_from=06.10.2021&create_date_to=10.10.2021${createParams(filter)}&group_by[]=manager`, api.config).then(r => r.data)
}

/**
 * Получаем данные пользователя за месяц
 **/
export const taskGroupMonthApi = (filter) => {
    return axios.get(api.url+`task/group/?create_date_from=${filter.monthStart}&create_date_to=${filter.monthEnd}${createParams(filter)}&group_by[]=manager`, api.config)
    //return axios.get(api.url+`task/group/?create_date_from=11.10.2019&create_date_to=31.10.2021${createParams(filter)}&group_by[]=manager`, api.config).then(r => r.data)

}






