import axios from 'axios';
const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};

/**
 * Получаем пользовательское свойство, по которому будем группировать запросы
 * @returns "UF_CRM_1617025486034"
 */
export const userFieldCodeApi = () => {
    return axios.get(url + `setting/HR_PROPERTY_POSITION`, config);
};

/**
 * Получаем свойство воронка, для использования в запросах
 * @returns "5"
 */
export const hrFunnelIdApi = () => {
    return axios.get(url + `setting/HR_FUNNEL_ID`, config);
};

/**
 * Получаем пользовательское свойство должность (профессия)
 * @param {UF_CRM_1617025486034} userFielCode
 */
export const positionApi = (filter) => {
    return axios.get(url + `user-field/${filter.userFieldCode}`, config);
};

/**
 * Получаем сделки в процессе
 * @param {*} userFielCode
 */
export const dealInWorkApi = (filter) => {
    return axios.get(
        url +
            `deal/group/?funnel_id=${filter.funnelId}&group_by[uts_deal][]=${filter.userFieldCode}&select[]=count&status-group=processing`,
        config,
    );
};

/**
 * Получаем успешные сделки
 */
export const dealClosedSuccessApi = (filter) => {
    return axios.get(
        url +
            `deal/group/?funnel_id=${filter.funnelId}&group_by[uts_deal][]=${filter.userFieldCode}&select[]=count&status-group=success&finish_date_from=${filter.start}&finish_date_to=${filter.end}`,
        config,
    );
};

/**
 * Получаем неуспешеые сделки
 */
export const dealClosedFailApi = (filter) => {
    return axios.get(
        url +
            `deal/group/?funnel_id=${filter.funnelId}&group_by[uts_deal][]=${filter.userFieldCode}&select[]=count&status-group=failed&finish_date_from=${filter.start}&finish_date_to=${filter.end}`,
        config,
    );
};

/**
 * Получаем количество сделанных звонков
 */
export const callMadeApi = (filter) => {
    return axios.get(
        url + `call/group/?funnel_id=${filter.funnelId}&group_by[uts_deal][]=${filter.userFieldCode}&start_date_from=${filter.start}&start_date_to=${filter.end}`,
        config,
    );
};

/**
 * Получаем количество разговоров более 2 минут
 */
export const callMoreApi = (filter) => {
    return axios.get(
        url +
            `call/group/?funnel_id=${filter.funnelId}&group_by[uts_deal][]=${filter.userFieldCode}&start_date_from=${filter.start}&start_date_to=${filter.end}&min_duration=120`,
        config,
    );
};

/**
 * Получаем количество назначенных встреч
 */
export const meetingAssignedApi = (filter) => {
    return axios.get(
        url +
            `activity/group/?funnel_id=${filter.funnelId}&select[]=count&group_by[uts_deal][]=${filter.userFieldCode}&create_date_from=${filter.start}&create_date_to=${filter.end}&title[]=%231Интервью&title[]=%232Интервью&title[]=%23ФИнтервью`,
        config,
    );
};

/**
 * Получаем количество проведенных встреч
 */
export const meetingConductedApi = (filter) => {
    return axios.get(
        url +
            `activity/group/?funnel_id=${filter.funnelId}&select[]=count&group_by[uts_deal][]=${filter.userFieldCode}&finish_date_from=${filter.start}&finish_date_to=${filter.end}&completed=Y&title[]=%231Интервью&title[]=%232Интервью&title[]=%23ФИнтервью&description=%23done`,
        config,
    );//&comment=%23done
};



/**
 * ЗАПРОСЫ ДЛЯ ДЕТАЛЬНОЙ СТРАНИЦЫ
 */

/**
 * Получаем список сделок в процессе
 */
export const dealInWorkListApi = (filter) => {
    return axios.get(
        url +
            `deal/list/?funnel_id=${filter.funnelId}&status-group=processing&property[${filter.userFieldCode}]=${filter.detailPageId}&page=${filter.paginationPage}&pageSize=${filter.paginationPageSize}`,
        config,
    );
};

/**
 * Получаем список успешных сделок
 */
export const dealClosedSuccessListApi = (filter) => {
    return axios.get(
        url +
            `deal/list/?funnel_id=${filter.funnelId}&status-group=success&finish_date_from=${filter.start}&finish_date_to=${filter.end}&property[${filter.userFieldCode}]=${filter.detailPageId}&page=${filter.paginationPage}&pageSize=${filter.paginationPageSize}`,
        config,
    );
};

/**
 * Получаем список неудачных сделок
 */
export const dealClosedFailListApi = (filter) => {
    return axios.get(
        url +
            `deal/list/?funnel_id=${filter.funnelId}&status-group=failed&finish_date_from=${filter.start}&finish_date_to=${filter.end}&property[${filter.userFieldCode}]=${filter.detailPageId}&page=${filter.paginationPage}&pageSize=${filter.paginationPageSize}`,
        config,
    );
};

/**
 * Получаем список сделанных звонков
 */
export const callMadeListApi = (filter) => {
    return axios.get(
        url + `call/list/?funnel_id=${filter.funnelId}&start_date_from=${filter.start}&start_date_to=${filter.end}&prop[${filter.userFieldCode}]=${filter.detailPageId}&page=${filter.paginationPage}&pageSize=${filter.paginationPageSize}`,
        config,
    );
};

/**
 * Получаем список разговоров более 2 минут
 */
export const callMoreListApi = (filter) => {
    return axios.get(
        url +
            `call/list/?funnel_id=${filter.funnelId}&start_date_from=${filter.start}&start_date_to=${filter.end}&min_duration=120&prop[${filter.userFieldCode}]=${filter.detailPageId}`,
        config,
    );
};

/**
 * Получаем список назначенных встреч
 */
export const meetingAssignedListApi = (filter) => {
    return axios.get(
        url +
            `activity/list/?funnel_id=${filter.funnelId}&create_date_from=${filter.start}&create_date_to=${filter.end}&title[]=%231Интервью&title[]=%232Интервью&title[]=%23ФИнтервью&prop[${filter.userFieldCode}]=${filter.detailPageId}`,
        config,
    );
};

/**
 * Получаем список проведенных встреч
 */
export const meetingConductedListApi = (filter) => {
    return axios.get(
        url +
            `activity/list/?funnel_id=${filter.funnelId}&finish_date_from=${filter.start}&finish_date_to=${filter.end}&completed=Y&title[]=%231Интервью&title[]=%232Интервью&title[]=%23ФИнтервью&description=%23done&prop[${filter.userFieldCode}]=${filter.detailPageId}`,
        config,
    );
};