import React from "react"
import { StatisticCardProps } from './StatisticCard.props';

import {TabPane} from 'reactstrap';
import MinimalStatisticsBG from '../../components/cards/minimalStatisticsBGCard';
import { arBackground } from "../colors";
import * as Icon from 'react-feather';


/**
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @returns array
 */
export const StatisticCard = ({ 
	text = "",
	bgColor,
	statistics = 0,
	icon
}:StatisticCardProps) => {
	const CorrectItem: any = Icon[icon];
	return (
		<TabPane key={1}>
			<MinimalStatisticsBG
				cardBgColor={bgColor}
				statistics={statistics}
				text={text}
				icon={icon}
				iconSide={"right"}
			>
				{<CorrectItem size={51} color="#ffffff" />}
			</MinimalStatisticsBG>
		</TabPane>
	)
};