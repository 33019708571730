const initialState = {
    employeeList: [],
};

const zakupkiReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ZKP_EMPLOYEE':
            return {
                ...state,
                employeeList: action.payload,
            };
        default:
            return state;
    }
};

export default zakupkiReducer;
