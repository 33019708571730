import { fetchTaskListApi } from '../../servise/commonData/commonData';

export const fetchTaskListAction = (payload) => ({
    type: 'FETCH_REPORT_TASK_LIST',
    payload,
});
export const fetchTaskListAddedAction = (payload) => ({
    type: 'FETCH_REPORT_TASK_LIST_ADDED',
    payload,
});
export const fetchTaskListDelayAction = (payload) => ({
    type: 'FETCH_REPORT_TASK_LIST_DELAY',
    payload,
});
export const fetchTaskListDelasCrmAction = (payload) => ({
    type: 'FETCH_TASK_LIST_DELAY_CRM',
    payload,
});
export const fetchTaskListDealsAction = (payload) => ({
    type: 'FETCH_TASK_LIST_DEALS',
    payload,
});

//all-Task-Report Actions
export const fetchTaskListWorkParamsAction = (payload) => ({
    type: 'FETCH_TASK_LIST_WORK_PROC',
    payload,
});
export const fetchTaskListWorkTodayAction = (payload) => ({
    type: 'FETCH_TASK_LIST_WORK_TODAY',
    payload,
});
export const fetchTaskListWorkNextDayAction = (payload) => ({
    type: 'FETCH_TASK_LIST_WORK_NEXT_DAY',
    payload,
});
export const fetchTaskListWorkDelayModalAction = (payload) => ({
    type: 'FETCH_TASK_LIST_WORK_DELAY',
    payload,
});
export const fetchTaskListWorkFinishAction = (payload) => ({
    type: 'FETCH_TASK_LIST_WORK_FINISH',
    payload,
});

export const fetchDealModal = (payload) => ({
    type: 'FETCH_DEAL_MODAL',
    payload,
});
export const fetchDealModalPlan = (payload) => ({
    type: 'FETCH_DEAL_MODAL_PLAN',
    payload,
});

export const fetchTaskList = (params, value) => {
    return async (dispatch) => {
        await fetchTaskListApi(params).then((r) => {
            switch (value) {
                case 'added':
                    dispatch(fetchTaskListAddedAction(r));
                    break;

                case 'delay':
                    dispatch(fetchTaskListDelayAction(r));
                    break;
                case 'delaysCRM':
                    dispatch(fetchTaskListDelasCrmAction(r));
                    break;
                case 'dealTaskList':
                    dispatch(fetchTaskListDealsAction(r));
                    break;
                case 'workParam':
                    dispatch(fetchTaskListWorkParamsAction(r));
                    break;
                case 'todayModal':
                    dispatch(fetchTaskListWorkTodayAction(r));
                    break;
                case 'nextDayModal':
                    dispatch(fetchTaskListWorkNextDayAction(r));
                    break;
                case 'delaysModal':
                    dispatch(fetchTaskListWorkDelayModalAction(r));
                    break;
                case 'finishModal':
                    dispatch(fetchTaskListWorkFinishAction(r));
                    break;
                case 'dealModal':
                    dispatch(fetchDealModal(r));
                    break;
                case 'dealModalPlan':
                    dispatch(fetchDealModalPlan(r));
                    break;

                default:
                    dispatch(fetchTaskListAction(r));
            }
        });
    };
};
