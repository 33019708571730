import axios from 'axios';
import getQueryString from '../helper';

const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};
export const GetCallStatisticApi = (params) => {
    return axios.get(url + `call/group/?` + getQueryString(params), config);
    //return axios.get(url + `call/group/?start_date_from=${params.start_date_from}&start_date_to=${params.start_date_to}&group_by[]=manager&group_by[]=direction&group_by[2]=status&group_by[responsible]=department_id`, config);
};
