import { parse } from 'query-string';
import moment from 'moment';
import { ApiProps, FilterDateArrProps } from './Functions.props';

/**
 * Преобразуем секунды в массив и возвращиет одно из значений (Дни и часы | Часы и минуты | Минуты и секунды | секунды)
 * @param seconds на вход принимает секунды
 * @returns 11ч. 14м.
 */
export const correctTime = (seconds: string | number) => {
    interface myTime {
        years: number;
        months: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        milliseconds: number;
    }

    let timeDuration: any = moment.duration(seconds, 'seconds');
    const time: myTime = timeDuration._data;
    const timeArr = [];
    time.years = time.years > 0 ? time.years : 0;
    time.months = time.months > 0 ? time.months : 0;
    time.days = time.days > 0 ? time.days : 0;
    time.hours = time.hours > 0 ? time.hours : 0;
    time.minutes = time.minutes > 0 ? time.minutes : 0;
    time.seconds = time.seconds > 0 ? time.seconds : 0;
    if (time.years) {
        timeArr.push(`${time.years} г.`);
    }
    if (time.months) {
        timeArr.push(`${time.months} м.`);
    }
    if (time.days) {
        timeArr.push(`${time.days} д.`);
    }
    if (time.hours) {
        timeArr.push(`${time.hours} ч.`);
    }
    if (time.minutes) {
        timeArr.push(`${time.minutes} м.`);
    }
    timeArr.push(`${time.seconds} с.`);
    const correctTime = timeArr[1] ? timeArr[0] + ' ' + timeArr[1] : timeArr[0];
    return correctTime;
};

/**
 * Прокидываем в filter объект с параметрами из url
 * useEffect(() => {
        fncParamsFromUrl(setFilter, props.location.search)
    }, [props.location.search])
 * @param url window.location.search
 * @returns - {id: 45, page: 2, type: "dealInWork"}
 */
export const fncParamsFromUrl = (setFilter: any, url: string = window.location.search) => {
    const paramsArr = parse(url);
    setFilter((prev: any) => ({ ...prev, url: paramsArr }));
};

/**
 * Данные из url в фильтр
 * @param setFilter
 * @param props
 */
export const fncUrl2Filter = (setFilter: any, props: any) => {
    const params1 = parse(props.location.search);
    const params2 = props.match.params;
    setFilter((prev: any) => ({ ...prev, url: { ...params1, ...params2 } }));
};

//CSS стили для input
export const customStyles = {
    option: (provided: any) => ({
        ...provided,
        borderBottom: '1px solid grey',
        color: 'black',
    }),
};

/**
 * Используется для запросов
 * export const queryApi = () => {return axios.get(api.url+ "user-field/UF_CRM_1617025486034", api.config)}
 */
export const api: ApiProps = {
    url: process.env.REACT_APP_API_URL,
    config: {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
    },
    url_module: process.env.REACT_APP_BASE_URL,
};

/**
 * Создаем параметры для запроса
 * @param arParamName
 * @param arParamValue
 * @returns
 */
export const createQueryString = (arParamName: string[], arParamValue: string | number[]) => {
    return arParamName.map((paramName, key) => `${paramName}=${arParamValue[key]}`).join('&');
};
