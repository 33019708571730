import { GetUserProfileApi } from '../../servise/user';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';

export const GetUserProfileAction = (payload) => ({
    type: FETCH_USER_PROFILE,
    payload,
});

export const FetchUserProfile = () => {
    return async (dispatch) => {
        await GetUserProfileApi().then((r) => {
            dispatch(GetUserProfileAction(r.data));
        });
    };
};
