const initialState = {
    deals: null,
    funnel: [],
    fields: []
};

export const statusReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DEALS_STATUS':
            return {
                ...state,
                deals: action.payload
            };
        case 'FETCH_FUNNEL':
            return {
                ...state,
                funnel: action.payload
            };
        case 'FETCH_MULTI_TYPE_FIELD':
            const value = action.payload;

            return {
                ...state,
                fields: [...state.fields, value]
            };
        case 'FETCH_MULTI_TYPE_RESET':
            return {
                ...state,
                fields: []
            };
        case 'FETCH_DEPARTMENT_FILTER':
            return {
                ...state,
                department: action.payload
            };

        default:
            return state;
    }
};
export default statusReducer;
