import { fetchCall } from '../../actions/hrData/call';
import { fetchWithoutErrorCRM } from '../../actions/hrData/errorWithoutErrorCRM';
import { fetchGoogleDoc } from '../../actions/hrData/fetchGoogleDoc';
import { fetchActivityList } from '../../actions/activityList/activityList';
import { fetchWorkResponse } from '../../actions/workResponse/workResponse';
import { fetchDealListCommon } from '../../actions/dealListCommon/dealList';
import {
    fetchTaskList,
    fetchTaskListDealsAction,
} from '../../actions/taskList/taskList';
import {
    fetchActivityGroup,
    fetchActivityOperational,
    fetchDelaysOperationalAction,
} from '../../actions/activityOperational/activity';
import { fetchDealList } from '../../actions/dealList/dealList';
import { fetchLeadList } from '../../actions/leadList/leadList';
import { fetchListGroup } from '../../actions/listGroup/listGroup';
import { fetchCallGroup } from '../../actions/call/statistic';
import { fetchTaskGroup } from '../../actions/taskGroup/taskGroup';
import {
    fetchEmployeeIncall,
    fetchWorkTimeEmployee,
} from '../../actions/employee/employee';

const initialState = {
    call: null,
    delays: null,
    addResume: null,
    etchVacancy: null,
    staticResume: null,
    statics: null,
    duration: {
        duration: null,
        count: null,
    },
    googleDoc: null,
    newCRM: null,
    sideMenu: [],
    lists: [],
    toggleMenu: false,
    settingsParams: [],
    settingsReport: [],
};

export const commonDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CALL':
            return {
                ...state,
                call: action.payload,
            };
        case 'FETCH_STATICS_INTERVIEW':
            return {
                ...state,
                statics: action.payload,
            };
        case 'FETCH_DELAYS':
            return {
                ...state,
                delays: action.payload,
            };
        case 'FETCH_ADD_RESUME':
            return {
                ...state,
                addResume: action.payload,
            };
        case 'FETCH_VACANCY':
            return {
                ...state,
                vacancy: action.payload,
            };
        case 'FETCH_NEW_RESUME':
            return {
                ...state,
                staticResume: action.payload,
            };
        case 'FETCH_ERROR_CRM':
            return {
                ...state,
                errorCRM: action.payload,
            };
        case 'FETCH_WITHOUT_ERROR_CRM':
            return {
                ...state,
                withoutErrorCRM: action.payload,
            };
        case 'FETCH_WITHOUT_ERROR_CRM_DELAY':
            return {
                ...state,
                withoutErrorCRMDelay: action.payload,
            };
        case 'FETCH_GOOGLE_DOC':
            return {
                ...state,
                googleDoc: action.payload,
            };
        case 'FETCH_RESUME_NEW_CRM':
            return {
                ...state,
                newCRM: action.payload,
            };
        case 'FETCH_SETTINGS':
            return {
                ...state,
                settings: action.payload,
            };
        case 'FETCH_SETTINGS_ID':
            return {
                ...state,
                settingsId: action.payload,
            };
        case 'FETCH_ACTIVITY_INTERVIEW':
            return {
                ...state,
                activityInterview: action.payload,
            };

        case 'FETCH_ACTIVITY_PLANNED':
            return {
                ...state,
                activityPlanned: action.payload,
            };
        case 'FETCH_ACTIVITY_SHEDINTERVIEW':
            return {
                ...state,
                activityInt: action.payload,
            };
        case 'FETCH_ACTIVITY_DEALS':
            return {
                ...state,
                activityDeals: action.payload,
            };
        case 'FETCH_ACTIVITY_FIRST':
            return {
                ...state,
                activityFirst: action.payload,
            };
        case 'FETCH_ACTIVITY_SECOND':
            return {
                ...state,
                activitySecond: action.payload,
            };

        case 'FETCH_ACTIVITY_FINAL':
            return {
                ...state,
                activityFinal: action.payload,
            };
        case 'FETCH_ACTIVITY_FIRST_B':
            return {
                ...state,
                activityFirstB: action.payload,
            };
        case 'FETCH_ACTIVITY_SECOND_B':
            return {
                ...state,
                activitySecondB: action.payload,
            };

        case 'FETCH_ACTIVITY_FINAL_B':
            return {
                ...state,
                activityFinalB: action.payload,
            };
        case 'FETCH_CALL_DETAIL':
            return {
                ...state,
                callDetail: action.payload,
            };
        case 'FETCH_CALL_DETAIL_ALL':
            return {
                ...state,
                callDetailAll: action.payload,
            };
        case 'FETCH_TASK_STATE':
            return {
                ...state,
                taskState: action.payload,
            };
        case 'FETCH_DEAL_LIST':
            return {
                ...state,
                dealList: action.payload,
            };
        case 'FETCH_LEAD_LIST':
            return {
                ...state,
                leadList: action.payload,
            };
        case 'FETCH_LISTS':
            return {
                ...state,
                lists: action.payload,
            };
        case 'FETCH_DEALS_WITHOUT_TASK':
            return {
                ...state,
                dealWithoutTask: action.payload,
            };
        case 'SET_TOGGLE_MENU':
            localStorage.setItem('menues', action.payload ? 'open' : 'close');
            return {
                ...state,
                toggleMenu: action.payload,
            };
        case 'FETCH_SIDE_MENU':
            return {
                ...state,
                sideMenu: action.payload,
            };
        case 'FETCH_WORK_RESPONSE':
            return {
                ...state,
                workResponse: action.payload,
            };
        case 'FETCH_WORK_RESPONSE_NEW':
            return {
                ...state,
                workResponseNew: action.payload,
            };
        case 'FETCH_DEAL_LIST_COMMON':
            return {
                ...state,
                dealListCommon: action.payload,
            };
        case 'FETCH_DEPATRAMENT_LIST':
            return {
                ...state,
                departamentList: action.payload,
            };
        case 'FETCH_SIDE_MENU_REPORT':
            return {
                ...state,
                settingsReport: action.payload,
            };
        case 'FETCH_SETTINGS_PARAMS':
            return {
                ...state,
                settingsParams: action.payload,
            };
        case 'FETCH_SETTINGS_RESULT':
            return {
                ...state,
                settingsResult: action.payload,
            };
        default:
            return state;
    }
};
export default commonDataReducer;

export const fetchCommonData = (
    params,
    paramsWithoutCrm,
    duration,
    googleDocParams,
    managerParam,
    timeDurationCell,
    paramsActivity,
    paramsActivity2,
    paramsActivity3,
    paramsActivity1,
    paramsTitle,
    paramsFirst,
    managerParamAct,
    workResponse,
    workResponseN,
    addCrm,
    fixedCrmP,
    addCrmP,
    delay,
    call1,
    call2,
    paramsWithoutCrmDelay,
) => {
    return async (dispatch) => {
        dispatch(fetchGoogleDoc(googleDocParams));
        dispatch(
            fetchActivityList(
                paramsActivity1,
                paramsTitle,
                '',
                managerParamAct,
                '',
            ),
        );

        dispatch(
            fetchActivityList(
                paramsActivity,
                paramsTitle,
                'planned',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity2,
                paramsTitle,
                'shedInterview',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity3,
                '',
                'delas',
                managerParamAct,
                '',
            ),
        );

        dispatch(
            fetchActivityList(
                paramsActivity2,
                paramsFirst.first,
                'first',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity2,
                paramsFirst.second,
                'second',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity2,
                paramsFirst.final,
                'final',
                managerParamAct,
                '',
            ),
        );

        dispatch(
            fetchActivityList(
                paramsActivity1,
                paramsFirst.first,
                'firstB',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity1,
                paramsFirst.second,
                'secondB',
                managerParamAct,
                '',
            ),
        );
        dispatch(
            fetchActivityList(
                paramsActivity1,
                paramsFirst.final,
                'finalB',
                managerParamAct,
                '',
            ),
        );

        dispatch(fetchWorkResponse(workResponse));
        dispatch(fetchWorkResponse(workResponseN, true));
        dispatch(fetchDealListCommon(addCrm));

        dispatch(fetchTaskList(fixedCrmP));
        dispatch(fetchTaskList(addCrmP, 'added'));
        dispatch(fetchTaskList(delay, 'delay'));

        dispatch(fetchCall(call1, 'duration'));
        dispatch(fetchCall(call2, 'callCount'));

        dispatch(fetchWithoutErrorCRM(paramsWithoutCrm));
        dispatch(fetchWithoutErrorCRM(paramsWithoutCrmDelay, true));
    };
};
export const fetchCommonDataActivity = (
    paramsActivity,
    paramsActivity2,
    paramsActivity3,
    paramsActivity1,
    paramsTitle,
    paramsFirst,
    managerParam,
    pagination,
    value,
    workResponseN,
    workResponse,
    addCrm,
    fixedCrmP,
    addCrmP,
    delay,
    paramsWithoutCrm,
    paramsWithoutCrmDelay,
) => {
    return async (dispatch) => {
        switch (value) {
            case 'Просрочки (без ошибок CRM) - Дела':
                dispatch(
                    fetchActivityList(
                        paramsActivity3,
                        '',
                        'delas',
                        managerParam,
                        pagination,
                    ),
                );

                break;
            case 'Проведено':
                dispatch(
                    fetchActivityList(
                        paramsActivity1,
                        paramsTitle,
                        '',
                        managerParam,
                        pagination,
                    ),
                );

                break;
            case 'Запланировано':
                dispatch(
                    fetchActivityList(
                        paramsActivity,
                        paramsTitle,
                        'planned',
                        managerParam,
                        pagination,
                    ),
                );

                break;
            case 'Назначено интервью':
                dispatch(
                    fetchActivityList(
                        paramsActivity2,
                        paramsTitle,
                        'shedInterview',
                        managerParam,
                        pagination,
                    ),
                );

                break;
            case 'Обработано':
                dispatch(fetchWorkResponse(workResponse));

                break;
            case 'Откликов':
                dispatch(fetchWorkResponse(workResponseN, true));

                break;
            case 'Добавлено в CRM':
                dispatch(fetchDealListCommon(addCrm));

                break;
            case 'Исправлено':
                dispatch(fetchTaskList(fixedCrmP));

                break;
            case 'Добавлено':
                dispatch(fetchTaskList(addCrmP, 'added'));

                break;
            case 'Просрочены':
                dispatch(fetchTaskList(delay, 'delay'));

                break;
            case 'Просрочки (без ошибок CRM) - Задачи':
                dispatch(fetchWithoutErrorCRM(paramsWithoutCrm));

                break;
            case 'Закрыто с просрочкой':
                dispatch(fetchWithoutErrorCRM(paramsWithoutCrmDelay, true));

                break;
            default:
        }
    };
};

export const fetchCommonOperational = (
    date,
    finishData,
    delays,
    dealList,
    withoutTaskParams,
    durationParams,
    listParams,
    listGroupParams,
    revenueParams,
    calls,
    callsDelay,
    taskGroupPlan,
    taskGroupFinishParam,
    dealPlan,
    dealPlanFinish,
    applications,
    workTime,
    dealListParamsLead,
    applicationsLead,
    dealTaskList,
    departmentParams,
    flag,
) => {
    return async (dispatch) => {
        !flag
            ? dispatch(fetchActivityOperational(delays, 'delays'))
            : dispatch(fetchDelaysOperationalAction());

        !flag
            ? dispatch(fetchTaskList(dealTaskList, 'dealTaskList'))
            : dispatch(fetchTaskListDealsAction());

        dispatch(fetchActivityOperational(date, 'plenned'));
        dispatch(fetchActivityOperational(finishData, 'finish'));

        dispatch(fetchDealList(dealList, 'dealList'));

        dispatch(fetchLeadList(dealListParamsLead, 'dealParam'));

        dispatch(fetchDealList(withoutTaskParams, 'withoutTask'));
        dispatch(fetchLeadList(withoutTaskParams, 'withoutTask'));

        dispatch(fetchDealList(listParams, 'list'));

        dispatch(fetchListGroup(listGroupParams, 'list'));
        dispatch(fetchListGroup(revenueParams, 'revenue'));

        dispatch(fetchDealList(applications, 'applications'));
        dispatch(fetchLeadList(applicationsLead, 'applications'));

        dispatch(
            fetchWorkTimeEmployee(workTime + `&active=1${departmentParams}`),
        );
        dispatch(fetchEmployeeIncall(workTime));

        dispatch(fetchCallGroup(calls));
        dispatch(fetchCallGroup(callsDelay, true));

        dispatch(fetchTaskGroup(taskGroupPlan));
        dispatch(fetchTaskGroup(taskGroupFinishParam, true));

        dispatch(fetchActivityGroup(dealPlan));
        dispatch(fetchActivityGroup(dealPlanFinish, true));
    };
};
export const fetchFunnelOperational = (
    date,
    finishData,
    delays,
    dealList,
    withoutTaskParams,
    durationParams,
    listParams,
    listGroupParams,
    revenueParams,
    calls,
    callsDelay,
    taskGroupPlan,
    taskGroupFinishParam,
    dealPlan,
    dealPlanFinish,
    applications,
    workTime,
    dealListParamsLead,
    applicationsLead,
) => {
    return async (dispatch) => {
        dispatch(fetchActivityOperational(date, 'plenned'));
        dispatch(fetchActivityOperational(finishData, 'finish'));

        dispatch(fetchDealList(dealList, 'dealList'));
        dispatch(fetchLeadList(dealListParamsLead, 'dealParam'));

        dispatch(fetchDealList(withoutTaskParams, 'withoutTask'));
        dispatch(fetchLeadList(withoutTaskParams, 'withoutTask'));

        dispatch(fetchDealList(listParams, 'list'));

        dispatch(fetchListGroup(listGroupParams, 'list'));
        dispatch(fetchListGroup(revenueParams, 'revenue'));

        dispatch(fetchDealList(applications, 'applications'));
        dispatch(fetchLeadList(applicationsLead, 'applications'));
    };
};
export const fetchOperationalResponsble = (
    date,
    finishData,
    delays,
    dealList,
    withoutTaskParams,
    durationParams,
    listParams,
    listGroupParams,
    revenueParams,
    calls,
    callsDelay,
    taskGroupPlan,
    taskGroupFinishParam,
    dealPlan,
    dealPlanFinish,
    applications,
    workTime,
    departmentParams,
    dealTaskList,
    employee,
    flag,
) => {
    return async (dispatch) => {
        !flag
            ? dispatch(fetchActivityOperational(delays, 'delays'))
            : dispatch(fetchDelaysOperationalAction());

        !flag
            ? dispatch(fetchTaskList(dealTaskList, 'dealTaskList'))
            : dispatch(fetchTaskListDealsAction());

        dispatch(fetchEmployeeIncall(workTime));
        dispatch(
            fetchWorkTimeEmployee(workTime + `&active=1${departmentParams}`),
        );

        dispatch(fetchCallGroup(calls));
        dispatch(fetchCallGroup(callsDelay, true));

        dispatch(fetchTaskGroup(taskGroupPlan));
        dispatch(fetchTaskGroup(taskGroupFinishParam, true));

        dispatch(fetchActivityGroup(dealPlan));
        dispatch(fetchActivityGroup(dealPlanFinish, true));
    };
};

export const fetchOperationalPagination = (
    date,
    finishData,
    delays,
    dealList,
    withoutTaskParams,
    durationParams,
    listParams,
    listGroupParams,
    revenueParams,
    calls,
    callsDelay,
    taskGroupPlan,
    taskGroupFinishParam,
    dealPlan,
    dealPlanFinish,
    applications,
    value,
    dealListParamsLead,
    applicationsLead,
    dealTaskList,
    managerIdTable,
    modalTask1,
    modalTask2,
    modalDeal,
    modalDealFinish,
    isDeals,
    isLeads,
) => {
    return async (dispatch) => {
        switch (value) {
            case 2:
                {
                    isDeals &&
                        dispatch(fetchDealList(applications, 'applications'));
                    isLeads &&
                        dispatch(
                            fetchLeadList(applicationsLead, 'applications'),
                        );
                }
                break;
            case 4:
                {
                    dispatch(fetchActivityOperational(delays, 'delays'));
                    dispatch(fetchTaskList(dealTaskList, 'dealTaskList'));
                }
                break;
            case 3:
                {
                    isDeals &&
                        dispatch(
                            fetchDealList(withoutTaskParams, 'withoutTask'),
                        );
                    isLeads &&
                        dispatch(
                            fetchLeadList(withoutTaskParams, 'withoutTask'),
                        );
                }
                break;
            case 1:
                {
                    isDeals && dispatch(fetchDealList(dealList, 'dealList'));
                    isLeads &&
                        dispatch(
                            fetchLeadList(dealListParamsLead, 'dealParam'),
                        );
                }
                break;
            case 5:
                {
                    managerIdTable &&
                        dispatch(fetchTaskList(modalTask1, 'dealModal'));
                }
                break;
            case 6:
                {
                    managerIdTable &&
                        dispatch(fetchTaskList(modalTask2, 'dealModalPlan'));
                }
                break;
            case 7:
                {
                    managerIdTable &&
                        dispatch(
                            fetchActivityList(
                                modalDeal,
                                '',
                                'modalPlan',
                                '',
                                '',
                            ),
                        );
                }
                break;
            case 8:
                {
                    managerIdTable &&
                        dispatch(
                            fetchActivityList(
                                modalDealFinish,
                                '',
                                'modalPlanFinish',
                                '',
                                '',
                            ),
                        );
                }
                break;
            default: {
            }
        }
    };
};
