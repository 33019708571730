import axios from 'axios';
import { api } from '../../../reusableComponents';

/**
 * Получаем пользовательское свойство "Тип лида"
 * @returns 
 */
export const statusReportLeadApi = () => {
    return axios.get(api.url+`setting/STATUS_REPORT_LEAD`, api.config)
}

/**
 * Возвращает что выводить [count, sum]
 * @returns 
 */
export const statusReportResultApi = () => {
    return axios.get(api.url+`setting/STATUS_REPORT_RESULT`, api.config)
}

/**
 * Получаем массив статусов сделок
 * @returns 
 */
export const statusDealApi = (filter) => {
    return axios.get(api.url+`status/deal?funnel_id=${filter.funnel}`, api.config)
}

/**
 * Получаем массив статусов лидов
 * @returns 
 */
export const statusLeadApi = () => {
    return axios.get(api.url+`status/lead`, api.config)
}

/**
 * Получаем объект по пользовательскому свойству
 * @returns 
 */
export const userFieldApi = (userProperty) => {
    return axios.get(api.url+`user-field/${userProperty}`, api.config)
}


/**
 * Получаем сделки
 * @param {*} filter 
 * @returns 
 */
export const dealApi = (filter) => {

    let funnelId = filter && filter.funnel && filter.funnel.length 
    ? `funnel_id=${filter.funnel}&`
    : ""

    let date = filter.date1.date !== undefined 
    ? `create_date_from=${filter.date1.date}&create_date_to=${filter.date2.date}&` 
    : ""

    const statusGroup = filter.status.length 
    ? `&status-group=${filter.status[0]}`
    : ""

    const employee = filter.departmentEmployee.length
    ? "&" + filter.departmentEmployee.map(el => `responsible_id[]=${el}`).join("&")
    : ""
    
    const userField = []
    Object.keys(filter).forEach((filterCode) => {
        if(filterCode.includes("UF_CRM_")) {
            filter[filterCode].forEach((property) => {
                userField.push(`property[${filterCode}][]=${property}`)
            })
        }
    })
    const userFieldProps = !!userField.length ? `&${userField.join("&")}` : ""

    return axios.get(api.url+`deal/group/?${funnelId}${date}group_by[]=status&group_by[]=manager${employee}${statusGroup}${userFieldProps}`, api.config)
}

/**
 * Получаем лиды
 * @param {*} filter 
 * @returns 
 */
export const leadApi = (filter) => {

    let date = filter.date1.date !== undefined 
    ? `create_date_from=${filter.date1.date}&create_date_to=${filter.date2.date}&` 
    : ""

    const statusGroup = filter.status.length 
    ? `&status-group=${filter.status[0]}`
    : ""

    const employee = filter.departmentEmployee.length
    ? "&" + filter.departmentEmployee.map(el => `responsible_id[]=${el}`).join("&")
    : ""

    const userField = []
    Object.keys(filter).forEach((filterCode) => {
        if(filterCode.includes("UF_CRM_")) {
            filter[filterCode].forEach((property) => {
                userField.push(`property[${filterCode}][]=${property}`)
            })
        }
    })
    const userFieldProps = !!userField.length ? `&${userField.join("&")}` : ""

    return axios.get(api.url+`lead/group/?group_by[]=status&group_by[]=manager&${date}${statusGroup}${employee}${userFieldProps}`, api.config)
}