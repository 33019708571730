import React, {useState, useEffect} from "react"
import { FilterStatusProps, ReactSelectProps } from './FilterMonth.props';
import './FilterMonth.scss';
import DatePicker from 'react-datepicker';
import ru from "date-fns/locale/ru";
import { dateToObj, fncUrl2Variable } from "./functions"
import { useHistory } from "react-router-dom";
import { FilterMonthArUrlProps } from "./FilterMonth.props";

/**
 * Фильтр по месяцу. Прокидывает в фильр: filterDepartmentVal, filterDepartmentEmployee
 * @param setFilter прокидываем фильтр
 * 
 * @returns array
 */
export const FilterMonth: React.FC<FilterStatusProps> = ({
	filter,
	setFilter,
	className = ''
}) => {

	const history = useHistory()
	const arUrl: FilterMonthArUrlProps = fncUrl2Variable()
	let date: any

	if(arUrl.year && arUrl.month) {
		date = new Date(arUrl.year, arUrl.month - 1)
	} else if(filter.month && filter.month.monthNum) {
		date = new Date(filter.month.year, filter.month.monthNum - 1)
	} else {
		date = new Date()
	}

	useEffect(() => {
		setFilter((prev:any) => ({
			...prev,
			month: {
				...dateToObj(date.toLocaleDateString())
			}
		}))
	},[])

	return (
		<div className="datapicker-input">
			<DatePicker
				className={`${className} filter-month`}
				selected={date}
				startDate={new Date()}
				onChange={(date: any) => {
					setFilter((prev:any) => ({
						...prev,
						month: {
							...dateToObj(new Date(date).toLocaleDateString())
						}
					}))
					history.push(`${window.location.pathname}?month=${date.getMonth() + 1}&year=${date.getFullYear()}`)
				}}
				dateFormat="MMM yyyy"
				showMonthYearPicker
				showFullMonthYearPicker
				locale={ru}
			/>
		</div>
	)
};