import { 
    correctArraySourceId, 
    correctArrayUtmSource,
    marketingTraffic
} from '../../../pageComponents/dailyReportScreen/functions'

import { 
    EVERYDAY_REPORT_SCREEN_FUNNEL_LIST,
    EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH,
    EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY,
    EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID,
    EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE,
    EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT,
    EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC,
    EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK
} from '../../types/types';

import { 
    funnelListApi,
    appSelectedDateApi,
    appCurrentMonthApi,
    appThreeDayAgoApi,
    appFailedFor3DayApi,
    appFailSelectedDateApi,
    appSuccessSelectedDateApi,
    adSourceIdApi,
    adUtmSourceApi,
    appMarketingPlanApi,
    marketingTrafficApi,
    marketingTrafficWeekApi

} from '../../servise/dailyReportScreen/dailyReportScreenApi';

import { 
    funnelListAction,
    appSelectedDateAction,
    appCurrentMonthAction,
    appRefusalFor3DayAction,
    refusalForSelectedDateAction,
    conversionForSelectedDateAction,
    adSourceIdAction,
    adUtmSourceAction,
    appOnProductAction,
    marketingTrafficAction,
    marketingTrafficWeekAction
} from '../../actions/dailyReportScreen/dailyReportScreenAction';


const initialState = {
    funnelList: [],
    appSelectedDate: 0,
    refusalForSelectedDate: 0,
    conversionForSelectedDate: 0,
    appCurrentMonth: 0,
    appRefusalFor3Day: 0,
    adSourceId: [],
    adUtmSource: [],
    appOnProduct: [],
    marketingTraffic: {
        list: [],
        ads: []
    },
    marketingTrafficWeek: {
        list: [],
        ads: []
    },
};

const everyDayReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVERYDAY_REPORT_SCREEN_FUNNEL_LIST: {
            return {
                ...state,
                funnelList: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE: {
            return {
                ...state,
                appSelectedDate: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH: {
            return {
                ...state,
                appCurrentMonth: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE: {
            return {
                ...state,
                refusalForSelectedDate: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE: {
            return {
                ...state,
                conversionForSelectedDate: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY: {
            return {
                ...state,
                appRefusalFor3Day: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID: {
            return {
                ...state,
                adSourceId: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE: {
            return {
                ...state,
                adUtmSource: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT: {
            return {
                ...state,
                appOnProduct: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC: {
            return {
                ...state,
                marketingTraffic: action.payload
            };
        }
        case EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK: {
            return {
                ...state,
                marketingTrafficWeek: action.payload
            };
        }
        default:
            return state;
    }
};

export default everyDayReducer;

/**
 * Получаем массив воронки
 */
export const fetchFunnelList = () => {
    return async dispatch => {
        const r = await funnelListApi()
        dispatch(funnelListAction(r));
    };
};


/**
 * [Заявок по продукту]
 * Формируем массив со всеми необходимыми данными для блока
 */
export const fetchAppOnProduct = (filter) => {

    return async (dispatch) => {
        const funnelsArr = await funnelListApi(filter)
        const planForTodayArr = await appMarketingPlanApi(filter)

        for(let key = 0; key < planForTodayArr.length; key++) {

            let el = planForTodayArr[key]

            funnelsArr.forEach(funnel => {
                if(el.funnel_id === funnel.id) {
                    
                    planForTodayArr[key].name = funnel.name
                }
            })

            let d1 = el.date_from
            let d2 = el.date_to

            //Формируем дату вида ##.##.#### 
            d1 = `${d1[8]+d1[9]}.${d1[5]+d1[6]}.${d1[0]+d1[1]+d1[2]+d1[3]}`
            d2 = `${d2[8]+d2[9]}.${d2[5]+d2[6]}.${d2[0]+d2[1]+d2[2]+d2[3]}`

            planForTodayArr[key].create_date_from = d1
            planForTodayArr[key].create_date_to = d2
            
            // Факт 
            planForTodayArr[key].fact = await appSelectedDateApi({
                funnelId: el.funnel_id,
                start: d1,
                end: d2
            })

            // Новых
            planForTodayArr[key].new = await appSelectedDateApi({
                funnelId: el.funnel_id,
                start: filter.current.date,
                end: filter.current.date
            })
        }
        dispatch(appOnProductAction(planForTodayArr))
    }
    
}

/**
 * [Заявок за период]
 * [Отказов за период]
 * [Конверсия]
 */
export const fetchAppData = (filter) => {
    return async dispatch => {

        let appAll = await appSelectedDateApi(filter)
        let appFailed = await appFailSelectedDateApi(filter)
        let appSuccess = await appSuccessSelectedDateApi(filter)
        let adSourceIdArr = await adSourceIdApi(filter)
        let adUtmSourceArr = await adUtmSourceApi(filter)

        adSourceIdArr = correctArraySourceId(adSourceIdArr)
        adUtmSourceArr = correctArrayUtmSource(adUtmSourceArr)

        /**
         * Рассчитываем % неуспешных заявок
         */
        let refusalPercent = Number((appFailed/appAll*100).toFixed(1))
        if(isNaN(refusalPercent)) refusalPercent = 0

        /**
         * Рассчитываем % успешных заявок
         */
        let conversionPercent = Number((appSuccess/appAll*100).toFixed(1))
        if(isNaN(conversionPercent)) conversionPercent = 0

        dispatch(appSelectedDateAction(appAll));
        dispatch(refusalForSelectedDateAction(refusalPercent));
        dispatch(conversionForSelectedDateAction(conversionPercent));
        dispatch(adSourceIdAction(adSourceIdArr));
        dispatch(adUtmSourceAction(adUtmSourceArr));
    };
};

/**
 * Получаем заявки за текущий месяц
 */
export const fetchAppCurrentMonth = (filter) => {
    return async dispatch => {
        const r = await appCurrentMonthApi(filter)
        dispatch(appCurrentMonthAction(r));
    };
};

/**
 * Получаем процент отказов по заявкам за последние 3 дня от текущей даты
 */
export const fetchAppRefusalFor3Day = (filter) => {
    return async dispatch => {
        const appAll = await appThreeDayAgoApi(filter)
        const appFailed = await appFailedFor3DayApi(filter)
        let refusalPercent = Number((appFailed/appAll*100).toFixed(1))
        if(isNaN(refusalPercent)) refusalPercent = 0
        dispatch(appRefusalFor3DayAction(refusalPercent));
    };
};

/**
 * [Объем трафика за выбранный день]
 */
export const fetchMarketingTraffic = (filter) => {
    return async dispatch => {
        const r = await marketingTrafficApi(filter)
        const arResult = marketingTraffic(filter.start, filter.end, r)
        dispatch(marketingTrafficAction(arResult));
    };
};

/**
 * [Объем трафика за неделю]
 */
export const fetchMarketingTrafficWeek = (filter) => {
    return async dispatch => {
        const r = await marketingTrafficWeekApi(filter)
        const arResult = marketingTraffic(filter.current.weekAgo, filter.current.date, r)
        dispatch(marketingTrafficWeekAction(arResult));
    };
};
