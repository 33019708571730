import {
    SALARY_KICKIDLER_REPORT,
    SALARY_TASK_ELAPSED,
    SALARY_WORKTIME_REPORT,
    SALARY_MARK_REPORT,
    SALARY_WEEKEND,
    SALARY_PERIOD,
} from './salaryDetailNew.types';

export const salaryKickidlerReportAction = (payload: any) => ({
    type: SALARY_KICKIDLER_REPORT,
    payload,
});

export const salaryWorktimeReportAction = (payload: any) => ({
    type: SALARY_WORKTIME_REPORT,
    payload,
});

export const salaryTaskElapsedAction = (payload: any) => ({
    type: SALARY_TASK_ELAPSED,
    payload,
});

export const salaryMarkReportAction = (payload: any) => ({
    type: SALARY_MARK_REPORT,
    payload,
});

export const salaryWeekendAction = (payload: any) => ({
    type: SALARY_WEEKEND,
    payload,
});

export const salaryPeriodAction = (payload: any) => ({
    type: SALARY_PERIOD,
    payload,
});
