import React, {useState, useEffect} from "react"
import { FilterDepartmentProps } from './FilterDepartment.props';
import './FilterDepartment.scss';
import Select from "react-select";
import { customStyles } from "../functions";
import { 
	fetchListDepartment,
	fetchListEmployee
} from "./functions";

/**
 * Компонент вывода департаментов. Прокидывает в фильр: department, departmentEmployee
 * @param setFilter прокидываем фильтр
 * @param isClearable возможность сброса по крестику
 * @param isMulti множественное или нет
 * @param placeholder текст отображаемый в поле
 * @param employee прокидывать дополнительно сотрудников выбранного отдела
 * @param className класс
 * @returns array
 */
export const FilterDepartment: React.FC<FilterDepartmentProps> = ({ 
	setFilter,
	isClearable = false, 
	isMulti = false,
	placeholder = "Выберите департамент",
	employee = false,
	className = ""
}) => {

	const [list, setList] = useState([]);

	useEffect(() => {
		fetchListDepartment(setList)
	}, [])

	return (
		<Select
			className={`${className} filter-department`}
			onChange={(e: any) => {

				//Приводим input к массиву, чтобы был как и множественное поле
				let value = isMulti ? e : [e]
				//При сбросе в инпуте делаем пустое состояние такое же как и в иножественном выборе
				if(!value[0]) value = []

				//Если ничего не выбрано в фильтре департамента, значит сотрудников отдела не выводим тоже
				if(value.length !== 0 && employee) {
					fetchListEmployee(setFilter, value)
				} else {
					setFilter((prev: any) => ({
						...prev,
						departmentEmployee: []
					}))
				}
				setFilter((prev: any) => {

					//Если ничего не выбрано в фильтре, значит сотрудники равны null

					return {
						...prev, 
						department: value.map((el: any) => el.value)
					}
				})
					
			}}
			options={
				list.map((el:any):any => ({label: el.name, value: el.id}))
			}
			isMulti={isMulti}
			isClearable={isClearable}
			placeholder={placeholder}
			styles={customStyles}
		/>
	)
};