// import external modules
import { combineReducers } from 'redux';
// import internal(own) modules
import statisticDetail from './statisticDetail';

const callReducer = combineReducers({
    statisticDetail,
});

export default callReducer;
