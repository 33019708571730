// import external modules
import { combineReducers } from 'redux';
// import internal(own) modules
import calenderReducer from './calenderReducer';
import emailReducer from './email/';
// import chatReducer from "./chatReducer";
import callReducer from './call';
import chatReducer from './chat/';
import contactsReducer from './contacts/';
import todoReducer from './todo/';
import customizer from './customizer/';
import userReducer from './user/userProfile';
import reportReducer from './report';
import hrReducer from './hr';
import withoutMarkReducer from './withoutMark/withoutMarkReducer';
import reportTimeReducer from './reportTime/reportTime';
import importReducer from './import/index';
import { reducer as toastrReducer } from 'react-redux-toastr';
import reportMonthReducer from './reportMonth/reportMonthReducer';
import commonDataReducer from './commonData/commonDataReducer';
import statusReducer from './status/status';
import allTaskReportReducer from './allTaskReport/allTaskReportReducer';
import generalReportReducer from './generalReport/generalReportReducer';
import operationalReducer from './operational/operational';
import marketingReducer from './marketing/marketingReducer';
import reportPositionReducer from './reportPosition/reportPositionReducer';
import dailyReportScreenReducer from './dailyReportScreen/dailyReportScreenReducer';
import reportCrmReducer from './reportCrm/reportCrmReducer';
import reportSalaryReducer from './reportSalary/reportSalary';
import salaryDetailReducer from './salaryDetail/salaryDetail';
import dynamicMenuReducer from './dynamicMenu/dynamicMenu';
import userTaskReducer from './userTask/userTask';
import settingReducer from './setting/setting';
import employeeTableReducer from './operational/employeeTable';
import entityListModalReducer from './entityListModal/entityListModal';
import dictionaryReducer from './dictionary/dictionary';
import zakupkiReducer from './zakupki/zakupki';
import zakupkiDashboardReducer from './zakupki/zakupkiDashboard';
import salaryDetailNewReducer from './salaryDetailNew/salaryDetailNew';

const rootReducer = combineReducers({
    calender: calenderReducer,
    call: callReducer,
    emailApp: emailReducer,
    contactApp: contactsReducer,
    todoApp: todoReducer,
    toastr: toastrReducer, // <- Mounted at toastr.
    chatApp: chatReducer,
    customizer: customizer,
    user: userReducer,
    report: reportReducer,
    hr: hrReducer,
    reportMonth: reportMonthReducer,
    withoutMark: withoutMarkReducer,
    reportTime: reportTimeReducer,
    commonData: commonDataReducer,
    status: statusReducer,
    allTaskReport: allTaskReportReducer,
    generalReport: generalReportReducer,
    operational: operationalReducer,
    entityModal: entityListModalReducer,
    employeeTable: employeeTableReducer,
    dictionary: dictionaryReducer,
    marketing: marketingReducer,
    dailyReportScreen: dailyReportScreenReducer,
    import: importReducer,
    reportPosition: reportPositionReducer,
    reportSalary: reportSalaryReducer,
    salaryDetail: salaryDetailReducer,
    salaryDetailNew: salaryDetailNewReducer,
    reportCrm: reportCrmReducer,
    dynamicMenu: dynamicMenuReducer,
    userTask: userTaskReducer,
    zakupki: zakupkiReducer,
    zakupkiDashboard: zakupkiDashboardReducer,
    setting: settingReducer,
});

export default rootReducer;
