import { 
  USER_TASK_LIST_TODAY,
  USER_TASK_LIST_TOMMOROW,
  USER_TASK_LIST_WEEK,
  USER_TASK_LIST_MONTH,
  USER_TASK_DATA_TABLE,
  USER_TASK_LIST_TODAY_MORE,
  USER_TASK_LIST_TOMMOROW_MORE,
  USER_TASK_LIST_WEEK_MORE,
  USER_TASK_LIST_MONTH_MORE
} from "../../types/types"

export const taskListTodayAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_TODAY,
    payload
  }
)

export const taskListTommorowAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_TOMMOROW,
    payload
  }
)

export const taskListWeekAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_WEEK,
    payload
  }
)

export const taskListMonthAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_MONTH,
    payload
  }
)

export const taskListTodayMoreAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_TODAY_MORE,
    payload
  }
)

export const taskListTommorowMoreAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_TOMMOROW_MORE,
    payload
  }
)

export const taskListWeekMoreAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_WEEK_MORE,
    payload
  }
)

export const taskListMonthMoreAction = (payload: any)=>(              
  {
    type: USER_TASK_LIST_MONTH_MORE,
    payload
  }
)

export const dataTableAction = (payload: any)=>(              
  {
    type: USER_TASK_DATA_TABLE,
    payload
  }
)