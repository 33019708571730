const initialState = {
    result: [],
    dictionary: {
        status: [],
        employee: [],
    },
};

export const failureStatus = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REPORT_FAILURE':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
export default failureStatus;
