import axios from "axios"

/**
 * Получаем список отделов
 * @returns 
 */
export const departmentListApi = () => {
    return axios.get(api.url+`department/list/`, api.config)
}

/**
 * Получаем список сотрудников отдела
 * @returns 
 */
export const employeeListApi = (queryString: any) => {
    return axios.get(api.url+`employee/list/?${queryString}&active=1`, api.config).then((r:any) => r.data)
}

/**
 * Получаем список департаментов
 * @param setList 
 */
export const fetchListDepartment = async (setList:any) => {
	const response = await departmentListApi().then(r => r.data)
	setList(response)
}

/**
 * Получаем список сотрудников департаментов
 * @param setList 
 */
export const fetchListEmployee = async (setFilter:any, departments: any) => {

	const queryString = departments.map((department: any) => `department[]=${department.value}`).join("&")

	let response = await employeeListApi(queryString).then((r:any) => r.result)

	//Если в отделе никого нет, то прокидываем несуществующего сотрудника
	if(!response.length) response = [{id: "0"}]

	setFilter((prev:any) => ({
		...prev, 
		departmentEmployee: Array.isArray(response) && response.map((el: any) => el.id)})) || false
}

/**
 * Используется для запросов
 * export const queryApi = () => {return axios.get(api.url+ "user-field/UF_CRM_1617025486034", api.config)}
 */

export const api:any = {
	url : process.env.REACT_APP_API_URL,
	config:	{
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
    	withCredentials:true
	}
}
