import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};
export const fetchTaskGroupWorkApi = (params) =>
    axios.get(url + `task/group/?${params}`, config).then((r) => r.data);
