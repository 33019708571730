import { 
    EVERYDAY_REPORT_SCREEN_FUNNEL_LIST,
    EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH,
    EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY,
    EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE,
    EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID,
    EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE,
    EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT,
    EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC,
    EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK
} from "../../types/types";

export const funnelListAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_FUNNEL_LIST,
    payload,
});

export const appCurrentMonthAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_APPLICATION_CURRENT_MONTH,
    payload,
});

export const appSelectedDateAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_APPLICATION_SELECTED_DATE,
    payload,
});

export const refusalForSelectedDateAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_SELECTED_DATE,
    payload,
});

export const conversionForSelectedDateAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_CONVERSION_FOR_SELECTED_DATE,
    payload,
});

export const appRefusalFor3DayAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_REFUSAL_FOR_3_DAY,
    payload,
});

export const adSourceIdAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_AD_SOURCE_ID,
    payload,
});

export const adUtmSourceAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_AD_UTM_SOURCE,
    payload,
});

export const appOnProductAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_APPLICATION_ON_PRODUCT,
    payload,
});

export const marketingTrafficAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC,
    payload,
});

export const marketingTrafficWeekAction = (payload) => ({
    type: EVERYDAY_REPORT_SCREEN_MARKETING_TRAFFIC_WEEK,
    payload,
});