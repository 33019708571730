import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
const urlBase = process.env.REACT_APP_BASE_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: true
};
export const fetchCallApi = params =>
    axios.get(url + `call/group/?${params}`, config).then(n => n.data);

export const fetchAddResumeApi = () => {
    return axios.get(url + ``, config).then(n => n.data);
};
export const fetchVacancyApi = () => {
    return axios.get(urlBase + `/hr/vacancy/list/`, config).then(n => n.data);
};
export const fetchStaticResumeApi = () => {
    return axios.get(urlBase + `/hr/stat/list/`, config).then(n => n.data);
};
export const fetchErrorCRMApi = managerParam => {
    const manager = managerParam ? managerParam.slice(1) : '';
    return axios.get(url + `task/list-error/?${manager}`, config);
};

export const fetchWithoutErrorCRMApi = params =>
    axios.get(url + `task/list/?${params}`, config);

export const fetchResumeNewCRMApi = (params, managerParam) => {
    const manager = managerParam ? managerParam : '';
    return axios.get(url + `resume/new/?${params}${manager}`, config);
};
export const fetchGoogleDocApi = params =>
    axios.get(url + `google/params/?${params}`, config);
export const fetchSettingsApi = params =>
    axios.get(url + `setting/${params}`, config);

export const fetchActivityListApi = (
    params = '',
    paramsTitle = '',
    managerParam,
    pagination
) => {
    const manager = managerParam ? managerParam : '';
    return axios.get(
        url + `activity/list/?${params}${paramsTitle}${manager}${pagination}`,
        config
    );
};

export const fetchWorkResponseApi = params =>
    axios.get(url + `response/list/?${params}`, config).then(n => n.data);
export const fetchDealListCommonApi = params =>
    axios.get(url + `deal/list/?${params}`, config).then(n => n.data);
export const fetchTaskListApi = params =>
    axios.get(url + `task/list/?${params}`, config).then(n => n.data).catch(e => "");

export const fetchCallDetailApi = duration => {
    return axios
        .get(url + `call/stat-detail/?group_by[]=manager${duration}`, config)
        .then(n => n.data);
};
export const fetchTaskStatApi = () =>
    axios
        .get(url + `task/stat/?group_by[]=responsible_user`, config)
        .then(n => n.data);

export const fetchActivityApi = date =>
    axios.get(url + `activity/list/?${date}`, config).then(n => n.data);
export const fetchDealListApi = params =>
    axios.get(url + `deal/list/?${params}`, config).then(n => n.data);
export const fetchLeadListApi = params => {
    params = params.split("&funnel_id")
    return axios.get(url + `lead/list/?${params[0]}`, config).then(n => n.data);
}
    
export const fetchListGroupApi = params =>
    axios.get(url + `deal/group/?${params}`, config).then(n => n.data);
export const fetchCallGroupApi = params =>
    axios.get(url + `call/group/?${params}`, config).then(n => n.data);
export const fetchTaskGroupApi = params =>
    axios.get(url + `task/group/?${params}`, config).then(n => n.data);
export const fetchActivityGroupApi = params =>
    axios.get(url + `activity/group/?${params}`, config).then(n => n.data);
export const fetchStatusDealApi = params =>
    axios.get(url + `status/deal/?${params}`, config).then(n => n.data);

export const fetchWorkTimeEmployeeApi = params =>
    axios.get(url + `employee/worktime/?${params}`, config).then(n => n.data);
export const fetchEmployeeIncallApi = params =>
    axios.get(url + `employee/incall/?${params}`, config).then(n => n.data);

// -- every day pages --

export const fetchEveryDayMarketingPlanApi = params =>
    axios.get(url + `marketing/plan/?${params}`, config).then(n => n.data);
export const fetchDepartmentListApi = (params = '') =>
    axios.get(url + `department/list/?${params}`, config).then(n => n.data);