import axios from "axios";
const url = process.env.REACT_APP_API_URL

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',

    },
    withCredentials:true
}

/**
 * Получаем воронки
 */
export const funnelListApi = () => {
    return axios.get(url+`funnel/list/`, config).then(r => r.data)
}

/**
 * Получаем все заявки за выбранный период
 */
export const appSelectedDateApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.start}&create_date_to=${filter.end}`, config)
        .then(r => r.data.result[0].count)
        .catch(e => 0)
}

/**
 * Получаем успешные заявки за выбранный период
 */
export const appSuccessSelectedDateApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}create_date_from=${filter.start}&create_date_to=${filter.end}&status-group=success`, config)
        .then(r => r.data.result[0].count).catch(e => 0)
}

/**
 * Получаем неуспешные заявки за выбранный период
 */
export const appFailSelectedDateApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}create_date_from=${filter.start}&create_date_to=${filter.end}&status-group=failed`, config)
        .then(r => r.data.result[0].count).catch(e => 0)
}

/**
 * Получаем все заявки за текущий месяц
 */
export const appCurrentMonthApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.current.dateStartMonth}&create_date_to=${filter.current.date}`, config)
        .then(r => r.data.result[0].count)
}

/**
 * Получаем все заявки за последние 3 дня от текущей даты
 */
export const appThreeDayAgoApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.current.threeDayAgo}&create_date_to=${filter.current.date}`, config)
        .then(r => r.data.result[0].count)
        .catch(e => 0)
}

/**
 * Получаем неуспешные заявки за последние 3 дня от текущей даты
 */
export const appFailedFor3DayApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.current.threeDayAgo}&create_date_to=${filter.current.date}&status-group=failed`, config)
        .then(r => r.data.result[0].count)
        .catch(e => 0)
}

/**
 * [Источник]
 * Массив рекламных компаний сгруппированных по типу source_id
 */
export const adSourceIdApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.start}&create_date_to=${filter.end}&group_by[]=source_id`, config)
        .then(r => r.data)
        .catch(e => [])
}

/**
 * [Рекламные каналы]
 * Массив рекламных компаний сгруппированных по типу utm_source
 */
export const adUtmSourceApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.start}&create_date_to=${filter.end}&group_by[]=utm_source`, config)
        .then(r => {
            return r.data
            
        })
        .catch(e => [])
}

/**
 * [Объем трафика за выбранные даты]
 * Массив рекламных компаний сгруппированных по типу utm_source за последнюю неделю
 */
export const marketingTrafficApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`marketing/traffic/?${funnel_id}date_from=${filter.start}&date_to=${filter.end}&group_by[]=source&group_by[]=date`, config)
        .then(r => {
            return r.data
        })
        .catch(e => [])
}

/**
 * [Объем трафика за неделю]
 * Массив рекламных компаний сгруппированных по типу utm_source за последнюю неделю
 */
export const marketingTrafficWeekApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`marketing/traffic/?${funnel_id}date_from=${filter.current.weekAgo}&date_to=${filter.current.date}&group_by[]=source&group_by[]=date`, config)
        .then(r => {
            return r.data
        })
        .catch(e => [])
}


/**
 * [Заявок по продукту] -> Новых
 * Получаем все заявки за текущую дату
 * Блок 
 */
/*
export const appCurrentDateApi = (filter) => {
    const funnel_id = filter.funnelId ? `funnel_id=${filter.funnelId}&` : ""
    return axios
        .get(url+`deal/group/?${funnel_id}select[]=count&create_date_from=${filter.current.date}&create_date_to=${filter.current.date}`, config)
        .then(r => r.data.result[0].count)
        .catch(e => 0)
}
*/

/**
 * [Заявок по продукту]
 * Получаем массив планов актуальных на текущую дату
 */
export const appMarketingPlanApi = (filter) => {
    return axios
        .get(url+`marketing/plan/?date=${filter.current.date}`, config)
        .then(r => r.data.result)
        .catch(e => [])
}