// import external modules
import React, { Component } from 'react';
import { Navbar } from 'reactstrap';
import { connect } from 'react-redux';

import { Menu, MoreVertical } from 'react-feather';

class ThemeNavbar extends Component {
    handleClick = (e) => {
        this.props.toggleSidebarMenu('open');
    };
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    logout() {}

    render() {
        return (
            <Navbar className="navbar navbar-expand-lg navbar-light bg-faded">
                <div className="container-fluid px-0">
                    <div className="navbar-header">
                        <Menu
                            size={14}
                            className="navbar-toggle d-lg-none float-left"
                            onClick={this.handleClick.bind(this)}
                            data-toggle="collapse"
                        />
                        {/* <MoreVertical
                            className="mt-1 navbar-toggler black no-border float-right"
                            size={50}
                            onClick={this.toggle}
                        /> */}
                    </div>

                    {/* <div className="navbar-container">
                  <Collapse isOpen={this.state.isOpen} navbar>
                     <Nav className="ml-auto float-right" navbar>
                        <UncontrolledDropdown nav inNavbar className="pr-1">
                           <DropdownToggle nav>
                              <img src={this.props.user.profile.personal_photo} alt="logged-in-user" className="rounded-circle width-35" />
                           </DropdownToggle>
                           <DropdownMenu right>
                              <DropdownItem>
                                 <span className="font-small-3">
                                    {this.props.user.profile.name} {this.props.user.profile.last_name}
                                 </span>
                              </DropdownItem>
                              <DropdownItem divider />

                              <Link onClick={this.logout} to="/login" className="p-0">
                                 <DropdownItem>
                                    <LogOut size={16} className="mr-1" /> Выход
                                 </DropdownItem>
                              </Link>
                           </DropdownMenu>
                        </UncontrolledDropdown>
                     </Nav>
                  </Collapse>
               </div> */}
                </div>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, null)(ThemeNavbar);
