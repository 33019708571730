import { fetchWithoutErrorCRMApi } from '../../servise/commonData/commonData';

export const fetchWithoutErrorCRMAction = (payload) => ({
    type: 'FETCH_WITHOUT_ERROR_CRM',
    payload,
});
export const fetchWithoutErrorCRMDelayAction = (payload) => ({
    type: 'FETCH_WITHOUT_ERROR_CRM_DELAY',
    payload,
});

export const fetchWithoutErrorCRM = (params, delay) => {
    return async (dispatch) => {
        fetchWithoutErrorCRMApi(params).then((r) => {
            delay
                ? dispatch(fetchWithoutErrorCRMDelayAction(r.data))
                : dispatch(fetchWithoutErrorCRMAction(r.data));
        });
    };
};
