import {
    fetchSourceAction,
    fetchSourceIdAction,
    resetReplaceSuccessAction,
    setReplaceSuccessAction,
    setSourceAddErrorAction,
} from '../../actions/hr/source/source';
import {
    EditSourceApi,
    fetchSourceApi,
    fetchSourceIdApi,
    SetSourceApi,
} from '../../servise/hr/hrSource';

const initialState = {
    source: [],
    replaceSuccess: false,
    sourceId: null,
    sourceAddError: false,
};
const hrSourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SOURCE': {
            return {
                ...state,
                source: action.payload,
            };
        }
        case 'SET_REPLACE_SUCCESS': {
            return {
                ...state,
                replaceSuccess: true,
            };
        }
        case 'RESET_REPLACE_SUCCESS': {
            return {
                ...state,
                replaceSuccess: false,
                sourceId: null,
            };
        }
        case 'FETCH_SOURCE_ID': {
            return {
                ...state,
                sourceId: action.payload,
            };
        }
        case 'SET_SOURCE_EDD_ERROR': {
            return {
                ...state,
                sourceAddError: action.payload,
            };
        }
        default:
            return state;
    }
};

export default hrSourceReducer;

export const fetchSource = () => {
    return async (dispatch) => {
        await fetchSourceApi().then((r) => {
            dispatch(fetchSourceAction(r.data));
        });
    };
};
export const fetchSourceId = (id) => {
    return async (dispatch) => {
        await fetchSourceIdApi(id).then((r) => {
            dispatch(fetchSourceIdAction(r.data));
        });
    };
};
export const SetSource = (value, id) => {
    return async (dispatch) => {
        dispatch(setSourceAddErrorAction(false));
        if (id) {
            EditSourceApi(value, id).then(() =>
                dispatch(setReplaceSuccessAction())
            );
        } else {
            SetSourceApi(value)
                .then(() => dispatch(setReplaceSuccessAction()))
                .catch((e) => dispatch(setSourceAddErrorAction(true)));
        }
    };
};
export const resetReplaceSuccess = () => {
    return async (dispatch) => {
        dispatch(resetReplaceSuccessAction());
    };
};
