import React, { FC } from "react"
import { Button as ButtonReact } from "reactstrap"
import { ButtonProps } from './Button.props';
import './Button.scss';

export const Button:FC<ButtonProps> = ({ 
	type, 
	children,
	className="",
	onClick,
	disabled = false
}) => {
	switch (type) {
		case 'standart':
			return <ButtonReact 
					className={className}
					onClick={onClick}
					disabled={disabled}
				>
					{children}
				</ButtonReact>
		case 'gradient':
			return <ButtonReact 
					className={`gradient-nepal ${className}`}
					onClick={onClick}
					disabled={disabled}
				>
					{children}
				</ButtonReact>
		default:
		return <>{children}</>;
	}
};