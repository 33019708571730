import axios from 'axios';
const url = process.env.REACT_APP_BASE_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
};

export const SetSourceApi = (value) => {
    const param = { name: value };
    return axios.post(url + `hr/source/add/`, param, config);
};
export const EditSourceApi = (value, id) => {
    const param = { name: value };
    return axios.put(url + `hr/source/${id}`, param, config);
};
export const fetchSourceApi = () => {
    return axios.get(url + `hr/source/list/`, config);
};
export const fetchSourceIdApi = (id) => {
    return axios.get(url + `hr/source/${id}`, config);
};
