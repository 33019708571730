import React, { createContext, useEffect } from 'react';

const FoldedContentContext = createContext();

const FoldedContentProvider = (props) => {
    const storage = localStorage.getItem('menues');

    const [foldedContent, setFoldedContent] = React.useState();

    useEffect(() => {
        if (storage === 'open') {
            setFoldedContent(true);
        } else if (storage === 'close') {
            setFoldedContent(false);
        }
    }, [storage]);

    const makeFullContent = () => {
        localStorage.setItem('menues', 'open');
        setFoldedContent((prevState) => true);
    };
    const makeNormalContent = () => {
        localStorage.setItem('menues', 'close');
        setFoldedContent((prevState) => false);
    };
    const state = { foldedContent, makeFullContent, makeNormalContent };
    return (
        <FoldedContentContext.Provider value={{ ...state }}>
            {props.children}
        </FoldedContentContext.Provider>
    );
};
const FoldedContentConsumer = FoldedContentContext.Consumer;

export { FoldedContentProvider, FoldedContentConsumer };
