import {
    fetchTargetAction,
    fetchTargetIdAction,
    replaceTargetSuccessAction,
    resetTargetIdAction,
    resetTargetSuccessAction,
} from '../../actions/hr/target/target';
import {
    addTargetDataApi,
    editTargetDataApi,
    fetchTargetApi,
    fetchTargetIdApi,
} from '../../servise/hr/hrTarget';

const initialState = {
    target: [],
    isReplace: false,
    targetId: null,
};
const hrTargetReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TARGET_ACTION': {
            return {
                ...state,
                target: action.payload,
            };
        }
        case 'ADD_TARGET_SUCCESS': {
            return {
                ...state,
                isReplace: true,
            };
        }
        case 'RESET_TARGET_SUCCESS': {
            return {
                ...state,
                isReplace: false,
            };
        }
        case 'FETCH_TARGET_ID': {
            return {
                ...state,
                targetId: action.payload,
            };
        }
        case 'RESET_TARGET_ID': {
            return {
                ...state,
                targetId: null,
            };
        }
        default:
            return state;
    }
};

export default hrTargetReducer;

export const fetchTarget = () => {
    return async (dispatch) => {
        await fetchTargetApi().then((r) => {
            dispatch(fetchTargetAction(r.data));
        });
    };
};
export const addTargetData = (value) => {
    return async (dispatch) => {
        await addTargetDataApi(value).then((r) => {
            dispatch(replaceTargetSuccessAction());
        });
    };
};
export const editTargetData = (value, id) => {
    return async (dispatch) => {
        await editTargetDataApi(value, id).then((r) => {
            dispatch(replaceTargetSuccessAction());
        });
    };
};
export const resetTargetSuccess = () => {
    return async (dispatch) => {
        dispatch(resetTargetSuccessAction());
    };
};
export const resetTargetId = () => {
    return async (dispatch) => {
        dispatch(resetTargetIdAction());
    };
};
export const fetchTargetId = (id) => {
    return async (dispatch) => {
        await fetchTargetIdApi(id).then((r) => {
            dispatch(fetchTargetIdAction(r.data));
        });
    };
};
