import { menu } from '../../../app/router';
import { 
    DYNAMIC_MENU_SETTING_LEFT_MENU,
    DYNAMIC_MENU_CREATE_BOARDS,
    DYNAMIC_MENU_ADD_ITEM,
    DYNAMIC_MENU_REMOVE_ITEM,
    DYNAMIC_MENU_REMOVE_SUB_ITEM,
    DYNAMIC_MENU_EDIT_ITEM,
    DYNAMIC_MENU_RENEW_BOARD,
    DYNAMIC_MENU_CREATE_MENU,
    DYNAMIC_MENU_SEND_TO_SERVER
} from '../../types/types';

import { 
    settingLeftMenuApi,
    menuChangeApi
} from '../../servise/dynamicMenu/dynamicMenu';

import { 
    settingLeftMenuAction,
    createMenuAction,
    createBoardsAction,
    addItemAction,
    removeItemAction,
    removeSubItemAction,
    editItemAction,
    renewBoardsAction
} from '../../actions/dynamicMenu/dynamicMenu';

/*
const changedMenu = menu.map(item => ({
    ...item, 
    items: [],
    icon: "Check"
}))
*/



const initialState = {
    settingLeftMenu: [],
    menu: [],
    boards: [
        /*{ id: 0, name: "Меню", order: 0, icon: "AlertCircle", items: [...changedMenu]},*/
        /*
        { id: 1, name: "Пункт 1", order: 1, icon: "AlertCircle", items: [
            {
                icon: "AlertCircle", 
                id: 1, 
                name: "Оперативный отчёт", 
                order: 1, 
                parent_id: null,  
                url: "/operational"},
            {
                icon: null,
                id: 3,
                name: "Отчёт за месяц",
                order: 3,
                parent_id: 2,
                url: "/staff/report/mount"
            },
            {
                icon: null,
                id: 4,
                name: "Рабочее время",
                order: 4,
                parent_id: 2,
                url: "/staff/report/worktime"
            }
        ] },
        { id: 2, name: "Пункт 2", order: 2, icon: "AlignCenter", items: [] },
        { id: 3, name: "Пункт 3", order: 3, icon: "Copy", items: [] },
        { id: 4, name: "Пункт 4", order: 4, icon: "Compass", items: [] },
        { id: 5, name: "Пункт 5", order: 5, icon: "Command", items: [
            {
                icon: null,
                id: 12,
                name: "Общий отчёт",
                order: 12,
                parent_id: 7,
                url: "/hr/general/report"
            }, 
            {
                icon: null,
                id: 24,
                name: "Создать правило",
                order: 24,
                parent_id: 22,
                url: "/import-setting/create/",
            }
        ] },
    */]
};

const dynamicMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case DYNAMIC_MENU_SETTING_LEFT_MENU: {
            return {
                ...state,
                settingLeftMenu: action.payload
            };
        }
        case DYNAMIC_MENU_CREATE_BOARDS: {
            return {
                ...state,
                boards: action.payload
            };
        }
        case DYNAMIC_MENU_CREATE_MENU: {
            return {
                ...state,
                menu: action.payload
            };
        }
        case DYNAMIC_MENU_ADD_ITEM: {
            state.boards.push(action.payload)
            return {
                ...state
            };
        }
        case DYNAMIC_MENU_REMOVE_ITEM: {
            
            if(action.payload.board && action.payload.item) {
                //Удаляем подпункт меню
                state.boards[action.payload.board].items.forEach((item, key) => {
                    if(key == action.payload.item) {
                        //Перемещаем элемент на первую доску
                        state.boards[0].items.unshift(item)
                        //Удаляем элемент с доски
                        state.boards[action.payload.board].items.splice(key, 1)
                    }
                })
            } else {
                //Удаляем пункт меню
                const deleted = []
                state.boards[action.payload.board].items.forEach((item, key) => {
                    //Перемещаем элемент на первую доску
                    state.boards[0].items.splice(0, 0, item)
                })
                delete state.boards[action.payload.board]
            }
            
            return {
                ...state
            };
        }
        case DYNAMIC_MENU_REMOVE_SUB_ITEM: {
            state.boards[action.payload.board].items.forEach((item, key) => {
                if(key == action.payload.item) {
                    //Перемещаем элемент на первую доску
                    state.boards[0].items.unshift(item)
                    //Удаляем элемент с доски
                    state.boards[action.payload.board].items.splice(key, 1)
                }
            })

            return {
                ...state
            };
        }
        case DYNAMIC_MENU_EDIT_ITEM: {
            if(action.urlParams.item) {
                state.boards[action.urlParams.board].items[action.urlParams.item].name = action.payload.name
                state.boards[action.urlParams.board].items[action.urlParams.item].icon = action.payload.icon
            } else {
                state.boards[action.urlParams.board].name = action.payload.name
                state.boards[action.urlParams.board].icon = action.payload.icon
            }
            return {
                ...state
            };
        }
        case DYNAMIC_MENU_RENEW_BOARD: {
            return {
                ...state,
                boards: action.payload
            };
        }
        default:
            return state;
    }
};

export default dynamicMenuReducer;

/**
 * Получаем меню с бэка
 */
export const fetchSettingLeftMenu = () => {
    return async dispatch => {
        const r = await settingLeftMenuApi()
        dispatch(settingLeftMenuAction(r));
    };
};

/**
 * Формируем массив досок для правой колонки
 */
export const fetchCreateBoards = (boards) => {
    return async dispatch => {
        dispatch(createBoardsAction(boards));
    };
};

/**
 * Формируем массив меню для левой колонки
 */
 export const fetchCreateMenu = (boards) => {
    return async dispatch => {
        dispatch(createMenuAction(boards));
    };
};

/**
 * Добавляем пункт меню
 */
export const fetchAddItem = (item) => {
    return dispatch => {
        dispatch(addItemAction(item));
    }
};

/**
 * Удалить пункт меню
 */
export const fetchRemoveItem = (item) => {
    return dispatch => {
        dispatch(removeItemAction(item));
    }
};

/**
 * Удалить подпункт меню
 */
export const fetchRemoveSubItem = (item) => {
    return dispatch => {
        dispatch(removeSubItemAction(item));
    }
};

/**
 * Редактируем пункт меню
 */
export const fetchEditItem = (urlParam, item) => {
    return dispatch => {
        dispatch(editItemAction(urlParam, item));
    }
};

/**
 * Обновляем доски
 */
export const fetchRenewBoards = (id, item) => {
    return dispatch => {
        dispatch(renewBoardsAction(id, item));
    }
};

/**
 * Отправляем меню на сервер
 */
export const fetchMenuChange = (data) => {
    return async (dispatch) => {
        menuChangeApi(data).then(r => {document.location.reload()})

    }
};