import axios from 'axios';
const url = process.env.REACT_APP_API_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials:true
}

/**
 * Получаем данные для главной страницы
 * @param {*} filter 
 * @returns 
 */
export const reportTimeMonthApi = (filter) => {
    return axios.get(url+`report/monthtime/?month=${filter.month.monthNum}&year=${filter.month.year}`, config)
}


/**
 * Получаем время на линии в секундах
 * @param {*} filter 
 * @returns number
 */
export const reportTimeLineApi = (filter) => {

    return axios.get(url+`call/group/?responsible_id=${filter.url.responsible_id}&month=${filter.month.monthNum}&year=${filter.year}&start_date_from=${filter.url.date}&start_date_to=${filter.url.date}&select[]=duration&completed=Y`, config).then(r => r.data.result[0].duration)
}

/**
 * Получаем время задач
 * @param {*} filter 
 * @returns 
 */
export const reportTimeTaskApi = (filter) => {
    return axios.get(url+`task-elapsed/group/?group_by[]=manager&group_by[]=task&group_by[]=date&finish_date_from=${filter.url.date}&finish_date_to=${filter.url.date}&responsible_id=${filter.url.responsible_id}`, config).then(r => r.data.result)
}

/**
 * Получаем имя задач
 * @param {*} params 
 * @returns 
 */
export const reportTimeTaskNameApi = (params) => {
    return axios.get(url+`task/list/?${params}`, config).then(r => r.data.result)
}

/**
 * Получаем список дел
 * @param {*} userId 
 * @param {*} date 
 * @returns 
 */
export const reportTimeDealApi = (filter) => {
    return axios.get(url+`activity/list?start_date_from=${filter.url.date}&start_date_to=${filter.url.date}&responsible_id=${filter.url.responsible_id}&completed=Y`, config).catch(e => ([]))
}

/**
 * Получаем список дел
 * @param {*} userId 
 * @param {*} date 
 * @returns 
 */
export const reportTimeEventApi = (filter) => {
    return axios.get(url+`event/list/?date_from=${filter.url.date}&date_to=${filter.url.date}&responsible_id=${filter.url.responsible_id}&group_by[]=name&group_by[]=owner_id&group_by[]=date`, config).catch(e => ([]))
}