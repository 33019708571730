import { fetchTaskGroupWork } from '../../actions/allTaskReport/allTaskReport';
import { fetchTaskList } from '../../actions/taskList/taskList';

const initialState = {
    task: [],
};
const allTaskReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TASK_GROUP_WORK':
            return {
                ...state,
                taskWork: action.payload,
            };

        case 'FETCH_TASK_GROUP_WORK_TODAY':
            return {
                ...state,
                taskWorkToday: action.payload,
            };

        case 'FETCH_TASK_GROUP_WORK_NEXT_DAY':
            return {
                ...state,
                taskWorkNexDay: action.payload,
            };

        case 'FETCH_TASK_GROUP_WORK_DELAY':
            return {
                ...state,
                taskWorkDelay: action.payload,
            };

        case 'FETCH_TASK_GROUP_ALL_FINISH':
            return {
                ...state,
                taskWorkFinish: action.payload,
            };

        case 'FETCH_TASK_LIST_WORK_PROC':
            return {
                ...state,
                taskListProc: action.payload,
            };

        case 'FETCH_TASK_LIST_WORK_TODAY':
            return {
                ...state,
                taskListToday: action.payload,
            };

        case 'FETCH_TASK_LIST_WORK_NEXT_DAY':
            return {
                ...state,
                taskListNextDay: action.payload,
            };

        case 'FETCH_TASK_LIST_WORK_DELAY':
            return {
                ...state,
                taskListDelay: action.payload,
            };

        case 'FETCH_TASK_LIST_WORK_FINISH':
            return {
                ...state,
                taskListFinish: action.payload,
            };

        default:
            return state;
    }
};

export default allTaskReportReducer;

export const fetchTaskReportAll = (
    workParam,
    todayParam,
    nextDayParam,
    delaysParam,
    finishParam
) => {
    return async (dispatch) => {
        dispatch(fetchTaskGroupWork(workParam, 'detailTask'));
        dispatch(fetchTaskGroupWork(todayParam, 'todayParam'));
        dispatch(fetchTaskGroupWork(nextDayParam, 'nextDayParam'));
        dispatch(fetchTaskGroupWork(delaysParam, 'delaysParam'));
        dispatch(fetchTaskGroupWork(finishParam, 'finishParam'));
    };
};
export const fetchTaskReport = (
    workModalP,
    todayModalP,
    nextDayModalP,
    delaysModalP,
    finishModalP,
    value
) => {
    return async (dispatch) => {
        switch (value) {
            case 1:
                dispatch(fetchTaskList(workModalP, 'workParam'));

                break;
            case 2:
                dispatch(fetchTaskList(todayModalP, 'todayModal'));

                break;
            case 3:
                dispatch(fetchTaskList(nextDayModalP, 'nextDayModal'));

                break;
            case 4:
                dispatch(fetchTaskList(delaysModalP, 'delaysModal'));

                break;
            case 5:
                dispatch(fetchTaskList(finishModalP, 'finishModal'));

                break;
            default:
        }
        // dispatch(fetchTaskGroupWork(todayParam,"todayParam"))
        // dispatch(fetchTaskGroupWork(nextDayParam,"nextDayParam"))
        // dispatch(fetchTaskGroupWork(delaysParam,"delaysParam"))
        // dispatch(fetchTaskGroupWork(finishParam,"finishParam"))
    };
};
