// import {
//     fetchEmployeeApi,
//     fetchReportMonthTimeApi,
//     fetchReportMonthTimeCompletionApi,
//     fetchReportMonthTimeTimeApi
// } from "../../servise/ReportMonthTime/ReportMonthTime";
// import {
//     fetchEmployeeAction,
//     fetchReportMonthTimeAction,
//     fetchReportMonthTimeCompletionAction,
//     fetchReportMonthTimeTimeAction
// } from "../../actions/ReportMonthTime/ReportMonthTime";

const initialState = {};
const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_MARKETING_PLAN': {
            return {
                ...state,
                marketingPlan: action.payload,
            };
        }
        case 'SET_MARKETING_PLAN_SUCCESS': {
            return {
                ...state,
                marketingPlanSuccess: action.payload,
            };
        }
        case 'DELETE_MARKETING_PLAN': {
            return {
                ...state,
                deletePlan: new Date(),
            };
        }
        case 'FETCH_EDIT_MARKETING_PLAN': {
            return {
                ...state,
                editDate: action.payload,
            };
        }

        case 'SET_EDIT_MARKETING_PLAN': {
            return {
                ...state,
                editPlan: action.payload,
            };
        }
        case 'SET_ERROR_REPLACE_MARKETING': {
            return {
                ...state,
                errorMarketing: action.payload,
            };
        }

        default:
            return state;
    }
};

export default marketingReducer;
