import { TaskListProps, TaskListResultProps } from '../../../props';
import { SalaryDetailFilterProps } from '../../../pageComponents/SalaryDetail/SalaryDetail.props';
import moment from 'moment';

import { 
    SALARY_DETAIL_CALCULATION, 
    SALARY_DETAIL_TASK_LIST,
    SALARY_DETAIL_REPORT_MONTH_TIME,
    SALARY_DETAIL_TASK_GROUP,
    SALARY_DETAIL_ACTIVITY_LIST,
    SALARY_DETAIL_EVENT_LIST,
} from "../../types/types"

import { 
    salaryCalculationApi,
    taskListApi,
    reportMonthTimeApi,
    taskGroupApi,
    activityListApi,
    eventListApi,
} from '../../servise/salaryDetail/salaryDetail';

import { 
    salaryCalculationAction,
    taskListAction,
    reportMonthTimeAction,
    taskGroupAction,
    activityListAction,
    eventListAction
} from '../../actions/salaryDetail/salaryDetail';

const initialState = {
    salaryCalculation:{},
    taskList: {},
    taskGroup: {},
    activityList: {},
    eventList: {},
    monthTime: {}
};

export const salaryDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SALARY_DETAIL_CALCULATION:
            return {
                ...state,
                salaryCalculation: action.payload
            }
        case SALARY_DETAIL_TASK_LIST:
            return {
                ...state,
                taskList: action.payload
            }
        case SALARY_DETAIL_REPORT_MONTH_TIME:
            return {
                ...state,
                reportMonthTime: action.payload
            }
        
        case SALARY_DETAIL_TASK_GROUP:
            return {
                ...state,
                taskGroup: action.payload
            }
        case SALARY_DETAIL_ACTIVITY_LIST:
            return {
                ...state,
                activityList: action.payload
            }
        case SALARY_DETAIL_EVENT_LIST:
            return {
                ...state,
                eventList: action.payload
            }
        default:
            return state;
    }
};
export default salaryDetailReducer

/**
 * Получаем данные для главной страницы
 * @param filter 
 * @returns 
 */
export const fetchSalaryCalculation = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await salaryCalculationApi(filter).then(r=>{
            //Считаем сумму kpi и добавляем в объект
            try {
                r.data.result[0].result.kpi.total = r.data.result[0].result.kpi.effectivetime + r.data.result[0].result.kpi["plan-fact"] + r.data.result[0].result.kpi["task-mark"]
                dispatch(salaryCalculationAction(r.data.result[0]))
            } catch(e) {
                console.log("Пустое значение в ответе сервера")
            }
        })
    }
}

/**
 * Получаем данные [Отработано дней]
 * @param filter 
 * @returns 
 */
export const fetchReportMonthTime = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await reportMonthTimeApi(filter).then((r: any)=>{
            //Вытаскиваем нужного нам пользователя
            r.data = r.data.filter((el: any) => el.user_id == filter.url.id)
            dispatch(reportMonthTimeAction(r.data[0]))
        })
    }
}

/**
 * Получаем данные для детальной страницы [План/Факт] [Эффективное время]
 * @param filter 
 * @returns 
*/
export const fetchTaskList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await taskListApi(filter).then((r:any)=>{
            //Возвращаем нужного пользователя
            const result = r.data.result.map((el:any) => ({
                ...el,
                duration_plan: el.duration_plan ? Number(el.duration_plan) : 0,
                duration_fact: el.duration_fact ? Number(el.duration_fact) : 0,
            }))
            dispatch(taskListAction(result))
        })
    }
}

/**
 * Получаем данные [Оценка задач]
 * @param filter 
 * @returns 
 */
export const fetchTaskGroup = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await taskGroupApi(filter).then(r=>{
            dispatch(taskGroupAction(r.data))
        })
    }
}

/**
 * Получаем данные [Эффективное время]
 * @param filter 
 * @returns 
 */
export const fetchEffectiveTime = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await salaryCalculationApi(filter).then(r=>{
            dispatch(salaryCalculationAction(r.data[0]))
        })
    }
}

/** 
 * Получаем список дел [Эффективное время]
 * @param {*} userId 
 * @param {*} date 
 * @returns 
 */
export const fetchActivityList = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await activityListApi(filter).then((r: any)=>{

            const deals = r.data.result.map((el: any) => {
                const startTime = moment(el.start_time, "YYYY-MM-DD HH:mm:ss")
                const endTime = moment(el.end_time, "YYYY-MM-DD HH:mm:ss")
                el.time = endTime.diff(startTime, "second")
                return el
            })

            dispatch(activityListAction(r.data))
        })
    }
}

/**
 * Получаем список событий календаря [Эффективное время]
 * @param {*} userId 
 * @param {*} date 
 * @returns
 */
export const fetchEventList = (filter: SalaryDetailFilterProps) => { 
    return async (dispatch: any) => {
        await eventListApi(filter).then((r: any)=>{
            dispatch(eventListAction(r.data))
        })
    }
}

